import {linkTypes, userTypes} from './mutation-types'
import http from '@/mixins/http-lib'
import {
  archiveLinkURL,
  bulkCSVLinkURL,
  bulkCSVProcessManually,
  bulkLinksActionURL,
  bulkStoreLinkURL,
  changeLinkStatusURL, countBioLinks,
  countLinks,
  createQRCodeURL,
  deleteLinkURL,
  exportCSVLinksURL,
  fetchAnalyticsByLinks, fetchCustomizeLinkPreview,
  fetchLinkMetaURL,
  fetchLinksURL,
  renderQRCodeURL,
  searchLinksURL,
  storeLinkURL,
  updateSlugURL
} from '../../config/api'
import {swalDefaultValues} from '@/mixins/attributes'
import {fetchCSVHeaderUrl, uploadCSV, uploadImageURL, verifyImageURL} from '../../config/urls/helper/file'
import {abTestingObjValidations} from '@/common/attributes'
import {trackLinkCreated} from '../../common/methods'
import {csvExportSteps} from '@/common/constants'

const getDefaultQuickLinkValidations = () => {
  return {
    validations: {
      campaign: false,
      url: false,
      urlValid: false,
      urlLength: false
    },
    messages: {
      campaign: 'Please select a campaign',
      url: 'URL is required.',
      urlValid: 'URL is invalid, please enter a valid URL.',
      urlLength: 'Url length should not be greater than 500'
    }
  }
}

const getDefaultExportLinksOption = () => {
  return {
    brands: [],
    campaigns: [],
    campaigns_options: []
  }
}

const getDefaultSaveLink = () => {
  return {

    _id: null,
    call_to_action_id: null,
    shorten_url: null,
    has_custom_message: false,
    url: '',
    tags: [],
    is_tags_enabled: false,
    message_headline: 'Your headline goes here',
    message: 'Your message goes here',
    duplicate: false,
    qr_code_url: null,
    note: null,
    cta: {
      domainId: null
    },
    password_protected: false,
    password: '',
    password_protected_logo: null,
    link_expire_attributes: {
      expiry: '',
      expiry_time: '00:00:00',
      expiry_datetime: '',
      link_expiry_logo: ''
    },
    seo: {
      title: null,
      description: null,
      image: null
    },
    split_urls: [],
    is_ab_test: false,
    custom_favicon_url: '',
    is_deep_link_enabled: false,
    is_deep_link_allowed: false,
    is_routing_rule: false,
    routing_rules: [],
    routing_rules_edit: false,
    showSeo: false,
    showFavicon: false,
    showLinkExpiry: false,
    iframe_allowed: false,
    link_history: {}
  }
}
const getDefaultQuickLink = () => {
  return {

    _id: null,
    call_to_action_id: null,
    shorten_url: null,
    has_custom_message: false,
    url: '',
    tags: [],
    message_headline: 'Your headline goes here',
    message: 'Your message goes here',
    duplicate: false,
    qr_code_url: null,
    note: null,
    password_protected: false,
    password: '',
    password_protected_logo: null,
    link_expire_attributes: {
      expiry: '',
      expiry_time: '00:00:00',
      expiry_datetime: '',
      link_expiry_logo: ''
    },
    cta: {
      domainId: null
    },
    split_urls: [],
    is_ab_test: false,
    custom_favicon_url: '',
    is_deep_link_enabled: false,
    is_deep_link_allowed: false,
    is_routing_rule: false,
    routing_rules: [],
    iframe_allowed: false,
    seo: {
      title: null,
      description: null,
      image: null
    }
  }
}

const getDefaultBulkLink = () => {
  return {
    campaign: null,
    links: null,
    tags: [],
    link_tag: null,
    focus: false,
    brand: null
  }
}
const getLoadersState = () => {
  return {
    qr_code_download: false,
    retrieve: false,
    store: false,
    slug: false,
    quick_link: false,
    link_preview: false,
    custom_meta_image: false,
    custom_meta_image_url: false,
    custom_favicon_image_url: false,
    password_protected_logo: false,
    link_expiry_logo: false,
    customizeLinkPreviewLoader: false
  }
}
export default {
  state: {
    bulk_selection: {
      all: false,
      items: []
    },
    quick_link_validations: getDefaultQuickLinkValidations(),
    loaders: getLoadersState(),
    link: {
      items: [],
      fetchStatus: false,
      search: '',
      searchType: 'search',
      callToAction: [],
      page: 1,
      date: {
        label: 'All Time',
        value: ''
      },
      sort: 'created_desc',
      limit: 10,
      totalPages: 0,
      totalItems: 0,
      previewURL: null,
      previewStatus: false,
      previewErrorMessage: '',
      linkTag: '',
      suggestedTags: [],
      tags: [],
      selectedCta: null,
      shortenUrl: '',
      domainUrl: null,
      editShortenUrl: '',
      cancelRequest: [],
      editSlug: false,
      selectAll: true,
      selectAllTags: true,
      archive: false,
      focus: false,
      campaign: [],
      brand: [],
      new_link_created: false,
      tableColumns: [
        { id: 1, name: 'Original Link', status: true },
        { id: 2, name: 'Campaign', status: true },
        { id: 3, name: 'Clicks', status: true },
        { id: 4, name: 'Conv. / Rate', status: true },
        { id: 5, name: 'Conv. rate', status: true },
        { id: 6, name: 'Avg. Time', status: false },
        { id: 7, name: 'Tags', status: true },
        { id: 8, name: 'Unique Clicks', status: true },
        { id: 9, name: 'Status', status: true }
      ],
      link: {}
    },

    saveLink: getDefaultSaveLink(),
    bulkLink: getDefaultBulkLink(),
    quickLink: getDefaultQuickLink(),
    quickLinkTag: {

      // create this state, as the tags are updated once the user enters them.

      tag: '',
      focus: false
    },
    export_links: getDefaultExportLinksOption()
  },
  getters: {
    getLinksLoaders: state => state.loaders,
    getLinks: state => state.link,
    getLinksSelectedTag: state => state.link.selectedTags,
    getSaveLink: state => state.saveLink,
    getLoaders: state => state.loaders,
    getQuickLink: state => state.quickLink,
    getQuickLinkTag: state => state.quickLinkTag,
    getBulkLink: state => state.bulkLink,
    getExportLinks: state => state.export_links,
    getQuickLinkValidations: state => state.quick_link_validations,
    getLinksBulkSelection: state => state.bulk_selection,
  },
  actions: {
    async fetchLinks({commit, getters, dispatch}, page = 1) {
      const payload = getters.getLinks
      payload.page = page
      payload.limit = parseInt(payload.limit)
      payload.items = []
      payload.brand_id = getters.getWorkspace._id
      commit(linkTypes.SET_LINKS_RETRIEVE_LOADER, true)
      await http.post(fetchLinksURL, payload).then(res => {
        if (res.data.status) {
          commit(linkTypes.SET_LINKS_LIST, res.data)
        }
        return res
      }).catch(() => {
        return null
      })
      commit(linkTypes.SET_LINKS_RETRIEVE_LOADER, false)
      return true
    },
    fetchLinkAnalytics({commit, getters, dispatch}) {
      getters.getLinks.items.forEach((item) => {
        http.post(fetchAnalyticsByLinks, {
          link_id: item._id
        }).then(res => {
          if (res.data.status) {
            const obj = {
              _id: item._id,
              data: res.data.data
            }
            commit(linkTypes.SET_LINK_ANALYTICS, obj)
          }
        })
      })
    },
    async fetchCustomizeLinkPreviewObj({commit, getters, dispatch}, payload = null) {
      getters.getLinksLoaders.customizeLinkPreviewLoader = true
      return await http.post(fetchCustomizeLinkPreview, payload).then(res => {
        if (res) {
          getters.getLinksLoaders.customizeLinkPreviewLoader = false
        }
        getters.getLinksLoaders.customizeLinkPreviewLoader = false
        return res.data
      }).catch((err) => {
        console.warn(err)
        return null
      })
    },
    async createQRCode({commit, getters, dispatch}, {url = null, slug = null}) {
      if (!url) {
        url = getters.getSaveLink.url
      }
      if (!slug) {
        slug = getters.getSaveLink.slug
      }
      url += '?r=qr'
      commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, true)
      await http.post(createQRCodeURL, {
        url: url,
        slug: slug
      }).then(res => {
        commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, false)
        if (res.data.data) {
          commit(linkTypes.SET_QR_CODE_URL, res.data.data)
          dispatch('renderQRCode', res.data.data)
        }
      }).catch(
        () => {
          commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, false)
        }
      )
      return
    },

    renderQRCode({commit, getters, dispatch}, url) {
      commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, true)

      http.post(renderQRCodeURL, {
        url: url
      }, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'image/png'
        }
      }).then(res => {
        // download the report for the user.

        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        const splitURL = url.split('/')
        // improve the file download name
        const fileName = splitURL[splitURL.length - 1] + '.png'
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, false)
      }).catch(
        () => {
          commit(linkTypes.SET_QR_CODE_DOWNLOAD_LOADER, false)
        }
      )
    },

    deleteLink({commit, state, getters, dispatch}, {linkId, index}) {
         return  http.post(deleteLinkURL, {_id: linkId}).then(res => {
            if (res.data.status) {
              getters.getLinks.items.splice(index, 1)
            } else {
              const message = (res.data.message) ? res.data.message : 'An error occurred while removing your link, please try again.'
              dispatch('toastNotification', {message: message, type: 'error'})
            }
          }).catch()
    },

    async archiveLink({state, commit, getters, dispatch}) {
      // payload has the link id and other information

      const payload = getters.getModalData
      const profileArchiveStatus = getters.getArchiveStatus.status
      if (profileArchiveStatus) payload.hideArchiveMessage = true
      const res = await http.post(archiveLinkURL, payload).then(res => {
        // if link archived then show the success and remove the items from the list
        if (res.data.status) {
          commit(userTypes.SET_MODAL_STATUS, 'success')
          getters.getLinks.items.splice(payload.index, 1)
          dispatch('toastNotification', {message: res.data.message})
          // set the profile status to the archive

          if (profileArchiveStatus) commit(userTypes.SET_PROFILE_ARCHIVE_MESSAGE_STATUS)
        } else {
          commit(userTypes.SET_MODAL_STATUS, 'fail')
          const message = (res.data.message) ? res.data.message : 'An error occurred while archiving your link, please try again.'
          dispatch('toastNotification', {message: message, type: 'error'})
        }
        return res
      }).catch(() => {
          return null
        }
      )

      setTimeout(() => {
        commit(userTypes.SET_CLOSE_CONFIRMATION_MODAL, 'archiveLink')
      }, 1000)
    },

    changeLinkStatus({commit, getters, state, dispatch}, {linkId, status, index}) {
      http.post(changeLinkStatusURL, {
        _id: linkId,
        status: status
      }).then(res => {
        if (res.data.status) {
          getters.getLinks.items[index].is_link_disabled = status
        } else {
          const message = (res.data.message) ? res.data.message : 'An error occurred while changing the status for your link, please try again.'
          dispatch('toastNotification', {message: message, type: 'error'})
          getters.getLinks.items[index].is_link_disabled = false
        }
      }).catch(() => {

      })
    },

    async fetchLinkMeta({commit, getters, state, dispatch}, shorten_url) {
      return await http.post(fetchLinkMetaURL, {shorten_url}).then(res => {
        if (res.data.status) {
          return res.data
        }
        return null
      })
    },

    async storeLink({commit, getters, dispatch, state}, payload) {
      // const payload = getters.getSaveLink
      commit(linkTypes.SET_LINKS_STORE_LOADER, true)
      payload.source = 'replug_app'
      payload.callTypeCreateLink = true
      const res = await http.post(storeLinkURL, payload).then(res => {
        if (res.data.status) {
          if (payload.hasOwnProperty('_id') && payload._id && payload._id.length > 3) {
            // updating existing link

            const findIndex = getters.getLinks.items.findIndex(item => item._id === payload._id)
            getters.getLinks.items.splice(findIndex, 1, res.data.data)
            dispatch('toastNotification', {message: 'Link updated successfully!', type: 'success'})
          } else {
            trackLinkCreated(getters.getProfile)
            // new link scenario
            commit(linkTypes.SET_LINKS_EDIT_VALUE, res.data.data)
            commit(linkTypes.SET_LINKS_NEW_CREATED, true)
            getters.getLinks.items.unshift(res.data.data)
            // For the newly users signed up to the platform, taking care of their onboarding.
            if (getters.getProfile.onboarding === false) {
              getters.getProfile.onboarding_data.link = true
              // getters.getProfile.onboarding = true
            }
            dispatch('toastNotification', {message: 'Link created successfully!', type: 'success'})
          }
        } else {
          const message = (res.data.message) ? res.data.message : 'An error occurred, please try again.'
          dispatch('toastNotification', {message: message, type: 'error'})
        }
        return res
      }).catch(() => {
          return null
        }
      )
      commit(linkTypes.SET_LINKS_STORE_LOADER, false)
      return res
    },

    async storeQuickLink({commit, getters, dispatch}) {
      const payload = JSON.parse(JSON.stringify(getters.getQuickLink))

      if (payload.split_urls && payload.split_urls.length > 0) {
        payload.split_urls.forEach((item) => {
          delete item.validations
          delete item.isEdit
          delete item.loader
        })
      }
      commit(linkTypes.SET_QUICK_LINK_STORE_LOADER, true)
      // commit(linkTypes.SET_QUICK_LINK_SHORTEN_URL, null)
      const res = await http.post(storeLinkURL, payload).then(async res => {
        if (res.data.status) {
          await trackLinkCreated(getters.getProfile)
          // new link scenario

          if (res.data.data.split_urls && res.data.data.split_urls.length > 1) {
            res.data.data.split_urls.forEach(item => {
              item.validations = {...abTestingObjValidations}
              item.isEdit = false
              item.loader = false
            })
          }

          commit(linkTypes.SET_QUICK_LINK_EDIT_VALUE, res.data.data)
          commit(linkTypes.SET_LINKS_NEW_CREATED, true)
          getters.getLinks.items.unshift(res.data.data)
          // For the newly users signed up to the platform, taking care of their onboarding.
          if (getters.getProfile.onboarding === false) {
            getters.getProfile.onboarding_data.link = true
            getters.getProfile.onboarding = true
          }
          if (payload._id) dispatch('toastNotification', {message: 'Link updated.', type: 'success'})
          return true
        } else {
          const message = (res.data.message) ? res.data.message : 'An error occurred, please try again.'
          dispatch('toastNotification', {message: message, type: 'error'})
          return false
        }
        return res
      }).catch(() => {
          return null
        }
      )
      commit(linkTypes.SET_QUICK_LINK_STORE_LOADER, false)
      return res
    },

    async updateSlug({commit, getters, dispatch}, quickLink = false) {
      commit(linkTypes.SET_LINKS_SLUG_LOADER, true)
      let payload = {}

      // for quick link
      if (quickLink) {
        payload = {
          _id: getters.getQuickLink._id,
          slug: getters.getQuickLink.shorten_url
        }
      } else {
        // for new create link section

        payload = {
          _id: getters.getSaveLink._id,
          slug: getters.getSaveLink.shorten_url
        }
      }

      const res = await http.post(updateSlugURL, payload).then(res => {
        return res
      }).catch(() => {
          return null
        }
      )
      commit(linkTypes.SET_LINKS_SLUG_LOADER, false)
      return res
    },

    async bulkStoreLink({getters, dispatch}, config = {}) {
      const payload = {
        ...config,
        tags: getters.getSaveLink.tags
      }
      return await http.post(bulkStoreLinkURL, payload)
        .then(res => {
          if (res.data.status) {
            dispatch('toastNotification', {message: res.data.message})
          } else {
            const message = (res.data.message) ? res.data.message : 'An error occurred while uploading links, please try again.'
            dispatch('toastNotification', {message: message, type: 'error'})
          }
          return res
        })
        .catch(() => null)
    },

    async bulkProcessManuallyLink({commit, getters, dispatch}, config = {}) {
      const payload = {
        ...config,
        tags: getters.getSaveLink.tags
      }
      return await http.post(bulkCSVProcessManually, payload).then(res => {
        if (res.data.status) {
          dispatch('toastNotification', {message: res.data.message})
          return res.data
        } else {
          const message = res.data.message ? res.data.message : 'An error occurred while uploading links, please try again.'
          dispatch('toastNotification', {message: message, type: 'error'})
          return null
        }
      }).catch(() => {
        dispatch('toastNotification',
          {message: 'An error occurred while uploading links, please try again.', type: 'error'})
        return null
      })
    },

    async bulkCSVLink({getters, dispatch}, config) {
      const payload = {
        ...config,
        tags: getters.getSaveLink.tags
      }
      return await http.post(bulkCSVLinkURL, payload).then(res => {
        // successful response
        if (res.data.status) {
          dispatch('toastNotification', {message: res.data.message})
          return true
        }

        // error response
        const message = (res.data.message) ? res.data.message : 'An error occurred while uploading links, please try again.'
        dispatch('toastNotification', {message: message, type: 'error'})
        return false
      }).catch(() => null)
    },

    async exportCSVLinks({commit, getters, dispatch}, payload) {
      return await http.post(exportCSVLinksURL, payload)
        .then(res => {
          if (res.data.status) {
            dispatch('toastNotification', {message: res.data.message})
          }
          return res
        })
        .catch(res => null)
    },

    async searchLinks({commit, getters}, payload) {
      return await http.post(searchLinksURL, payload).then(res => res).catch(() => null)
    },

    async countLinks({commit, getters}, payload) {
      const res = await http.post(countLinks, payload).then(res => res).catch(() => null)
      return res
    },

    async countBioLinks({commit, getters}, payload) {
      return await http.post(countBioLinks, payload).then(res => res).catch(() => null)
    },

    async bulkLinksAction(context, state) {
      const swalItem = swalDefaultValues()
      switch (state) {
        case 'enable':
          swalItem.confirmButtonText = 'Yes, Enable them!'
          break
        case 'disable':
          swalItem.confirmButtonText = 'Yes, Disable them!'
          break
        case 'archive':
          swalItem.confirmButtonText = 'Yes, Archive them!'
          break
        case 'delete':
          swalItem.confirmButtonText = 'Yes, Delete them!'
          break
      }

      swal(swalItem, (isConfirm) => {
        if (isConfirm) {
          http.post(bulkLinksActionURL, {
            ids: context.getters.getLinksBulkSelection.items,
            state: state
          }).then(async res => {
            if (res.data.status) {
              context.commit(linkTypes.SET_BULK_LINKS_SELECTION_ALL, false)
              context.commit(linkTypes.SET_BULK_LINKS_SELECTION_ITEMS, [])
              await context.dispatch('fetchLinks', context.getters.getLinks.page)
              // message from the toast.
              await context.dispatch('fetchLinkAnalytics')
            }
          }).catch()
        } else {
          swal()
        }
      })
    },

    setLinksFetchStatus({commit}, status) {
      commit('setLinksFetchStatus', status)
    },

    setLinksSort({commit}, sort) {
      commit('setLinksSort', sort)
    },

    async uploadCustomMetaImage({commit, getters, dispatch}, event) {
      commit(linkTypes.SET_CUSTOM_META_IMAGE_UPLOAD_LOADER, true)
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            commit(linkTypes.SET_CUSTOM_IMAGE_URL, res.data.resize_url)
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {

      })
      event.target.value = ''
      commit(linkTypes.SET_CUSTOM_META_IMAGE_UPLOAD_LOADER, false)
    },
    async verifyImageResolution({commit, getters, dispatch}, imageURL) {
      await http.post(verifyImageURL, {image_url: imageURL}).then(res => {
        if (res.data.status) {
          return true
        } else {
          dispatch('toastNotification', {
            message: res.data.message, type: 'error'
          })
          commit(linkTypes.SET_CUSTOM_IMAGE_URL, '')
          return false
        }
        return res
      }).catch(() => {
      })
    },
    async uploadCustomFaviconImage({commit, getters, dispatch}, event) {
      commit(linkTypes.SET_CUSTOM_FAVICON_IMAGE_UPLOAD_LOADER, true)
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            commit(linkTypes.SET_CUSTOM_FAVICON_IMAGE_URL, res.data.resize_url)
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {

      })
      event.target.value = ''
      commit(linkTypes.SET_CUSTOM_FAVICON_IMAGE_UPLOAD_LOADER, false)
    },
    async uploadPasswordProtectedLogo({commit, getters, dispatch}, event) {
      commit(linkTypes.SET_PASSWORD_PROTECTED_LOGO_LOADER, true)
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      const logo = await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            // commit(linkTypes.SET_PASSWORD_PROTECTED_LOGO_URL, res.data.resize_url)
            // commit(linkTypes.QUICK_LINK_SET_PASSWORD_PROTECTED_LOGO_URL, res.data.resize_url)
            commit(linkTypes.SET_PASSWORD_PROTECTED_LOGO_LOADER, false)
            return res.data.resize_url
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
            return null
          }
        }
      ).catch(() => {

      })
      event.target.value = ''
      commit(linkTypes.SET_PASSWORD_PROTECTED_LOGO_LOADER, false)
      return logo
    },
    async uploadCSV({commit, getters, dispatch}, event) {
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      const res = await http.post(uploadCSV, formData).then(res => res
      ).catch(err => err)
      event.target.value = ''
      return res
    },
    async fetchCsvHeaders({commit, getters, dispatch}, url) {
      const formData = new FormData()
      formData.append('url', url)
      return await http.post(fetchCSVHeaderUrl, formData)
        .then(res => res)
        .catch(err => err)
    },
    async uploadLinkExpiryLogo({commit, getters, dispatch}, event) {
      commit(linkTypes.SET_LINK_EXPIRY_LOGO_LOADER, true)
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            commit(linkTypes.SET_LINK_EXPIRY_LOGO_URL, res.data.resize_url)
            commit(linkTypes.QUICK_LINK_SET_LINK_EXPIRY_LOGO_URL, res.data.resize_url)
            commit(linkTypes.SET_LINK_EXPIRY_LOGO_LOADER, false)
          } else {
            dispatch('toastNotification', {
              message: res.data.message, type: 'error'
            })
          }
          return res
        }
      ).catch(() => {

      })
      event.target.value = ''
      commit(linkTypes.SET_LINK_EXPIRY_LOGO_LOADER, false)
    },

    setTrafficRouting({commit}, obj) {
      commit(linkTypes.SET_TRAFFIC_ROUTING_RULE, obj.routing_rules)
      commit(linkTypes.SET_TRAFFIC_ROUTING_RULE_EDIT, obj.isEdit)
    }
  },
  mutations: {

    setLinksFetchStatus(state, status) {
      state.link.fetchStatus = status
    },

    setLinksSort(state, sort) {
      state.link.sort = sort
    },
    [linkTypes.SET_LINKS_LIMIT](state, value) {
      state.link.limit = value
    },
    [linkTypes.SET_HAS_LINK_CUSTOM_MESSAGE](state, value) {
      state.saveLink.has_custom_message = value
      state.quickLink.has_custom_message = value
    },
    [linkTypes.SET_LINK_CUSTOM_MESSAGE](state, value) {
      state.saveLink.message = value
      state.quickLink.message = value
    },
    [linkTypes.SET_LINK_CUSTOM_MESSAGE_HEADLINE](state, value) {
      state.saveLink.message_headline = value
      state.quickLink.message = value
    },
    [linkTypes.SET_LINK_TAGS_SELECT_ALL](state, value) {
      state.link.selectAllTags = value
    },

    // new states introduced by @azhard4int
    [linkTypes.SET_LINKS_LIST](state, value) {
      state.link.items = JSON.parse(JSON.stringify(value.data.data))
      state.link.items = state.link.items.map(val => ({...val, fetchStats: true}))
      state.link.page = value.data.current_page
      state.link.limit = value.data.per_page
      state.link.totalItems = value.data.total
      state.link.totalPages = value.data.last_page
    },
    [linkTypes.SET_LINK_ANALYTICS](state, data) {
      state.link.items.filter(link => {
        if (link._id == data._id) {
          link.fetchStats = false
          link.total_visitors = data.data.aggregations.total_visitors.value
          link.unique_visitors = data.data.aggregations.unique_clicks.value
          link.total_clicks_and_conversions = data.data.aggregations.clicked_conversions.doc_count
          if (link.total_visitors <= 0) {
            link.unique_visitors_conversion_rate = 0
          } else {
            const digits = 2
            let value = (link.total_clicks_and_conversions / link.total_visitors) * 100
            value = value * Math.pow(10, digits)
            value = Math.round(value)
            link.unique_visitors_conversion_rate = value / Math.pow(10, digits)
          }
        }
      })
    },
    [linkTypes.SET_LINKS_ARCHIVE_VALUE](state, value) {
      state.link.archive = value
    },
    [linkTypes.SET_LINKS_CAMPAIGNS_LIST](state, value) {
      state.link.campaign = value
    },
    [linkTypes.SET_LINKS_BRANDS_LIST](state, value) {
      state.link.brand = value
    },
    [linkTypes.SET_LINKS_EDIT_VALUE](state, value) {
      state.saveLink = Object.assign(getDefaultSaveLink(), JSON.parse(JSON.stringify(value)))
    },

    [linkTypes.SET_LINKS_DEFAULT_VALUE](state) {
      state.saveLink = getDefaultSaveLink()
    },
    [linkTypes.SET_LINKS_NEW_CREATED](state, value) {
      state.link.new_link_created = value
    },
    [linkTypes.SET_LINK_CAMPAIGN_ID](state, value) {
      state.saveLink.call_to_action_id = value
    },
    [linkTypes.SET_LINK_BRAND_ID](state, value) {
      state.saveLink.call_to_action_id = value
    },
    [linkTypes.SET_CTA_FOR_LINK](state, value) {
      state.link.callToAction = value
    },
    [linkTypes.SET_SAVE_LINK_URL](state, value) {
      state.saveLink.url = value
    },
    [linkTypes.SET_QUICK_LINK_URL](state, value) {
      state.quickLink.url = value
    },
    [linkTypes.SET_QR_CODE_URL](state, value) {
      state.saveLink.qr_code_url = value
    },
    [linkTypes.SET_QR_CODE_DOWNLOAD_LOADER](state, value) {
      state.loaders.qr_code_download = value
    },
    [linkTypes.SET_LINK_NOTE](state, value) {
      state.saveLink.note = value
    },
    [linkTypes.SET_LINK_TAG_FOCUS](state, value) {
      state.link.focus = value
    },
    [linkTypes.SET_LINK_TAGS](state, value) {
      state.saveLink.tags = value
    },
    [linkTypes.SET_LINK_TAG_VALUE](state, value) {
      state.link.linkTag = value
    },
    [linkTypes.SET_LINK_TAG_BOX](state, value) {
      state.link.linkTag = value
    },
    [linkTypes.SET_LINK_SUGGESTED_TAGS](state, value) {
      state.link.suggestedTags = value
    },
    [linkTypes.SET_LINK_SELECTED_TAGS](state, value) {
      state.link.tags = value
    },
    [linkTypes.SET_QUICK_LINK_TAG_LOADER](state, value) {
      state.quickLink.loader = value
    },
    [linkTypes.SET_QUICK_LINK_TAGS](state, value) {
      state.quickLink.tags = value
    },
    [linkTypes.SET_QUICK_LINK_TAG_FOCUS](state, value) {
      state.quickLinkTag.focus = value
    },
    [linkTypes.SET_QUICK_LINK_TAG_VALUE](state, value) {
      state.quickLinkTag.tag = value
    },
    [linkTypes.SET_LINKS_STORE_LOADER](state, value) {
      state.loaders.store = value
    },
    [linkTypes.SET_LINKS_SLUG_LOADER](state, value) {
      state.loaders.slug = value
    },
    [linkTypes.SET_LINKS_RETRIEVE_LOADER](state, value) {
      state.loaders.retrieve = value
    },
    // quick link

    [linkTypes.SET_QUICK_LINK_DEFAULT_VALUE](state) {
      state.quickLink = getDefaultQuickLink()
    },
    [linkTypes.SET_QUICK_LINK_STORE_LOADER](state, value) {
      state.loaders.quick_link = value
    },
    [linkTypes.SET_QUICK_LINK_EDIT_VALUE](state, value) {
      state.quickLink = Object.assign(getDefaultQuickLink(), JSON.parse(JSON.stringify(value)))
    },
    [linkTypes.SET_QUICK_LINK_CAMPAIGN_ID](state, value) {
      state.quickLink.call_to_action_id = value
    },
    [linkTypes.SET_QUICK_LINK_SHORTEN_URL](state, value) {
      state.quickLink.shorten_url = value
    },
    [linkTypes.SET_BULK_LINK_DEFAULT_VALUES](state) {
      state.bulkLink = getDefaultBulkLink()
      state.link.linkfocus = false
      state.link.selectedTags = []
      state.link.suggestedTags = []
      state.link.linkTag = ''
      state.link.focus = false
    },
    [linkTypes.SET_LINKS_DATE_RANGE](state, date) {
      if (date == null) {
        state.link.date = {
          label: 'All Time',
          value: ''
        }
      } else {
        state.link.date = date
      }
    },
    [linkTypes.SET_EXPORT_LINKS_CAMPAIGNS](state, value) {
      state.export_links.campaigns = value
    },
    [linkTypes.SET_EXPORT_LINKS_CAMPAIGNS_OPTION](state, value) {
      state.export_links.campaigns_options = value
    },
    [linkTypes.SET_EXPORT_LINKS_BRANDS](state, value) {
      state.export_links.brands = value
    },
    [linkTypes.SET_EXPORT_LINKS_DEFAULT_VALUE](state, value) {
      state.export_links = getDefaultExportLinksOption()
    },
    [linkTypes.SET_LINK_PREVIEW_LOADER](state, value) {
      state.loaders.link_preview = value
    },
    [linkTypes.SET_LINK_PREVIEW_URL](state, value) {
      state.link.previewURL = value
    },
    [linkTypes.SET_LINK_PREVIEW_STATUS](state, value) {
      state.link.previewStatus = value
    },
    [linkTypes.SET_LINK_PREVIEW_ERROR](state, value) {
      state.link.previewErrorMessage = value
    },
    [linkTypes.SET_LINK_PREVIEW_REQUESTS](state, value) {
      state.link.cancelRequest = value
    },
    [linkTypes.SET_BULK_LINKS_SELECTION_ITEMS](state, value) {
      state.bulk_selection.items = value
    },
    [linkTypes.SET_CUSTOM_IMAGE_URL](state, value) {
      state.saveLink.seo.image = value
      state.quickLink.seo.image = value
    },
    [linkTypes.SET_CUSTOM_FAVICON_IMAGE_URL](state, value) {
      state.saveLink.custom_favicon_url = value
      state.quickLink.custom_favicon_url = value
    },
    [linkTypes.SET_CUSTOM_META_IMAGE_UPLOAD_LOADER](state, value) {
      state.loaders.custom_meta_image = value
    },
    [linkTypes.SET_CUSTOM_FAVICON_IMAGE_UPLOAD_LOADER](state, value) {
      state.loaders.custom_favicon_image_url = value
    },
    [linkTypes.SET_CUSTOM_META_IMAGE_URL](state, value) {
      state.loaders.custom_meta_image_url = value
    },
    [linkTypes.SET_BULK_LINKS_SELECTION_ALL](state, value) {
      state.bulk_selection.all = value
    },
    [linkTypes.SET_PASSWORD_PROTECTED_LOGO_LOADER](state, value) {
      state.loaders.password_protected_logo = value
    },
    [linkTypes.SET_PASSWORD_PROTECTED_LOGO_URL](state, value) {
      state.saveLink.password_protected_logo = value
    },
    [linkTypes.QUICK_LINK_SET_PASSWORD_PROTECTED_LOGO_URL](state, value) {
      state.quickLink.password_protected_logo = value
    },
    [linkTypes.SET_LINK_EXPIRY_LOGO_LOADER](state, value) {
      state.loaders.link_expiry_logo = value
    },
    [linkTypes.SET_LINK_EXPIRY_LOGO_URL](state, value) {
      state.saveLink.link_expire_attributes.link_expiry_logo = value
    },
    [linkTypes.QUICK_LINK_SET_LINK_EXPIRY_LOGO_URL](state, value) {
      state.quickLink.link_expire_attributes.link_expiry_logo = value
    },
    [linkTypes.SET_TRAFFIC_ROUTING_RULE](state, value) {
      state.saveLink.routing_rules = value
      state.quickLink.routing_rules = value
    },
    [linkTypes.SET_TRAFFIC_ROUTING_RULE_EDIT](state, value) {
      state.saveLink.routing_rules_edit = value
      state.quickLink.routing_rules_edit = value
    }
  }
}
