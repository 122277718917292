export const authenticationTypes = {
  SET_JWT_TOKEN: 'SET_JWT_TOKEN',
  SET_LOADED_ONCE: 'SET_LOADED_ONCE',
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  SET_AUTHENTICATING: 'SET_AUTHENTICATING'
}

export const projectTypes = {
  SET_PROJECT_LIST: 'SET_PROJECT_LIST',
  ADD_TO_PROJECT_LIST: 'ADD_TO_PROJECT_LIST',
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT'
}

export const deviceTypes = {
  SET_DEVICES_LIST: 'SET_DEVICES_LIST',
  ADD_TO_DEVICES_LIST: 'ADD_TO_DEVICES_LIST',
  SET_ACTIVE_DEVICE: 'SET_ACTIVE_DEVICE',
  RESET_ACTIVE_DEVICE: 'RESET_ACTIVE_DEVICE',
  UPDATE_DEVICE_FROM_LIST: 'UPDATE_DEVICE_FROM_LIST',
  SET_DEVICE_FETCHED_TIME: 'SET_DEVICE_FETCHED_TIME'
}

export const subscriptionTypes = {
  SET_SUBSCRIPTIONS_LIST: 'SET_SUBSCRIPTIONS_LIST',
  SET_PADDLE_SUBSCRIPTIONS_LIST: 'SET_PADDLE_SUBSCRIPTIONS_LIST',
  SET_PADDLE_PRODUCTS_LIST: 'SET_PADDLE_PRODUCTS_LIST'
}

export const notesTypes = {
  SET_NOTES: 'SET_NOTES',
  ADD_TO_NOTES_LIST: 'ADD_TO_NOTES_LIST',
  RESET_STORE_NOTE: 'RESET_STORE_NOTE',
  SET_STORE_NOTE: 'SET_STORE_NOTE',
  SET_STORE_NOTE_TEXT: 'SET_STORE_NOTE_TEXT'
}

export const userTypes = {
  SET_MODAL_DATA: 'SET_MODAL_DATA',
  SET_WHITELABEL: 'SET_WHITELABEL',
  SET_MODAL_STATUS: 'SET_MODAL_STATUS',
  SET_CLOSE_CONFIRMATION_MODAL: 'SET_CLOSE_CONFIRMATION_MODAL',
  SET_PROFILE_ARCHIVE_MESSAGE_STATUS: 'SET_PROFILE_ARCHIVE_MESSAGE_STATUS',
  REMOVE_TEAM_MEMBER_ID: 'REMOVE_TEAM_MEMBER_ID',
  KEEP_TEAM_MEMBER_DATA: 'KEEP_TEAM_MEMBER_DATA',
  SET_TEAM_ADD_VALUE: 'SET_TEAM_ADD_VALUE',
  RESET_TEAM_ADD: 'RESET_TEAM_ADD',
  SET_TEAM_MEMBERS: 'SET_TEAM_MEMBERS',
  SET_TEAM_ADD_UTM_PERMISSION: 'SET_TEAM_ADD_UTM_PERMISSION',
  SET_TEAM_MEMBERS_RETRIEVE_LOADER: 'SET_TEAM_MEMBERS_RETRIEVE_LOADER',
  SET_TEAM_MEMBERS_RETRIEVE_STATUS: 'SET_TEAM_MEMBERS_RETRIEVE_STATUS',
  SET_TEAM_MEMBER_STORE_LOADER: 'SET_TEAM_MEMBER_STORE_LOADER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_WHITELABEL_LOADER: 'SET_WHITELABEL_LOADER',
  SET_SUBSCRIPTIONS_LOADER: 'SET_SUBSCRIPTIONS_LOADER'
}

export const linkTypes = {
  SET_LINKS_LIST: 'SET_LINKS_LIST',
  SET_LINK_ANALYTICS: 'SET_LINK_ANALYTICS',
  SET_LINKS_LIMIT: 'SET_LINKS_LIMIT',
  SET_LINKS_STORE_LOADER: 'SET_LINKS_STORE_LOADER',
  SET_LINKS_RETRIEVE_LOADER: 'SET_LINKS_RETRIEVE_LOADER',
  SET_LINKS_SLUG_LOADER: 'SET_LINKS_SLUG_LOADER',
  SET_LINKS_ARCHIVE_VALUE: 'SET_LINKS_ARCHIVE_VALUE',
  SET_LINKS_CAMPAIGNS_LIST: 'SET_LINKS_CAMPAIGNS_LIST',
  SET_LINKS_BRANDS_LIST: 'SET_LINKS_BRANDS_LIST',
  SET_LINKS_EDIT_VALUE: 'SET_LINKS_EDIT_VALUE',
  SET_LINKS_DEFAULT_VALUE: 'SET_LINKS_DEFAULT_VALUE',
  SET_LINKS_NEW_CREATED: 'SET_LINKS_NEW_CREATED',
  SET_LINKS_DATE_RANGE: 'SET_LINKS_DATE_RANGE',
  SET_LINK_CAMPAIGN_ID: 'SET_LINK_CAMPAIGN_ID',
  SET_CTA_FOR_LINK: 'SET_CTA_FOR_LINK',
  SET_SAVE_LINK_URL: 'SET_SAVE_LINK_URL',
  SET_QUICK_LINK_URL: 'SET_QUICK_LINK_URL',
  SET_QR_CODE_URL: 'SET_QR_CODE_URL',
  SET_QR_CODE_DOWNLOAD_LOADER: 'SET_QR_CODE_DOWNLOAD_LOADER',
  SET_LINK_TAG_FOCUS: 'SET_LINK_TAG_FOCUS',
  SET_LINK_NOTE: 'SET_LINK_NOTE',
  SET_LINK_TAGS: 'SET_QUICK_LINK_DEFAULT_VALUESET_LINK_TAGS',
  SET_LINK_TAG_BOX: 'SET_LINK_TAG_BOX',
  SET_LINK_TAG_VALUE: 'SET_LINK_TAG_VALUE',
  SET_LINK_SUGGESTED_TAGS: 'SET_LINK_SUGGESTED_TAGS',
  SET_LINK_SELECTED_TAGS: 'SET_LINK_SELECTED_TAGS',
  SET_QUICK_LINK_TAG_LOADER: 'SET_QUICK_LINK_TAG_LOADER',
  SET_QUICK_LINK_TAG_FOCUS: 'SET_QUICK_LINK_TAG_FOCUS',
  SET_QUICK_LINK_TAG_VALUE: 'SET_QUICK_LINK_TAG_VALUE',
  SET_QUICK_LINK_TAGS: 'SET_QUICK_LINK_TAGS',
  SET_QUICK_LINK_DEFAULT_VALUE: 'SET_QUICK_LINK_DEFAULT_VALUE',
  SET_QUICK_LINK_STORE_LOADER: 'SET_QUICK_LINK_STORE_LOADER',
  SET_QUICK_LINK_SHORTEN_URL: 'SET_QUICK_LINK_SHORTEN_URL',
  SET_QUICK_LINK_EDIT_VALUE: 'SET_QUICK_LINK_EDIT_VALUE',
  SET_QUICK_LINK_CAMPAIGN_ID: 'SET_QUICK_LINK_CAMPAIGN_ID',
  SET_BULK_LINK_DEFAULT_VALUES: 'SET_BULK_LINK_DEFAULT_VALUES',
  SET_LINK_CUSTOM_MESSAGE: 'SET_LINK_CUSTOM_MESSAGE',
  SET_LINK_CUSTOM_MESSAGE_HEADLINE: 'SET_LINK_CUSTOM_MESSAGE_HEADLINE',
  SET_LINK_TAGS_SELECT_ALL: 'SET_LINK_TAGS_SELECT_ALL',
  SET_EXPORT_LINKS_CAMPAIGNS: 'SET_EXPORT_LINKS_CAMPAIGNS',
  SET_EXPORT_LINKS_CAMPAIGNS_OPTION: 'SET_EXPORT_LINKS_CAMPAIGNS_OPTION',
  SET_EXPORT_LINKS_DEFAULT_VALUE: 'SET_EXPORT_LINKS_DEFAULT_VALUE',
  SET_EXPORT_LINKS_BRANDS: 'SET_EXPORT_LINKS_BRANDS',
  SET_LINK_PREVIEW_URL: 'SET_LINK_PREVIEW_URL',
  SET_LINK_PREVIEW_LOADER: 'SET_LINK_PREVIEW_LOADER',
  SET_LINK_PREVIEW_STATUS: 'SET_LINK_PREVIEW_STATUS',
  SET_LINK_PREVIEW_ERROR: 'SET_LINK_PREVIEW_ERROR',
  SET_META_FETCH_STATUS: 'SET_META_FETCH_STATUS',
  SET_LINK_PREVIEW_REQUESTS: 'SET_LINK_PREVIEW_REQUESTS',
  SET_BULK_LINKS_SELECTION_ITEMS: 'SET_BULK_LINKS_SELECTION_ITEMS',
  SET_CUSTOM_IMAGE_URL: 'SET_CUSTOM_IMAGE_URL',
  SET_CUSTOM_META_IMAGE_URL: 'SET_CUSTOM_META_IMAGE_URL',
  SET_CUSTOM_FAVICON_IMAGE_URL: 'SET_CUSTOM_FAVICON_IMAGE_URL',
  SET_CUSTOM_META_IMAGE_UPLOAD_LOADER: 'SET_CUSTOM_META_IMAGE_UPLOAD_LOADER',
  SET_CUSTOM_FAVICON_IMAGE_UPLOAD_LOADER: 'SET_CUSTOM_FAVICON_IMAGE_UPLOAD_LOADER',
  SET_BULK_LINKS_SELECTION_ALL: 'SET_BULK_LINKS_SELECTION_ALL',
  SET_PASSWORD_PROTECTED_LOGO_LOADER: 'SET_PASSWORD_PROTECTED_LOGO',
  SET_PASSWORD_PROTECTED_LOGO_URL: 'SET_PASSWORD_PROTECTED_URL',
  SET_LINK_EXPIRY_LOGO_LOADER: 'SET_LINK_EXPIRY_LOGO_LOADER',
  SET_LINK_EXPIRY_LOGO_URL: 'SET_LINK_EXPIRY_LOGO_URL',
  QUICK_LINK_SET_PASSWORD_PROTECTED_LOGO_URL: 'QUICK_LINK_SET_PASSWORD_PROTECTED_LOGO_URL',
  QUICK_LINK_SET_LINK_EXPIRY_LOGO_URL: 'QUICK_LINK_SET_LINK_EXPIRY_LOGO_URL',
  SET_TRAFFIC_ROUTING_RULE: 'SET_TRAFFIC_ROUTING_RULE',
  SET_TRAFFIC_ROUTING_RULE_EDIT: 'SET_TRAFFIC_ROUTING_RULE_EDIT',
  SET_HAS_LINK_CUSTOM_MESSAGE: 'SET_HAS_LINK_CUSTOM_MESSAGE'
}

export const campaignTypes = {
  RESET_CAMPAIGN_ADD: 'RESET_CAMPAIGN_ADD',
  SET_CAMPAIGN_ADD_EDIT_VALUE: 'SET_CAMPAIGN_ADD_EDIT_VALUE',
  SET_CAMPAIGNS_LIST: 'SET_CAMPAIGNS_LIST',
  SET_CAMPAIGNS_ANALYTICS: 'SET_CAMPAIGNS_ANALYTICS',
  SET_CAMPAIGNS_ARCHIVE_VALUE: 'SET_CAMPAIGNS_ARCHIVE_VALUE',
  ADD_CAMPAIGNS_TO_LIST: 'ADD_CAMPAIGNS_TO_LIST',
  EDIT_CAMPAIGN_NAME: 'EDIT_CAMPAIGN_NAME',
  SET_CAMPAIGNS_SORT_VALUE: 'SET_CAMPAIGNS_SORT_VALUE',
  SET_CAMPAIGNS_DATE_VALUE: 'SET_CAMPAIGNS_DATE_VALUE',
  SET_CAMPAIGN_ADD_BRAND: 'SET_CAMPAIGN_ADD_BRAND',
  SET_DEFAULT_CAMPAIGN_ANALYTICS: 'SET_DEFAULT_CAMPAIGN_ANALYTICS',
  SET_COMPONENT_CAMPAIGN_LIST: 'SET_COMPONENT_CAMPAIGN_LIST',

  SET_CAMPAIGN_ADD_BUTTON_URL: 'SET_CAMPAIGN_ADD_BUTTON_URL',
  SET_CAMPAIGN_ADD_IMAGE: 'SET_CAMPAIGN_ADD_IMAGE',
  SET_CAMPAIGN_ADD_RESIZE_IMAGE: 'SET_CAMPAIGN_ADD_RESIZE_IMAGE',
  SET_CAMPAIGN_IMAGE_LOADER: 'SET_CAMPAIGN_IMAGE_LOADER',
  SET_EXIT_INTENT_IMAGE_LOADER: 'SET_EXIT_INTENT_IMAGE_LOADER',
  SET_BACKGROUND_IMAGE_EXIT_INTENT: 'SET_BACKGROUND_IMAGE_EXIT_INTENT',
  SET_IS_LIST_FETCHED: 'SET_IS_LIST_FETCHED',
  SET_IS_ALL_LIST_FETCHED: 'SET_IS_ALL_LIST_FETCHED',
  SET_CAMPAIGN_LIST: 'SET_CAMPAIGN_LIST',

  SET_CAMPAIGN_ADD_TYPE: 'SET_CAMPAIGN_ADD_TYPE',
  SET_CAMPAIGN_ADD_CTA_VALUE: 'SET_CAMPAIGN_ADD_CTA_VALUE',
  SET_CAMPAIGN_ADD_TYPE_THIRD_PARTY_VALUE: 'SET_CAMPAIGN_ADD_TYPE_THIRD_PARTY_VALUE',
  SET_CAMPAIGN_ADD_CUSTOM_DOMAIN: 'SET_CAMPAIGN_ADD_CUSTOM_DOMAIN',
  REMOVE_FROM_CAMPAIGN_CODE_WIDGET_LIST: 'REMOVE_FROM_CAMPAIGN_CODE_WIDGET_LIST',
  SET_CAMPAIGN_CODE_WIDGET_ID: 'SET_CAMPAIGN_CODE_WIDGET_ID',
  ADD_TO_CAMPAIGN_CODE_WIDGET_LIST: 'ADD_TO_CAMPAIGN_CODE_WIDGET_LIST',
  SET_CAMPAIGN_PIXEL_ADD_CHECK: 'SET_CAMPAIGN_PIXEL_ADD_CHECK',
  SET_CAMPAIGN_RETARGETING_PIXEL_LIST: 'SET_CAMPAIGN_RETARGETING_PIXEL_LIST',
  // email list
  SET_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS: 'SET_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS',
  REMOVE_CAMPAIGN_ADD_EMAIL_SERVICE_TAG: 'REMOVE_CAMPAIGN_ADD_EMAIL_SERVICE_TAG',
  ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS: 'ADD_TAG_TO_CAMPAIGN_ADD_EMAIL_SERVICE_TAGS',
  SET_CAMPAIGN_ADD_EMAIL_SERVICE_ID: 'SET_CAMPAIGN_ADD_EMAIL_SERVICE_ID',
  SET_CAMPAIGN_ADD_EMAIL_SERVICE_NAME: 'SET_CAMPAIGN_ADD_EMAIL_SERVICE_NAME',
  SET_CAMPAIGN_ADD_EMAIL_LIST: 'SET_CAMPAIGN_ADD_EMAIL_LIST',
  SET_CAMPAIGN_PROVIDER_EMAIL_TAGS: 'SET_CAMPAIGN_PROVIDER_EMAIL_TAGS',
  SET_CAMPAIGN_PROVIDER_SUGGESTED_TAGS: 'SET_CAMPAIGN_PROVIDER_SUGGESTED_TAGS',
  SET_CAMPAIGN_PROVIDER_TAG_TEXT: 'SET_CAMPAIGN_PROVIDER_TAG_TEXT',
  SET_CAMPAIGN_PROVIDER_TAG_FOCUS: 'SET_CAMPAIGN_PROVIDER_TAG_FOCUS',
  SET_CAMPAIGN_PROVIDER_LOADER: 'SET_CAMPAIGN_PROVIDER_LOADER',
  ADD_TAG_TO_CAMPAIGN_PROVIDER_EMAIL_TAGS: 'ADD_TAG_TO_CAMPAIGN_PROVIDER_EMAIL_TAGS',
  REMOVE_TAG_FROM_CAMPAIGN_PROVIDER_EMAIL_TAGS: 'REMOVE_TAG_FROM_CAMPAIGN_PROVIDER_EMAIL_TAGS',
  REMOVE_TAG_FROM_CAMPAIGN_PROVIDER_SUGGESTED_TAGS: 'REMOVE_TAG_FROM_CAMPAIGN_PROVIDER_SUGGESTED_TAGS',
  RESET_CAMPAIGN_PROVIDER: 'RESET_CAMPAIGN_PROVIDER',
  SET_DEFAULT_WORKSPACE_CAMPAIGN: 'SET_DEFAULT_WORKSPACE_CAMPAIGN',
  // filters
  SET_CAMPAIGN_BRAND_FILTERS: 'SET_CAMPAIGN_BRAND_FILTERS',
  SET_CAMPAIGN_SOCIAL_CTA_STATS: 'SET_CAMPAIGN_SOCIAL_CTA_STATS',
  SET_CAMPAIGN_RETRIEVE_LOADER: 'SET_CAMPAIGN_RETRIEVE_LOADER',
  SET_CAMPAIGN_DASHBOARD_LOADER: 'SET_CAMPAIGN_DASHBOARD_LOADER',
  RESET_CAMPAIGN_VALIDATIONS: 'RESET_CAMPAIGN_VALIDATIONS',
  RESET_CAMPAIGN_SECTION_VALIDATIONS: 'RESET_CAMPAIGN_SECTION_VALIDATIONS',
  SET_CAMPAIGN_SECTION_VALIDATIONS: 'SET_CAMPAIGN_SECTION_VALIDATIONS'
}

export const domainTypes = {
  ADD_DOMAIN_ID_TO_LIST: 'ADD_DOMAIN_ID_TO_LIST',
  ADD_DOMAIN_TO_LIST: 'ADD_DOMAIN_TO_LIST',
  UPDATE_DOMAIN_TO_LIST: 'UPDATE_DOMAIN_TO_LIST',
  SET_DOMAINS: 'SET_DOMAINS',
  SET_DOMAINS_IDS: 'SET_DOMAINS_IDS',
  SET_DOMAIN_CONNECTIVITY: 'SET_DOMAIN_CONNECTIVITY',
  SET_ADD_DOMAIN: 'SET_ADD_DOMAIN',
  SET_DNS_RECORDS: 'SET_DNS_RECORDS',
  GET_DNS_RECORDS_LOADER: 'GET_DNS_RECORDS_LOADER',
  RESET_ADD_DOMAIN: 'RESET_ADD_DOMAIN',
  CUSTOM_DOMAIN_LOADER: 'CUSTOM_DOMAIN_LOADER',
  SET_DEFAULT_DOMAIN: 'SET_DEFAULT_DOMAIN',
  SET_DOMAIN_FETCH_STATUS: 'SET_DOMAIN_FETCH_STATUS'
}

export const pixelTypes = {
  SET_PIXELS_LIST: 'SET_PIXELS_LIST',
  ADD_PIXEL_TO_LIST: 'ADD_PIXEL_TO_LIST',
  REMOVE_PIXEL_FROM_LIST: 'REMOVE_PIXEL_FROM_LIST',
  SET_PIXEL: 'SET_PIXEL',
  SET_PIXEL_LOADER_ADD: 'SET_PIXEL_LOADER_ADD',
  SET_PIXEL_LOADER_GET: 'SET_PIXEL_LOADER_GET',
  SET_PIXEL_LIST_ALL: 'SET_PIXEL_LIST_ALL',
  SET_PIXEL_FETCH_STATUS: 'SET_PIXEL_FETCH_STATUS'
}

export const utmTypes = {
  SET_UTM_LIST: 'SET_UTM_LIST',
  ADD_TO_UTM_LIST: 'ADD_TO_UTM_LIST',
  UPDATE_EXISTING_UTM: 'UPDATE_EXISTING_UTM',
  REMOVE_UTM_FROM_LIST: 'REMOVE_UTM_FROM_LIST',
  RESET_CREATE_UTM: 'RESET_CREATE_UTM',
  EDIT_CREATE_UTM: 'EDIT_CREATE_UTM',
  CHANGE_SELECTED_UTM: 'CHANGE_SELECTED_UTM',
  RESET_SELECTED_UTM: 'RESET_SELECTED_UTM',
  SET_UTM_RETRIEVE_LOADER: 'SET_UTM_RETRIEVE_LOADER',
  SET_UTM_PREVIOUS_ID: 'SET_UTM_PREVIOUS_ID'
}

export const tagTypes = {
  SET_TAGS_LIST: 'SET_TAGS_LIST',
  ADD_TAG_TO_LIST: 'ADD_TAG_TO_LIST',
  SET_TAG_SELECTION: 'SET_TAG_SELECTION',
  SET_TAG_EDIT_VALUE: 'SET_TAG_EDIT_VALUE',
  SET_TAG_MERGE_VALUE: 'SET_TAG_MERGE_VALUE'
}

export const widgetTypes = {
  SET_WIDGET_LIST: 'SET_WIDGET_LIST',
  ADD_WIDGET_TO_LIST: 'ADD_WIDGET_TO_LIST',
  RESET_WIDGET: 'RESET_WIDGET',
  SET_ADD_CUSTOM_WIDGET_FROM_CTA: 'SET_ADD_CUSTOM_WIDGET_FROM_CTA',
  SET_CUSTOM_WIDGET_ADD: 'SET_CUSTOM_WIDGET_ADD',
  SET_CUSTOM_WIDGET_LOADER: 'SET_CUSTOM_WIDGET_LOADER'

}

export const brandTypes = {
  SET_BRAND_LIST: 'SET_BRAND_LIST',
  SET_BRAND_LIST_ALL: 'SET_BRAND_LIST_ALL',
  ADD_BRAND_TO_LIST: 'ADD_BRAND_TO_LIST',
  ADD_CAMPAIGN_TO_BRANDS_LIST: 'ADD_CAMPAIGN_TO_BRANDS_LIST',
  REMOVE_BRAND_FROM_LIST: 'REMOVE_BRAND_FROM_LIST',
  SET_BRAND_SORT_VALUE: 'SET_BRAND_SORT_VALUE',
  SET_BRAND_DATE_VALUE: 'SET_BRAND_DATE_VALUE',
  SET_BRAND_AVATAR: 'SET_BRAND_AVATAR',
  SET_BRAND_AVATAR_RESIZE_URL: 'SET_BRAND_AVATAR_RESIZE_URL',
  SET_BRAND_ARCHIVE_STATUS: 'SET_BRAND_ARCHIVE_STATUS',
  SET_BRAND_IMAGE_UPLOAD_LOADER: 'SET_BRAND_IMAGE_UPLOAD_LOADER',
  SET_BRAND_STORE_LOADER: 'SET_BRAND_STORE_LOADER',
  SET_BRAND_RETRIEVE_LOADER: 'SET_BRAND_RETRIEVE_LOADER',
  SET_BRAND_ADD_VALUE: 'SET_BRAND_ADD_VALUE',
  SET_BRAND_ADD_NAME_VALUE: 'SET_BRAND_ADD_NAME_VALUE',
  RESET_BRAND_ADD: 'RESET_BRAND_ADD',
  SET_BRAND_ANALYTICS: 'SET_BRAND_ANALYTICS',
  SET_DEFAULT_BRAND_ANALYTICS: 'SET_DEFAULT_BRAND_ANALYTICS',
  SET_IS_ALL_LIST_FETCHED: 'SET_IS_ALL_LIST_FETCHED',
  SET_IS_LIST_FETCHED: 'SET_IS_LIST_FETCHED',
  SET_COMPONENT_BRAND_LIST: 'SET_COMPONENT_BRAND_LIST',
  SET_BRAND_LISTS: 'SET_BRAND_LISTS',
  SET_WORKSPACE: 'SET_WORKSPACE'
}

export const notificationTypes = {
  SET_NOTIFICATIONS_LIST: 'SET_NOTIFICATIONS_LIST',
  SET_NOTIFICATIONS_UNREAD_COUNT: 'SET_NOTIFICATIONS_UNREAD_COUNT'
}

export const rssTypes = {
  SET_RSS_LIST: 'SET_RSS_LIST',
  SET_RSS_LOADER_PREVIEW: 'SET_RSS_LOADER_PREVIEW',
  SET_RSS_LOADER_RECRAWL: 'SET_RSS_LOADER_RECRAWL',
  SET_RSS_LOADER_STORE: 'SET_RSS_LOADER_STORE',
  SET_RSS_LOADER_RETRIEVE: 'SET_RSS_LOADER_RETRIEVE',
  SET_RSS_SHOW_PREVIEW: 'SET_RSS_SHOW_PREVIEW',
  SET_RSS_ADD_DEFAULT: 'SET_RSS_ADD_DEFAULT',
  SET_RSS_ADD_VALUE: 'SET_RSS_ADD_VALUE',
  ADD_ITEM_TO_RSS_LIST: 'ADD_ITEM_TO_RSS_LIST',
  SET_RSS_PREVIEW_ORIGINAL_DATA: 'SET_RSS_PREVIEW_ORIGINAL_DATA',
  SET_RSS_PREVIEW_DATA: 'SET_RSS_PREVIEW_DATA'
}

export const reportTypes = {
  SET_REPORTS_ADD_CAMPAIGNS_SELECTION: 'SET_REPORTS_ADD_CAMPAIGNS_SELECTION',
  SET_REPORTS_ADD_CAMPAIGNS_OPTION: 'SET_REPORTS_ADD_CAMPAIGNS_OPTION',
  SET_REPORTS_ADD_EMAIL_SELECTION: 'SET_REPORTS_ADD_EMAIL_SELECTION',
  SET_REPORTS_ADD_EMAIL_OPTIONS: 'SET_REPORTS_ADD_EMAIL_OPTIONS',
  SET_REPORTS_LOADER_STORE: 'SET_REPORTS_LOADER_STORE',
  SET_REPORTS_RETRIEVE_STORE: 'SET_REPORTS_RETRIEVE_STORE',
  SET_EMAIL_REPORTS: 'SET_EMAIL_REPORTS',
  SET_REPORTS_LIST: 'SET_REPORTS_LIST',
  SET_REPORTS_ADD_DEFAULT_VALUE: 'SET_REPORTS_ADD_DEFAULT_VALUE',
  SET_REPORTS_ARCHIVE_STATUS: 'SET_REPORTS_ARCHIVE_STATUS',
  SET_REPORTS_SEARCH_VALUE: 'SET_REPORTS_SEARCH_VALUE'
}

export const analyticsTypes = {
  RESET_ANALYTICS_CAMPAIGN_FILTER: 'RESET_ANALYTICS_CAMPAIGN_FILTER',
  RESET_ANALYTICS_LINK_FILTER: 'RESET_ANALYTICS_LINK_FILTER',
  SET_ANALYTICS_LIST_BRANDS: 'SET_ANALYTICS_LIST_BRANDS',
  RESET_ANALYTICS_BRAND_FILTER: 'RESET_ANALYTICS_BRAND_FILTER',
  SET_ANALYTICS_BRAND_FILTER: 'SET_ANALYTICS_BRAND_FILTER',
  SET_ANALYTICS_CAMPAIGN_FILTER: 'SET_ANALYTICS_CAMPAIGN_FILTER',
  SET_ANALYTICS_LINK_FILTER: 'SET_ANALYTICS_LINK_FILTER',
  SET_ANALYTICS_BIO_LINK_FILTER: 'SET_ANALYTICS_BIO_LINK_FILTER',
  SET_ANALYTICS_DATE: 'SET_ANALYTICS_DATE',
  SET_ANALYTICS_VIEW_FILTER: 'SET_ANALYTICS_VIEW_FILTER'
}

export const bioLinkTypes = {
  SET_BIO_LINK_ITEMS: 'SET_BIO_LINK_ITEMS',
  SET_BIO_LINK_ANALYTICS: 'SET_BIO_LINK_ANALYTICS',
  SET_BIO_ADD_VALUE: 'SET_BIO_ADD_VALUE',
  SET_BIO_ADD_BRAND: 'SET_BIO_ADD_BRAND',
  SET_BIO_ADD_ID: 'SET_BIO_ADD_ID',
  SET_BIO_ADD_IS_CREATING_BIO: 'SET_BIO_ADD_IS_CREATING_BIO',
  SET_BIO_ADD_SHORTEN_URL: 'SET_BIO_ADD_SHORTEN_URL',
  SET_BIO_ADD_SHORTEN_DOMAIN: 'SET_BIO_ADD_SHORTEN_DOMAIN',
  SET_BIO_LINK_DOMAIN: 'SET_BIO_LINK_DOMAIN',
  SET_BIO_ADD_SHORTEN_DOMAIN_ID: 'SET_BIO_ADD_SHORTEN_DOMAIN_ID',
  SET_BIO_ADD_IMAGE: 'SET_BIO_ADD_IMAGE',
  SET_GRAPH_ADD_IMAGE: 'SET_GRAPH_ADD_IMAGE',
  SET_FAVICON_ADD_IMAGE: 'SET_FAVICON_ADD_IMAGE',
  SET_BIO_ADD_RESIZED_IMAGE: 'SET_BIO_ADD_RESIZED_IMAGE',
  SET_GRAPH_ADD_RESIZED_IMAGE: 'SET_GRAPH_ADD_RESIZED_IMAGE',
  SET_BIO_ADD_THEME: 'SET_BIO_ADD_THEME',
  SET_BIO_CONTENT_SECTION_ADD_LINKS: 'SET_BIO_CONTENT_SECTION_ADD_LINKS',
  SET_BIO_CONTENT_SECTION_ADD: 'SET_BIO_CONTENT_SECTION_ADD',
  SET_QR_CODE_URL: 'SET_QR_CODE_URL',
  RESET_BIO_CONTENT_SECTION_ADD: 'RESET_BIO_CONTENT_SECTION_ADD',
  ADD_BIO_CONTENT_SECTION_ITEM: 'ADD_BIO_CONTENT_SECTION_ITEM',
  UPDATE_BIO_CONTENT_SECTION_ITEM: 'UPDATE_BIO_CONTENT_SECTION_ITEM',
  REMOVE_BIO_CONTENT_SECTION_ITEM: 'REMOVE_BIO_CONTENT_SECTION_ITEM',
  SET_BIO_CONTENT_SECTION_TYPE: 'SET_BIO_CONTENT_SECTION_TYPE',
  SET_BIO_CONTENT_SECTION_POSTS: 'SET_BIO_CONTENT_SECTION_POSTS',
  ADD_BIO_LINK_ITEM: 'ADD_BIO_LINK_ITEM',
  RESET_BIO_LINK_ITEM: 'RESET_BIO_LINK_ITEM',
  REMOVE_BIO_LINK_ITEM: 'REMOVE_BIO_LINK_ITEM',
  REMOVE_BIO_LINK_ITEM_WITH_INDEX: 'REMOVE_BIO_LINK_ITEM_WITH_INDEX',
  UPDATE_BIO_LINK_ITEM: 'UPDATE_BIO_LINK_ITEM',
  UPDATE_BIO_CONTENT_SECTION_OBJECT: 'UPDATE_BIO_CONTENT_SECTION_OBJECT',
  SET_IGNORE_CHANGES: 'SET_IGNORE_CHANGES',
  SET_BIO_LINK_ARCHIVE_VALUE: 'SET_BIO_LINK_ARCHIVE_VALUE',
  SET_BIO_LINK_RETRIEVE_LOADER: 'SET_BIO_LINK_RETRIEVE_LOADER',
  SET_BIO_LINK_PAGINATION: 'SET_BIO_LINK_PAGINATION',
  SET_BIO_AUDIO_CONTENT_SECTION_TYPE: 'SET_BIO_AUDIO_CONTENT_SECTION_TYPE',
  SET_AUDIO_CONTENT_SECTION_ADD: 'SET_AUDIO_CONTENT_SECTION_ADD',
  SET_VIDEO_CONTENT_SECTION_ADD: 'SET_VIDEO_CONTENT_SECTION_ADD',
  SET_CARD_CONTENT_SECTION_ADD: 'SET_CARD_CONTENT_SECTION_ADD',
  SET_TEXT_CONTENT_SECTION_ADD: 'SET_TEXT_CONTENT_SECTION_ADD',
  SET_RSS_CONTENT_SECTION_ADD: 'SET_RSS_CONTENT_SECTION_ADD',
  SET_RSS_CONTENT_SECTION_POSTS: 'SET_RSS_CONTENT_SECTION_POSTS',
  SET_BIO_VIDEO_CONTENT_SECTION_TYPE: 'SET_BIO_VIDEO_CONTENT_SECTION_TYPE',
  SET_BIO_CARD_CONTENT_SECTION_TYPE: 'SET_BIO_CARD_CONTENT_SECTION_TYPE',
  SET_BIO_TEXT_CONTENT_SECTION_TYPE: 'SET_BIO_TEXT_CONTENT_SECTION_TYPE',
  SET_BIO_RSS_CONTENT_SECTION_TYPE: 'SET_BIO_RSS_CONTENT_SECTION_TYPE'
}

export const clientTypes = {
  SET_CLIENT: 'SET_CLIENT',
  REMOVE_CLIENT: 'REMOVE_CLIENT'
}

export const blacklistIp = {
  SET_BLACKLIST_IP_LOADER_GET: 'SET_BLACKLIST_IP_LOADER_GET',
  SET_BLACKLIST_IP_LIST: 'SET_BLACKLIST_IP_LIST',
  ADD_BLACKLIST_IP_TO_LIST: 'ADD_BLACKLIST_IP_TO_LIST',
  SET_BLACKLIST_IP: 'SET_BLACKLIST_IP',
  RESET_BLACKLIST_IP: 'RESET_BLACKLIST_IP'
}

export const deepLinksTypes = {
  SHOW_DEEP_LINKS_SETTINGS: 'SHOW_DEEP_LINKS_SETTINGS',
  SHOW_DEEP_LINKS_SETTINGS_LOADER: 'SHOW_DEEP_LINKS_SETTINGS_LOADER',
  SET_SELECTED_DEEP_LINK_ITEM: 'SET_SELECTED_DEEP_LINK_ITEM',
  SET_CREATE_DEEP_LINK: 'SET_CREATE_DEEP_LINK',
  SET_FILTERED_DEEP_LINKS_LIST: 'SET_FILTERED_DEEP_LINKS_LIST',
  SET_DEEP_LINKS_ITEMS: 'SET_DEEP_LINKS_ITEMS',
  RESET_DEEP_LINKS: 'RESET_DEEP_LINKS'
}

export const whiteLabelType = {
  SET_WHITELABEL: 'SET_WHITELABEL',
  RESET_WHITELABEL: 'RESET_WHITELABEL',
  SET_WHITELABEL_FETCH_LOADER: 'SET_WHITELABEL_FETCH_LOADER',
  SET_WHITELABEL_STORE_LOADER: 'SET_WHITELABEL_STORE_LOADER',
  SET_WHITELABEL_DOMAIN_LOADER: 'SET_WHITELABEL_DOMAIN_LOADER',
  SET_WHITELABEL_EMAIL_LOADER: 'SET_WHITELABEL_EMAIL_LOADER',
  SET_WHITELABEL_APP_SETTINGS: 'SET_WHITELABEL_APP_SETTINGS',
  RESET_WHITELABEL_VALIDATIONS: 'RESET_WHITELABEL_VALIDATIONS'
}

export const billingType = {
  SET_ADDON_CONSUMED_LIMIT_DETAIL: 'SET_ADDON_CONSUMED_LIMIT_DETAIL',
  SET_ADDON_BASED_SUBSCRIPTION_LIST: 'SET_ADDON_BASED_SUBSCRIPTION_LIST',
  SET_ACTIVE_ADDON_DETAIL: 'SET_ACTIVE_ADDON_DETAIL',
  SET_LIMIT_CONSUMED_ERROR: 'SET_LIMIT_CONSUMED_ERROR',
  SET_EDIT_MODIFER_Limits: 'SET_EDIT_MODIFER_Limits'
}
