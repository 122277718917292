import {mapGetters} from 'vuex'
import {pixelValidations} from '@/common/attributes'

let PixelMixin = {
  computed: {
    ...mapGetters(['getPixelList'])
  },
  methods: {
    showAddPixelModal (modal, call = '') {
      this.$store.dispatch('resetPixels')
      if (this.getSubscription.limits && this.getSubscription.limits.pixel &&
        this.getPixelList.length >= this.getSubscription.limits.pixel) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }

      modal.validations = JSON.parse(JSON.stringify(pixelValidations))

      if (call === 'campaign') {
        this.$store.commit('SET_CAMPAIGN_PIXEL_ADD_CHECK', true)
        this.$bvModal.show('addPixelModal')
      }

      // $('#addPixelModal').modal('show')
      this.$bvModal.show('addPixelModalV2')
    },

    getPixelSocialClass (type, classType) {
      if (classType == 1) {
        switch (type) {
          case 'twitter':
            return 'tw'
          case 'facebook':
            return 'fb'
          case 'custom':
            return 'cs'
          case 'quora':
            return 'quora'
          case 'pinterest':
            return 'pin'
          case 'linkedin':
            return 'lin'
          case 'tiktok':
            return 'tiktok'
          default:
            return 'cs'
        }
      } else {
        switch (type) {
          case 'twitter':
            return 'fab fa-twitter'
          case 'facebook':
            return 'fab fa-facebook'
          case 'custom':
            return 'fas fa-code'
          case 'google':
            return 'fab fa-google'
          case 'quora':
            return 'fab fa-quora'
          case 'pinterest':
            return 'fab fa-pinterest'
          case 'linkedin':
            return 'fab fa-linkedin'
          case 'tiktok':
            return 'fab fa-tiktok'
          default:
            return 'fas fa-code'
        }
      }
    }
  }
}

export {PixelMixin}
