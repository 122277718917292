<template>
  <div class="flex flex-col p-6 h-[100vh] bg-[#F2F3F8]">
    <div class="h-full grid lg:grid-cols-9 xl:grid-cols-12">
      <div
        :class="
          !getSiteDetails.isWhiteLabel
            ? 'lg:col-span-3  xl:col-span-3'
            : 'col-span-12'
        "
      >
        <div
          class="flex bg-white rounded-bl-lg rounded-br-lg h-full flex-column justify-start"
        >
          <div>
            <div class="py-8 bg-[#013D87] rounded-tl-lg rounded-tr-lg">
              <img :src="getSiteDetails.login_logo" class="mx-auto" />
            </div>
            <div class="pt-10 text-center px-10">
              <p class="font-poppins text-[#3C4549] text-xl font-semibold m-0">
                Forget Password
              </p>
              <p class="font-poppins pt-2 m-0 text-[#757A8A]">
                Enter your email address below and we’ll get you back on track.
              </p>
            </div>
            <div class="pt-8 px-10">

              <FloatingLabelInput
                class="mb-3"
                id="email"
                v-model="email"
                type="text"
                label="Email"
                :showIconLeft="true"
                iconName="mail.svg"
                :authPageCalled="true"
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="validations.email"
                    :message="messages.email"
                  ></InputFieldMessage>
                </template>
              </FloatingLabelInput>

              <div class="text-center pt-1.5">
                <Button
                  @click.prevent="resetPassword"
                  id="reset-button"
                  type="button"
                  class="w-[14rem] text-white h-[3rem] bg-blue-600 hover:bg-blue-700"
                >
                  <template v-slot:label>Reset Password</template>
                  <template v-if="loginLoader" v-slot:loader>
                    <Loader></Loader>
                  </template>
                </Button>
              </div>
            </div>
            <div class="px-10 pt-4 text-center font-poppins text-sm font-normal">

            <router-link
              :to="{ path: '/login' }"
              class="text-blue-700 leading-[21px]"
              >Take me back to login
            </router-link>

          </div>
          </div>


        </div>
      </div>
      <div
        v-show="!getSiteDetails.isWhiteLabel"
        class="hidden lg:block lg:col-span-6 xl:col-span-9"
      >
        <Carousel />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.checkbox_input_image {
  margin-left: 0.4rem;

  .remember_me {
    font-size: 13px;
  }
}

.checkbox_remember {
  label {
    color: #989eb5 !important;
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:before {
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:after {
    color: #989eb5 !important;
    font-size: 16px !important;
  }
}

@-moz-document url-prefix() {
  .authentication-component .authentication-component-inner .twitter__btn span,
  .authentication-component
    .authentication-component-inner
    .facebook__btn
    span {
    font-size: 13px !important;
    padding-left: 0px !important;
    padding-right: 15px !important;
  }
  .twitter__btn i,
  .facebook__btn i {
    min-width: 40px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {forgotPasswordURL} from '@/config/config.js'
import { APIURL } from "@/config/config";
import { API_URL_v3 } from "@/constants";
import http from "@/mixins/http-lib";
import FloatingLabelInput from "@/ui/ui-kit/v2/FloatingLabelInput.vue";
import Button from "@/ui/ui-kit/v2/Button.vue";
import Carousel from "@/ui/ui-kit/Carousel";
import InputFieldMessage from '@/ui/ui-kit/v2/InputFieldMessage.vue'

export default {
  name: "Forgot Password",
  components: {
    Carousel,
    FloatingLabelInput,
    Button,
    InputFieldMessage,
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  data: function () {
    return {
      email: "",
      validations:{
        email:false,
      },
      messages:{
        email:'Please Enter a valid email',
      },
      size: "16px",
      color: "#ffffff",
      loginLoader: false,
      whitelabel: null,
    };
  },
  async created () {
    await this.fetchWhitelabelAppSettings()
  },
  methods: {
    ...mapActions(['setUnauthorizedException', 'fetchWhitelabelAppSettings']),
    resetPassword () {
      this.validations.email = this.requiredCheck(this.email) || this.checkEmail(this.email);
     if(this.validations.email)
     return ;

      this.loginLoader = true
      this.$http.post(forgotPasswordURL, {email: this.email}).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.alertMessage(response.data.message, 'success')
              this.$router.push({name: 'login'})
            } else this.alertMessage(response.data.message, 'error')

          }
          this.loginLoader = false
        },
        response => {
          this.loginLoader = false
          this.alertMessage(response.data.message, 'error')
        }
      )
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
  watch: {
    email(newValue, oldValue) {
        const trimmedEmail = newValue.trim();
        if (!trimmedEmail) {
          this.messages.email= "Email is required.";
        }
        else if (!this.isValidEmail(trimmedEmail)) {
          this.messages.email = "Invalid email format.";
        } else {
          this.messages.email = null;
          this.validations.email = false;

        }
    },
  },

};
</script>
