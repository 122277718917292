import {validLength} from "@/common/attributes.js"

export default {
  methods: {
    validateRequired(value, fieldName) {
      if (!value) {
        return {
          isValid: false,
          message: `${fieldName} is required.`,
        };
      }
      return { isValid: true };
    },

    validateMinLength(value, fieldName) {
      if (value.length < validLength.minStringLength) {
        return {
          isValid: false,
          message: `${fieldName} Atleast ${validLength.minStringLength} Character long.`,
        };
      }
      return { isValid: true };
    },

    validateMaxLength(value, fieldName) {
      if (value.length > validLength.maxStringLength) {
        return {
          isValid: false,
          message: `${fieldName} Must Not Be Under ${validLength.maxStringLength} Character long.`,
        };
      }
      return { isValid: true };
    },

    validateNotSame(firstvalue,secondValue,firstField,secondField){
      if(firstvalue === secondValue){
        return {
          isValid: false, 
          message: `${firstField} Should Not Be Same as ${secondField}`,
        }
      }
      return {  isValid:true};

    },


    validateSame(firstvalue,secondValue,firstField,secondField){
      if(firstvalue !== secondValue){
        return {
          isValid: false, 
          message: `${firstField} Must Be Same as ${secondField}`,
        }
      }
      return {  isValid:true};

    },

    validateEmail(email, fieldName = "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        return {
          isValid: false,
          message: `Invalid ${fieldName} format.`,
        };
      }
      return { isValid: true };
    },

    validateNumeric(value, fieldName) {
      if (isNaN(value)) {
        return {
          isValid: false,
          message: `${fieldName} must be a numeric value.`,
        };
      }
      return { isValid: true };
    },
    validatePassword(password, fieldName = "password") {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
      const passwordRegexonlylength = /^.{8,}$/;
      if (!passwordRegexonlylength.test(password)) {
        // we are not using it right now
        return {
          isValid: false,
          message: `${fieldName} must be at least 8 characters long`,
        };
      }
      // if (!passwordRegex.test(password)) {   // we are not using it right now
      //   return {
      //     isValid: false,
      //     message:
      //       `${fieldName} must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one numeric digit.`,
      //   };
      // }
      return { isValid: true };
    },
      //this methods set the error message and validations to false
     setValidationToFaslse(objectKeys) {
      for (const key in objectKeys) {
        if (this.validations.hasOwnProperty(key)) {
          this.validations[key] = false;
        }
        if (this.messages.hasOwnProperty(key)) {
          this.messages[key] = "";
        }
      }
    },


    // this function will validate the value as first parameter, one of above rule as second parameter and what to change as third parameter in array
    validateField(...args) {
      try {
        let [ value, fieldName, functionName, fields ,...extraParams] = args;
        let [messageField, validationField] = fields;
        // console.log([ value, fieldName, functionName, fields ,...extraParams] );
        for (let i = 0; i < functionName.length; i++) {
          let validatoinFunction = functionName[i];
          // console.log(validatoinFunction,this[validatoinFunction].length);
          // if(this.validations[validationField]===true)    // if one validation is true then no need to check others
          // break;
          let validation = undefined;
          if(this[validatoinFunction].length>2){
            // this funciton accepts more than 2 arguments so we will use extraParams
            validation = this[validatoinFunction](...extraParams);
          }else{
            validation = this[validatoinFunction](value, fieldName);
          }
          // console.log(validation);
          if (!validation.isValid) {
            this.messages[messageField] = validation.message;
            this.validations[validationField] = true;
            break ; // we have found one validation error it's enough 
          } else {
            this.messages[messageField] = "";
            this.validations[validationField] = false;
            continue ;  // check for next function validaiton 
          }
      }
       
      } catch (e) {
        // console.log(e);
        // console.log("error from validateField "+e.message);
        let [ value, fieldName, functionName, fields ,...extraParams] = args;

        let [messageField, validationField] = fields;
        this.messages[messageField] = "Server Error";
        this.validations[validationField] = true;
      }
    },
  },
};
