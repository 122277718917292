import Vue from 'vue'
import Vuex from 'vuex'

import alert from './state/modules/alert'
import analytics from './state/modules/analytics'
import app from './state/modules/app'
import authentication from './state/modules/authentication'
import brands from './state/modules/brands'
import callToActions from './state/modules/call-to-actions'
import campaigns from './state/modules/campaigns'
import customDomains from './state/modules/custom-domains'
import feeds from './state/modules/feeds'
import getGuidedTour from './state/modules/guided-tour'
import httpRequest from './state/modules/http-request'
import links from './state/modules/links'
import notifications from './state/modules/notifications'
import reportsStore from './state/modules/reportsStore'
import rssFeeds from './state/modules/rss-feeds'
import subscriptions from './state/modules/subscriptions'
import tags from './state/modules/tags'
import toaster from './state/modules/toaster'
import pixels from './state/modules/settings/pixels'
import billing from './state/modules/settings/billing'
import utm from './state/modules/settings/utm'
import users from './state/modules/users'
import widgets from './state/modules/settings/widgets'
import bioModule from './state/modules/bio-link'
import clients from '@/state/modules/clients'
import blacklistIp from '@/state/modules/settings/blacklist-ip'
import deeplinks from '@/state/modules/settings/deeplinks/deeplinks'
import whitelabel from '@/state/modules/settings/whitelabel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert: alert,
    analytics: analytics,
    app: app,
    authentication: authentication,
    bioModule: bioModule,
    brands: brands,
    callToActions: callToActions,
    campaigns: campaigns,
    customDomains: customDomains,
    feeds: feeds,
    getGuidedTour: getGuidedTour,
    httpRequest: httpRequest,
    links: links,
    // mutationTypes: mutationTypes,
    notifications: notifications,
    reportsStore: reportsStore,
    rssFeeds: rssFeeds,
    subscriptions: subscriptions,
    tags: tags,
    toaster: toaster,
    pixels: pixels,
    utm: utm,
    users: users,
    widgets: widgets,
    billing: billing,
    clients: clients,
    blacklistIp: blacklistIp,
    deeplinks: deeplinks,
    whitelabel
  }
})
