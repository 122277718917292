import http from '@/mixins/http-lib.js'
import {whiteLabelType} from '@/state/modules/mutation-types'
import {replugDomainsList, whiteLabelValidations} from "@/common/attributes";
import {
  deleteWhitelabelDomainSSL,
  getWhiteLabel, getWhitelabelDetailsFromDomain,
  saveWhiteLabel,
  verifyDomainRecords,
  verifyWhitelabelEmail
} from "@/config/config";

const getDefaultWhiteLabel = () => {
  return {
    agency_name: '',
    header_logo: '',
    favicon: '',
    privacy_policy: '',
    terms_of_service: '',
    login_logo: '',
    app_loader: '',
    custom_domain_id: '',
    white_label_domain: '',
    whiteLabel_domain_connected: false,
    is_email_connected: false,
    from_email: '',
    email_sender_name: '',
    smtp_host: '',
    smtp_port: '',
    smtp_username: '',
    smtp_password: '',
    smtp_encryption: '',
    whitelabel_setup: false,
    user_id: ''
  }
}

const resetWhitelabelValidate = () => {
  return {
    agency_name: false,
    header_logo: false,
    favicon: false,
    privacy_policy: false,
    terms_of_service: false,
    login_logo: false,
    app_loader: false,
    custom_domain_id: false,
    white_label_domain: false,
    whiteLabel_domain_connected: false,
    is_email_connected: false,
    email_validate: false,
    email_sender_name: false
  }
}

const getDefaultWhiteLabelAppSettings = () => {
  return {
    agency_name: '',
    header_logo: '',
    favicon: '',
    privacy_policy: '',
    terms_of_service: '',
    login_logo: '',
    app_loader: '',
    custom_domain_id: '',
    white_label_domain: '',
    whiteLabel_domain_connected: false,
    is_email_connected: false,
    custom_domain: {}
  }
}

export default {
  state: {
    white_label: getDefaultWhiteLabel(),
    white_label_app_settings: getDefaultWhiteLabelAppSettings(),
    white_label_validations: JSON.parse(JSON.stringify({...whiteLabelValidations})),
    white_label_section_validate: resetWhitelabelValidate(),
    white_label_loaders: {
      app_fetch: false,
      fetch: false,
      store: false,
      domain: false,
      email: false
    }
  },
  getters: {
    getWhiteLabel: state => state.white_label,
    getWhiteLabelAppSettings: state => state.white_label_app_settings,
    getWhiteLabelValidations: state => state.white_label_validations,
    getWhiteLabelSectionValidate: state => state.white_label_section_validate,
    getWhiteLabelLoaders: state => state.white_label_loaders
  },
  actions: {
    setWhiteLabel ({commit}, value) {
      commit(whiteLabelType.SET_WHITELABEL, value)
    },
    resetWhitelabelValidations ({commit, dispatch}, value) {
      commit(whiteLabelType.RESET_WHITELABEL, value)
    },
    async storeWhiteLabel ({getters, commit, dispatch}) {
      commit(whiteLabelType.SET_WHITELABEL_STORE_LOADER, true)
      await http.post(saveWhiteLabel, getters.getWhiteLabel).then(res => {
          commit(whiteLabelType.SET_WHITELABEL_STORE_LOADER, false)
          dispatch('toastNotification', {
            message: res.data.message,
            type: res.data.status ? 'success' : 'error'
          })
          if (res.data.status) {
            getters.getWhiteLabel = res.data.data
            return
          }
          return res
        }
      ).catch(() => {
      })
    },
    async fetchWhiteLabel ({commit}) {
      commit(whiteLabelType.SET_WHITELABEL_FETCH_LOADER, true)
      await http.post(getWhiteLabel, {}).then(res => {
          commit(whiteLabelType.SET_WHITELABEL_FETCH_LOADER, false)
          if (res.data.status) {
            commit(whiteLabelType.SET_WHITELABEL, res.data.data)
            commit(whiteLabelType.RESET_WHITELABEL_VALIDATIONS)
            return
          }
          dispatch('toastNotification', {
            message: res.data.message, type: 'error'
          })
          return res
        }
      ).catch(() => {
      })
    },
    /**
     * verifying whitelabel domain validations
     * @param commit
     * @param getters
     * @param dispatch
     * @returns {Promise<void>}
     */
    async verifyDomainRecord ({commit, getters, dispatch}) {
      commit(whiteLabelType.SET_WHITELABEL_DOMAIN_LOADER, true)

      await http.post(verifyDomainRecords, {domain: getters.getWhiteLabel.white_label_domain}).then(res => {

        dispatch('toastNotification', {
          message: res.data.status ? 'Your domain has been successfully connected. It may take a few minutes for the SSL certificate to be created for custom domain.' : res.data.message,
          type: res.data.status ? 'success' : 'error'
        })

        getters.getWhiteLabel.whiteLabel_domain_connected = res.data.status
        getters.getWhiteLabelValidations.whiteLabel_domain_connected = !res.data.status
        commit(whiteLabelType.SET_WHITELABEL_DOMAIN_LOADER, false)

      }).catch((err) => {
        dispatch('toastNotification', {
          message: err,
          type: 'error'
        })
      })

    },
    /**
     * delete the ssl from whitelabel domain
     * @param value
     * @returns {Promise<void>}
     */
    async deleteWhitelabelDomainSSL ({}, value) {
      await http.post(deleteWhitelabelDomainSSL, {user_id: value})
    },
    /**
     * verifing the whilabel email
     * @param commit
     * @param getters
     * @param dispatch
     * @returns {Promise<void>}
     */
    async verifyWhitelabelEmail ({commit, getters, dispatch}) {
      commit(whiteLabelType.SET_WHITELABEL_EMAIL_LOADER, true)
      await http.post(verifyWhitelabelEmail, getters.getWhiteLabel).then(res => {
        dispatch('toastNotification', {
          message: res.data.message,
          type: res.data.status ? 'success' : 'error'
        })
        getters.getWhiteLabel.is_email_connected = res.data.status
        getters.getWhiteLabelValidations.is_email_connected = !res.data.status
        commit(whiteLabelType.SET_WHITELABEL_EMAIL_LOADER, false)
      }).catch((err) => {
        console.log(err)
      })
    },
    /**
     *  this whitelabel method is responsible for checking whitelabel if domain is not white label then we simply redirect to current environment
     */
    async fetchWhitelabelAppSettings ({commit, getters, dispatch}) {
      const domain = window.location.hostname
      getters.getWhiteLabelLoaders.app_fetch = true

      if (!replugDomainsList.includes(domain)) {
        http.post(getWhitelabelDetailsFromDomain, {domain: domain}).then(
          res => {
            if (res.data.status) {
              commit(whiteLabelType.SET_WHITELABEL_APP_SETTINGS, res.data.data)
              getters.getWhiteLabelLoaders.app_fetch = false
              return
            }
            getters.getWhiteLabelLoaders.app_fetch = false

            if(process.env.VUE_APP_ENVIRONMENT !== 'qa') {
              window.location.href = 'https://app.replug.io'
            }
          }
        )
      }
      getters.getWhiteLabelLoaders.app_fetch = false
      return true
    },
    /**
     * resetting the whitelabel all validations
     * @param commit
     */
    resetWhitelabelAllValidations ({commit}) {
      commit(whiteLabelType.RESET_WHITELABEL_VALIDATIONS)
    }
  },
  mutations: {
    [whiteLabelType.SET_WHITELABEL] (state, value) {
      state.white_label = value ? value : JSON.parse(JSON.stringify(getDefaultWhiteLabel()))
    },
    [whiteLabelType.RESET_WHITELABEL] (state, value) {
      state.white_label_validations = JSON.parse(JSON.stringify({...whiteLabelValidations}))
      state.white_label_section_validate = resetWhitelabelValidate()
    },
    [whiteLabelType.SET_WHITELABEL_FETCH_LOADER] (state, value) {
      state.white_label_loaders.fetch = value
    },
    [whiteLabelType.SET_WHITELABEL_STORE_LOADER] (state, value) {
      state.white_label_loaders.store = value
    },
    [whiteLabelType.SET_WHITELABEL_DOMAIN_LOADER] (state, value) {
      state.white_label_loaders.domain = value
    },
    [whiteLabelType.SET_WHITELABEL_EMAIL_LOADER] (state, value) {
      state.white_label_loaders.email = value
    },
    [whiteLabelType.SET_WHITELABEL_APP_SETTINGS] (state, value) {
      state.white_label_app_settings = value
    },
    [whiteLabelType.RESET_WHITELABEL_VALIDATIONS] (state, value) {
      state.white_label_validations = JSON.parse(JSON.stringify({...whiteLabelValidations}))
      state.white_label_section_validate = resetWhitelabelValidate()
    }
  }
}
