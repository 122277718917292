import { mapActions, mapGetters } from 'vuex'
import { widgetTypes } from '@/state/modules/mutation-types'
import { widgetValidations } from '@/common/attributes'
import { baseURL } from '@/config/api'

let widgetsMixin = {

  computed: {

    ...mapGetters(['getCustomWidgetList'])
  },
  mounted () {
    /* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
    }) */
  },
  methods: {
    ...mapActions([]),

    showCodeModal () {
      this.$store.commit(widgetTypes.RESET_WIDGET)
      this.$refs.custom_widget_modal.validations = JSON.parse(JSON.stringify(widgetValidations))
      this.$bvModal.show('customCode')
    },

    editCustomWidget (code) {
      this.$store.commit(widgetTypes.SET_CUSTOM_WIDGET_ADD, code)
      if(code.trigger_type == 'time'){
        this.$refs.custom_widget_modal.CheckBoxOne = false;
        this.$refs.custom_widget_modal.CheckBoxTwo = true;
      }
      else{
        this.$refs.custom_widget_modal.CheckBoxOne = true;
        this.$refs.custom_widget_modal.CheckBoxTwo = false;
      }
      this.$bvModal.show('customCode')
    },

    getCustomCodeData (codeId, attr) {
      let data = this.getCustomWidgetList.find(item => {
        return item._id === codeId
      })
      if (data) {
        return data[attr]
      }
      return ''
    },

    getCustomCodeUrl (scriptId, type) {
      return baseURL + 'get_script/' + type + '/' + scriptId

      // return 'http://0.0.0.0:5000/get_custom_js/'+script_id;
    },

    getCustomCodeClass (scriptId) {
      let position = this.getCustomCodeData(scriptId, 'position')
      let html = this.getCustomCodeData(scriptId, 'html')
      let positionClass = 'middle_position'
      switch (position) {
        case 'bottom_left':
        case 'bottom_right':
        case 'top_left':
        case 'top_right':
          positionClass = `${position}_position`
          break
      }

      if (!html || html.trim().length === 0) {
        positionClass = ' box_hidden'
      }
      return positionClass
    }
  }
}

export { widgetsMixin }
