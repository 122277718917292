import io from 'socket.io-client'
import { baseIntegrationURL, baseURL } from '@/config/config'

// export const baseURL = 'https://staging.replug.io/api/v3/'
export const shortenDomainURL = 'http://localhost:5000/'
export var validationSocket = () => {
  return io('http://localhost:3000/validation', {
    // query: {token: VueCookie.get(TOKEN_VALUE, {domain: COOKIE_DOMAIN})}
  })
}
export var defaultSocket = () => {
  return io('http://localhost:3000/', {
    // query: {token: VueCookie.get(TOKEN_VALUE, {domain: COOKIE_DOMAIN})}
  })
}

// profile
export const fetchProfileURL = baseURL + 'auth/user/profile'
export const updateNewFeatureAnnouncementsURL = baseURL + 'auth/user/update_new_feature_announcements'
export const getYoutubeChannel = baseURL + 'auth/user/get_youtube_channel'
// brands

export const storeBrandURL = baseURL + 'brands/store'
export const deleteBrandURL = baseURL + 'brands/delete'
export const archiveBrandURL = baseURL + 'brands/archive'
export const showBrandURL = baseURL + 'brands/show'
export const getBrandByIdOrDefault = baseURL + 'brands/get_brand_by_id_or_default'
export const setDefaultWorkspace = baseURL + 'brands/set_default_workspace'

// campaigns

export const fetchCampaignsLinksByIdURL = baseURL + 'campaigns/links'
export const showCampaignsURL = baseURL + 'campaigns/show'
export const getCampaignByBrandID = baseURL + 'campaigns/get_campaign_by_brand_id'
export const deleteCampaignURL = baseURL + 'campaigns/delete'
export const archiveCampaignURL = baseURL + 'campaigns/archive'
export const storeCampaignURL = baseURL + 'campaigns/store'
export const editCampaignNameURL = baseURL + 'campaigns/edit_name'
export const fetchDripTagsURL = baseIntegrationURL + 'drip/fetchDripTags'
export const fetchConvertfoxTagsURL = baseIntegrationURL + 'convertfox/fetchTags'
export const fetchMauticTagsURL = baseIntegrationURL + 'mautic/getTags'

// links

export const fetchLinksURL = baseURL + 'links/show'
export const deleteLinkURL = baseURL + 'links/delete'
export const archiveLinkURL = baseURL + 'links/archive'
export const changeLinkStatusURL = baseURL + 'links/change_status'
export const storeLinkURL = baseURL + 'links/store'
export const updateSlugURL = baseURL + 'links/update_slug'
export const bulkStoreLinkURL = baseURL + 'links/bulk'
export const bulkCSVLinkURL = baseURL + 'links/bulk_csv'
export const bulkCSVProcessManually = baseURL + 'links/process_manually'
export const isBulkCSVSlugExist = baseURL + 'links/isSlugExist'
export const exportCSVLinksURL = baseURL + 'links/export_csv'
export const searchLinksURL = baseURL + 'links/search'
export const countLinks = baseURL + 'links/count'
export const bulkLinksActionURL = baseURL + 'links/bulk_action'
export const fetchLinkMetaURL = baseURL + 'links/fetch_links_meta'
export const fetchCustomizeLinkPreview = baseURL + 'links/fetch_customize_link_preview'
export const fetchSelectedTrafficFilterURL = baseURL + 'links/fetch_traffic_routing_filter'


// tags
export const storeTagURL = baseURL + 'tags/store'
export const deleteTagURL = baseURL + 'tags/delete'
export const showTagURL = baseURL + 'tags/show'
export const addTagToLinkURL = baseURL + 'tags/add_tag_to_link'
export const removeTagFromLinkURL = baseURL + 'tags/remove_tag_from_link'
export const mergeTagURL = baseURL + 'tags/merge'

// utms

export const storeUTMURL = baseURL + 'utm/store'
export const showUTMURL = baseURL + 'utm/show'
export const showAllUTMURL = baseURL + 'utm/showAll'
export const removeUTMURL = baseURL + 'utm/remove'

// qr code

export const createQRCodeURL = baseURL + 'links/qr_code'
export const createBioQRCodeURL = baseURL + 'bio/qr_code'
export const renderQRCodeURL = baseURL + 'links/render_qr_code'

// domains
const domainsPrefix = baseURL + 'domains/'
export const storeDomainURL = domainsPrefix + 'store'
export const showDomainURL = domainsPrefix + 'show'
export const deleteDomainURL = domainsPrefix + 'delete'
export const checkDomainConnectivityURL = domainsPrefix + 'connectivity'
export const checkDNSRecordsURL = domainsPrefix + 'dns_records'
export const setDomainDefaultURL = domainsPrefix + 'default_domain'
export const setDomainUseHttpsURL = domainsPrefix + 'use_https'
export const fetchDomainLinked = domainsPrefix + 'is_domain_linked'
export const fetchDNSConfig = domainsPrefix + 'fetch_dns_config'
export const fetchDefaultBioDomain = domainsPrefix + 'defaultDomain'

// pixelsfetchRSSList
const pixelsPrefix = baseURL + 'pixels/'
export const storePixelURL = pixelsPrefix + 'store'
export const showPixelsURL = pixelsPrefix + 'show'
export const deletePixelURL = pixelsPrefix + 'delete'
export const showPixelsAllURL = pixelsPrefix + 'showAll'

// pixels
export const storeCustomWidgetURL = baseURL + 'widgets/store'
export const showCustomWidgetURL = baseURL + 'widgets/show'
export const deleteCustomWidgetURL = baseURL + 'widgets/delete'
export const previewCustomWidgetURL = baseURL + 'widgets/preview'

// notifications

export const showNotificationsURL = baseURL + 'notifications/show'
export const markReadNotificationsURL = baseURL + 'notifications/markRead'

// feeds

export const showRSSURL = baseURL + 'rss/show'
export const changeRSSStatusURL = baseURL + 'rss/change_status'
export const recrawlRSSURL = baseURL + 'rss/recrawl'
export const deleteRSSURL = baseURL + 'rss/delete'
export const storeRSSURL = baseURL + 'rss/store'
export const previewRSSURL = baseURL + 'rss/preview'
export const getRssFeedBioLinks = baseURL + 'fetch_rss_feed'

// reports

export const showReportsURL = baseURL + 'reports/show'
export const storeReportsURL = baseURL + 'reports/store'
export const deleteReportsURL = baseURL + 'reports/delete'
export const archiveReportsURL = baseURL + 'reports/archive'
export const showEmailReportsURL = baseURL + 'reports/email_reports_by_report_id'
export const dispatchManualReportsURL = baseURL + 'reports/dispatch_manual_report'
// user dashboard statistics

export const showStatisticsURL = baseURL + 'statistics/overview'
export const storeTriggerURL = baseURL + 'triggers/store'

// analytics
export const detailViewStatisticsURL = baseURL + 'statistics/detail_view'
export const listViewStatisticsURL = baseURL + 'statistics/list_view'
export const listViewLinksStatisticsURL = baseURL + 'statistics/list_view_links'
export const bioDetailViewStatisticsURL = baseURL + 'statistics/bio_detail_view'
export const realtimeStatisticsURL = baseURL + 'statistics/realtime'
export const bioRealtimeStatisticsURL = baseURL + 'statistics/bio_realtime'
export const fetchTotalStatistics = baseURL + 'statistics/fetch_total_clicks'

export const fetchAnalyticsByBrand = baseURL + 'analytics/brands'
export const fetchAnalyticsByCampaigns = baseURL + 'analytics/campaigns'
export const fetchAnalyticsByLinks = baseURL + 'analytics/links'
export const fetchAnalyticsByUserId = baseURL + 'analytics/user_id'

// team

export const inviteMemberURL = baseURL + 'team/store'
export const getMemberURL = baseURL + 'team/show'
export const removeTeamMemberURL = baseURL + 'team/delete'
export const resendInvitationURL = baseURL + 'team/resend'

// bio

export const storeBioURL = baseURL + 'bio/store'
export const showBioURL = baseURL + 'bio/show'
export const deleteBioURL = baseURL + 'bio/delete'
export const bioShortenURLUpdate = baseURL + 'bio/update_slug'
export const storeBioContentSectionURL = baseURL + 'bio/content/store'
export const deleteBioContentSectionURL = baseURL + 'bio/content/delete'
export const updateBioContentURL = baseURL + 'bio/content/update_sequence'
export const fetchBioLinksAnalytics = baseURL + 'analytics/bio_links'
export const searchBioURL = baseURL + 'bio/search'
export const archiveBioURL = baseURL + 'bio/archive'
export const countBioLinks = baseURL + 'bio/count'
// billing

export const getLifetimeBillingPlansURL = baseURL + 'billing/paddle/pricing_plans'
export const getOverridePriceURL = baseURL + 'billing/paddle/overridePrice'
export const getNewLimitsURL = baseURL + 'billing/paddle/limits'
export const getCouponsUsedURL = baseURL + 'billing/paddle/coupons'
export const fetchBillingDetails = baseURL + 'billing/fetch_billing_details'
export const fetchAddonSubscriptionURL = baseURL + 'billing/addons_transactions'
export const fetchPaymentHistoryURL = baseURL + 'billing/past_transactions'
export const pauseSubscriptionURL = baseURL + 'billing/pause_subscription'
export const resumeSubscriptionURL = baseURL + 'billing/resume_subscription'
export const fetchSubscriptionDetailsURL = baseURL + 'billing/fetch_by_subscription_id'
export const deleteSubscriptionURL = baseURL + 'billing/delete_subscription'
export const cancelSubscriptionRequestURL = baseURL + 'billing/cancel_subscription_request'
export const purchaseAddonModifier = baseURL + 'billing/purchase_addon_modifier'
export const deleteAddonModifier = baseURL + 'billing/delete_addon_modifier'
export const fetchComputedUserLimits = baseURL + 'billing/fetch_computed_user_limits'

// feeds from contentstudio

export const getFeedsURL = baseURL + 'https://api.contentstudio.io/feedsfeeder/suggestion/query'

// clients

export const fetchClients = baseURL + 'whitelabel/fetch_clients'
export const setClient = baseURL + 'whitelabel/store_client'
export const removeClient = baseURL + 'whitelabel/delete_client'

// blacklist ips

export const fetchBlacklistIpURL = baseURL + 'blacklist_ip/fetch_by_user_id'
export const storeBlacklistIpURL = baseURL + 'blacklist_ip/store'
export const deleteBlacklistIpURL = baseURL + 'blacklist_ip/delete'
export const fetchIp = baseURL + 'blacklist_ip/fetchIp'

// deep links
export const showDeepLinksSettingURL = baseURL + 'deepLinks/show'
export const showDeepLinksBrands = baseURL + 'deepLinks/showDeepLinksBrands'
