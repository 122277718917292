<template>
  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner w-50 m-auto">
        <div class="header_content mt-5 mb-5 text-center clear">
          <div class="logo_block flex justify-center">
            <a class="active">
              <img class="w-48" :src="getSiteDetails.header_logo" alt="replug">
            </a>
          </div>
        </div>
        <div class="white_box">
          <div class="box_inner">
            <div class="box_head !bg-[#F4F6FA] d-flex align-items-center justify-content-center">
              <div class="left">
                <h2 class="text">Update Your Email Address</h2>
              </div>


            </div>
            <div class="box_content !bg-white p-5">

              <FloatingLabelInput
                class="mb-3"
                id="email"
                v-model="email"
                type="text"
                label="Email"
                @keyup.enter="updateEmail()"
                :showIconLeft="true"
                iconName="mail.svg"
                :authPageCalled="true"
                :errorType="validations.email || validations.validEmail ? 'danger' : ''"
              >
                <template v-slot:error_message>
                  <InputFieldMessage v-if="validations.email" :message="messages.email"></InputFieldMessage>
                  <InputFieldMessage v-else-if="validations.validEmail" :message="messages.validEmail"></InputFieldMessage>
                </template>
              </FloatingLabelInput>


              <div class="flex justify-center items-center mt-4">
                <Button
                  id="login-button"
                  type="button"
                  class="text-white bg-[#2560D7] hover:bg-blue-700 mr-2"
                  buttonClass="btn-lg"
                  @click.prevent="updateEmail()"
                  :disabled="loader"
                >
                  <template v-slot:label>Update Email</template>
                  <template v-if="loader" v-slot:loader>
                    <Loader></Loader>
                  </template>
                </Button>
                <Button
                  id="login-button"
                  type="button"
                  class="border border-gray-500 hover:bg-[#F2F3F8]"
                  buttonClass="btn-lg"
                  @click="$router.push('/logout')"
                >
                  <template v-slot:label>Log out from your account!</template>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import LoginLeft from './LoginLeft.vue'
import LoginFooter from './LoginFooter.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import {mapActions, mapGetters} from 'vuex'
import InputFieldMessage from '@/ui/ui-kit/v2/InputFieldMessage.vue'

export default ({
  components: {
    LoginLeft,
    ClipLoader,
    LoginFooter,
    FloatingLabelInput : () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
  },
  data () {
    return {
      email: null,
      size: '20px',
      color: '#ffffff',
      loader: false,
      messages: {
        email: 'Please enter email address.',
        validEmail: 'Please enter valid email address'
      },
      validations: {
        email: false,
        validEmail: false
      }
    }
  },
  async created () {
    this.$store.dispatch('fetchProfile')
    await this.fetchWhitelabelAppSettings()
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    updateEmail () {
      const data = {
        email: this.email,
        verification_token: this.getProfile.verification_token
      }
      if (!this.validations.email && !this.validations.validEmail) {
        this.loader = true
        this.$store.dispatch('updateEmail', data).then(
          res => {
            this.loader = false
            if (res.data.status) {
              this.alertMessage(res.data.message, 'success')
            } else {
              this.alertMessage(res.data.message, 'error')
            }
          }, err => {
            console.warn(err)
          })
      }
    }
  },
  watch: {
    'email' (value) {
      if (value && value.trim().length > 0) {
        this.validations.validEmail = this.checkEmail(this.email)
        this.validations.email = false
      } else {
        this.validations.validEmail = false
        this.validations.email = true
      }
    },
  }
})
</script>
