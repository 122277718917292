import http from '@/mixins/http-lib'
import {clientTypes} from '@/state/modules/mutation-types'
import {fetchClients, setClient, removeClient} from '../../config/api'


const defaultAddClient = () => {
  return {
    _id: '',
    first_name: '',
    last_name: '',
    email: '',
    profile_image: ''
  }
}

export default {
  state: {
    client: defaultAddClient(),
    loaders: {
      loader: true
    }
  },
  getters: {
    getClients: state => state.client,
    getClientLoader: state => state.loaders
  },
  mutations: {
    [clientTypes.SET_CLIENT](state, value) {
      state.client = value.data
      state.loaders.loader = !value.status
    }
  },
  actions: {
    async fetchClients({dispatch, commit, getters}) {
      http.post(fetchClients, {brand_id: getters.getWorkspace._id}).then(res => {
        commit(clientTypes.SET_CLIENT, res.data)
      }).catch(() => null)
    }
  }
}
