<template>
  <div class="flex flex-col p-6 h-[100vh] bg-[#F2F3F8]">
    <div class="h-full grid lg:grid-cols-9 xl:grid-cols-12">
      <div :class="!getSiteDetails.isWhiteLabel ? 'lg:col-span-3  xl:col-span-3' : 'col-span-12'">
        <div class="flex bg-white rounded-bl-lg rounded-br-lg h-full flex-column justify-between">
          <div>
            <div class="py-8 bg-[#013D87] rounded-tl-lg rounded-tr-lg">
              <img :src="getSiteDetails.login_logo" class="mx-auto" />
            </div>
            <div class="pt-10 text-center px-10">
              <p class="font-poppins text-[#3C4549] text-xl font-semibold m-0">
                Sign in
              </p>
              <p class="font-poppins pt-2 m-0 text-[#757A8A]">
                Enter your email address and password to access replug app.
              </p>
            </div>
            <div class="pt-8 px-10">
              <form @keyup.enter="loginAuthenticate">
                <FloatingLabelInput
                  class="mb-3"
                  id="email"
                  v-model="username"
                  type="text"
                  label="Email"
                  :showIconLeft="true"
                  iconName="mail.svg"
                  :authPageCalled="true"
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.username"
                      :message="messages.username"
                    ></InputFieldMessage>
                  </template>
                </FloatingLabelInput>

                <FloatingLabelInput
                  id="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  iconName="lock.svg"
                  :showIconLeft="true"
                  :showIconRight="true"
                  :authPageCalled="true"
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.password"
                      :message="messages.password"
                    ></InputFieldMessage>
                  </template>
                  <template v-slot:right_icon>
                    <svg
                      v-if="showPassword"
                      @click="showPasswordFunc()"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-[21.55px] h-[17.50px] cursor-pointer"
                      viewBox="0 0 24 20"
                      fill="none"
                    >
                      <path
                        d="M1 10.0007C1 10.0007 5 3.33398 12 3.33398C19 3.33398 23 10.0007 23 10.0007C23 10.0007 19 16.6673 12 16.6673C5 16.6673 1 10.0007 1 10.0007Z"
                        stroke="#D2D5DF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 12.5C13.6569 12.5 15 11.3807 15 10C15 8.61929 13.6569 7.5 12 7.5C10.3431 7.5 9 8.61929 9 10C9 11.3807 10.3431 12.5 12 12.5Z"
                        stroke="#D2D5DF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else
                      @click="showPasswordFunc()"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-[21.55px] h-[17.50px] cursor-pointer"
                      viewBox="0 0 22 18"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.639917 0.531506C0.898674 0.20806 1.37064 0.155619 1.69409 0.414376L21.6941 16.4144C22.0175 16.6731 22.07 17.1451 21.8112 17.4685C21.5525 17.792 21.0805 17.8444 20.757 17.5857L16.9315 14.5252C15.3954 15.3877 13.5029 16 11.2255 16C5.16782 16 1.83336 11.6675 0.68656 9.81945C0.371749 9.31213 0.372204 8.68706 0.687052 8.17976C1.26948 7.24132 2.41559 5.66301 4.19543 4.33639L0.757047 1.58568C0.433601 1.32692 0.38116 0.854953 0.639917 0.531506ZM5.41135 5.30912C4.83893 5.705 4.3321 6.13648 3.8874 6.57184C3.08761 7.35486 2.50105 8.14047 2.11641 8.72793C2.00813 8.8933 2.00813 9.1067 2.11641 9.27207C2.50105 9.85953 3.08761 10.6451 3.8874 11.4282C5.4818 12.9891 7.87503 14.5 11.2255 14.5C12.9466 14.5 14.4151 14.1013 15.6525 13.502L13.3628 11.6703C12.7397 12.0415 11.9924 12.2504 11.2255 12.2504C9.28832 12.2504 7.47554 10.9175 7.47554 9.00039C7.47554 8.38627 7.66155 7.83211 7.97808 7.3625L5.41135 5.30912ZM9.16492 8.31198L12.0602 10.6282C11.8092 10.7062 11.5296 10.7504 11.2255 10.7504C9.84906 10.7504 8.97554 9.84466 8.97554 9.00039C8.97554 8.7689 9.04121 8.53278 9.16492 8.31198ZM18.5637 11.4282C18.3624 11.6252 18.3714 11.9562 18.5913 12.1322L18.8561 12.344C19.1437 12.5741 19.5586 12.5598 19.8165 12.2967C20.7316 11.3633 21.3736 10.4491 21.7635 9.82117C22.0783 9.3139 22.0793 8.68787 21.7645 8.18055C20.6177 6.33247 17.2833 2 11.2255 2C10.1381 2 9.13837 2.13962 8.22332 2.37942C7.6364 2.53324 7.51958 3.27477 7.99337 3.6538C8.19498 3.81509 8.46214 3.86559 8.71262 3.80299C9.48225 3.61065 10.3188 3.5 11.2255 3.5C14.5761 3.5 16.9693 5.01089 18.5637 6.57184C19.3635 7.35486 19.95 8.14047 20.3347 8.72793C20.443 8.8933 20.443 9.1067 20.3347 9.27207C19.95 9.85953 19.3635 10.6451 18.5637 11.4282Z"
                        fill="#D2D5DF"
                      />
                    </svg>
                  </template>
                </FloatingLabelInput>

                <div class="pt-6 flex justify-between items-center leading-3">
                  <Checkbox
                    id="login-checkbox"
                    v-model="rememberMe"
                    label="Remember Me"
                    type="checkbox"
                    labelClass="text-[#757A8A]"
                    :isDisabled="false"
                  ></Checkbox>
                  <div class="flex justify-center items-center">
                    <router-link
                      :to="{ path: '/forgot_password' }"
                      class="font-poppins text-center text-blue-700 text-sm font-normal leading-[21px]"
                      >Forgot Password ?</router-link
                    >
                  </div>
                </div>
                <div class="text-center pt-14">
                  <Button
                    @click.prevent="loginAuthenticate"
                    id="login-button"
                    type="button"
                    class="w-[14rem] text-white h-[3rem] bg-blue-600 hover:bg-blue-700"
                  >
                    <template v-slot:label>Sign in</template>
                    <template v-if="loginLoader" v-slot:loader>
                      <Loader></Loader>
                    </template>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div
            v-if="!getSiteDetails.isWhiteLabel"
            class="flex-grow px-10 flex-col items-center gap-4 inline-flex text-center font-poppins text-sm font-normal"
            :class="{
              'justify-center': socializationSignup || authorizeMessage,
              'justify-end': !socializationSignup && !authorizeMessage,
            }"
            >
            <div
              v-if="authorizeMessage"
              class="p-4 mb-4 text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              {{ authorizeMessage }}
            </div>
            <div
              v-if="socializationSignup"
              class="flex justify-center items-center flex-col gap-3"
            >
            <p class="text-[#3C4549] leading-tight">
              Or sign in using social media
            </p>
            <div class="justify-start items-center gap-3.5 inline-flex">
              <a :href="getLink('twitter')"
                ><img
                  class="w-[40px] h-[40px]"
                  src="/assets/img/v2/twitter.svg"
              /></a>
              <a :href="getLink('facebook')"
                ><img
                  class="w-[40px] h-[40px]"
                  src="/assets/img/v2/facebook.svg"
              /></a>
            </div>
            </div>
            <p class="text-[#3C4549] text-gray-500 leading-tight">
              Don’t have account
              <router-link
                :to="{ path: '/register' }"
                class="text-blue-700 leading-[21px]"
              >
                Sign up.</router-link
              >
            </p>
          </div>
          <div class="px-10 pb-4 text-center font-poppins text-sm font-normal">
            <span class="text-gray-500 leading-tight"
              >By signing in, You agree to our
            </span>
            <a
              href="https://replug.io/terms"
              class="text-blue-700 leading-[21px]"
              >terms of service
          </a>
            <span class="text-gray-500 leading-tight">and </span>
            <a
              href="https://replug.io/privacy"
              class="text-blue-700 leading-[21px]"
            >
              privacy policy.</a>
          </div>
        </div>
      </div>
      <div
        v-if="!getSiteDetails.isWhiteLabel"
        class="hidden lg:block lg:col-span-6 xl:col-span-9"
      >
        <Carousel />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.checkbox_input_image {
  margin-left: 0.4rem;

  .remember_me {
    font-size: 13px;
  }
}

.checkbox_remember {
  label {
    color: #989eb5 !important;
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:before {
    font-size: 16px !important;
  }

  input[type="checkbox"] + label:after {
    color: #989eb5 !important;
    font-size: 16px !important;
  }
}

@-moz-document url-prefix() {
  .authentication-component .authentication-component-inner .twitter__btn span,
  .authentication-component
    .authentication-component-inner
    .facebook__btn
    span {
    font-size: 13px !important;
    padding-left: 0px !important;
    padding-right: 15px !important;
  }
  .twitter__btn i,
  .facebook__btn i {
    min-width: 40px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIURL } from "@/config/config";
import { API_URL_v3 } from "@/constants";
import http from "@/mixins/http-lib";
import FloatingLabelInput from "@/ui/ui-kit/v2/FloatingLabelInput.vue";
import Checkbox from "@/ui/ui-kit/v2/Checkbox.vue";
import Button from "@/ui/ui-kit/v2/Button.vue";
import Carousel from "@/ui/ui-kit/Carousel";

import validaitonsWithMessageMixin from "@/mixins/validations/validations-with-message.js";
import InputFieldMessage from "@/ui/ui-kit/v2/InputFieldMessage.vue";

export default {
  name: "Login",
  mixins: [validaitonsWithMessageMixin],
  components: {
    Carousel,
    FloatingLabelInput,
    Checkbox,
    Button,
    InputFieldMessage,
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  data: function () {
    return {
      username: "",
      password: "",
      socializationSignup:false,
      loginFormStatus: false,
      authorizeMessage: null,
      size: "16px",
      color: "#ffffff",
      loginLoader: false,
      isDisableSignIn: false,
      rememberMe: false,
      whitelabel: null,
      showPassword: false,
      labelFocused: false,
      messages: {
        username: "Please enter your email",
        password: "Please enter your password",
      },
      validations: {
        username: false,
        password: false,
      },
    };
  },
  async created() {
    await this.fetchWhitelabelAppSettings();
  },
  computed: {
    ...mapGetters(["getWhitelabel"]),
  },
  methods: {
    ...mapActions([
      "authenticate",
      "fetchBrandList",
      "fetchWhitelabelAppSettings",
    ]),
    animateLabel(focus) {
      this.labelFocused = focus;
    },
    getLink(type) {
      if (this.$route.query.ref) {
        return APIURL + "social/refer/" + type + "/" + this.$route.query.ref;
      } else if (
        this.$route.query.oauth &&
        this.$route.query.oauth === "authorize" &&
        this.$route.query.client_id &&
        this.$route.query.redirect_uri &&
        this.$route.query.response_type &&
        this.$route.query.scope
      ) {
        return (
          APIURL +
          "social/" +
          type +
          "/?client_id=" +
          this.$route.query.client_id +
          "&redirect_uri=" +
          this.$route.query.redirect_uri +
          "&response_type=" +
          this.$route.query.response_type +
          "&scope=" +
          this.$route.query.scope
        );
      } else {
        return APIURL + "social/" + type;
      }
    },
    validateData() {
      /* the first parameter is the value , the second is name of the field , the third is list of funcion names as array and fourth is validation and message object key */
      this.validateField(
        this.username,
        "Email",
        ["validateRequired", "validateEmail"],
        ["username", "username"]
      );
      this.validateField(
        this.password,
        "Password",
        ["validateRequired", "validatePassword"],
        ["password", "password"]
      );
      // if any of the validation is true then return false so that backend request can be prevented
      return (this.validations.username || this.validations.password)
        ? false
        : true;
    },
    loginAuthenticate() {
      console.log(this.validateData());
      if (!this.validateData()) return false;
      console.log("loginAuthenticate");
      this.isDisableSignIn = true;
      this.loginLoader = true;
      const postData = {
        email: this.username,
        password: this.password,
        remember_me: this.rememberMe,
      };
      if (this.$route.query.source) {
        postData["source"] = this.$route.query.source;
      }

      if (this.$route.query.redirect) {
        postData["redirect"] = this.$route.query.redirect;
      }

      http.post(`${API_URL_v3}auth/authenticate`, postData).then(
        (response) => {
          /**
           * In case of SSO(Single Sign-On, we will be redirecting user back to where they came from
           * Current implementation caters Frill.co redirection
           */
          if (response.data.redirect) {
            window.location.href = response.data.redirect;
          }
          this.setSiteCookies(response.data.data.token);
          setTimeout(() => {
            this.loginLoader = false;
            this.isDisableSignIn = false;

            if (
              this.$route.query.client_id &&
              this.$route.query.redirect_uri &&
              this.$route.query.response_type &&
              this.$route.query.scope
            ) {
              this.$router.push({
                name: "oauth",
                query: {
                  response_type: this.$route.query.response_type,
                  redirect_uri: this.$route.query.redirect_uri,
                  client_id: this.$route.query.client_id,
                  scope: this.$route.query.scope,
                },
              });
              return;
            }
            if (this.$route.query.redirect_url) {
              const routeNameList = this.$route.query.redirect_url.split("/");
              this.$router.push({
                name: routeNameList[routeNameList.length - 1],
              });
              return;
            }
            this.$router.push({ name: "dashboard" });
          }, 1000);
        },
        (error) => {
          this.isDisableSignIn = false;
          this.loginLoader = false;
          this.$store.dispatch("toastNotification", {
            message: error.response.data.error.split("_").join(" "),
            type: "error",
          });
        }
      );
    },
    showPasswordFunc() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
