import {brandTypes, campaignTypes, linkTypes, userTypes} from './mutation-types'
import http from '@/mixins/http-lib'
import {
  archiveBrandURL,
  deleteBrandURL,
  fetchAnalyticsByBrand,
  getBrandByIdOrDefault, setDefaultWorkspace,
  showBrandURL,
  storeBrandURL
} from '@/config/api'
import {swalDefaultValues} from '@/mixins/attributes'
import {uploadImageURL} from '../../config/urls/helper/file'
import Vue from 'vue'

const defaultAddBrand = () => {
  return {
    _id: null,
    name: null,
    url: null,
    avatar: null,
    resize_url: null
  }
}
export default {
  state: {
    brand: {
      list: [],
      brandComponentList: [],
      page: 1,
      limit: 10,
      sort: 'created_desc',
      total_pages: 0,
      total_items: 0,
      date: {
        label: 'All Time',
        value: ''
      },
      archive: false,
      add: defaultAddBrand(),
      loaders: {
        retrieve: false,
        image_upload: false,
        store: false
      },
      search: '',
      // keys to improve brands page speed
      isListFetched: false,
      isAllListFetched: false,
      periodic: true,
      previousPage: false
    },
    brands: {

      items: [],
      fetchStatus: false,
      search: '',
      page: 1,
      limit: 10,
      sort: 'created_desc',
      totalPages: 0,
      totalItems: 0,

      allItems: [],
      fetchAllStatus: false,
      date: {
        label: 'All Time',
        value: ''
      },

      archiveStatus: false

    },
    saveBrand: {
      id: '',
      name: '',
      url: '',
      avatar: '',
      resize_url: ''
    },
    workspace: {}
  },
  getters: {
    getBrandList: state => state.brand.list,
    getComponentBrandList: state => state.brand.brandComponentList,
    getBrandLoaders: state => state.brand.loaders,
    getBrandAdd: state => state.brand.add,
    getBrand: state => state.brand,
    getBrands: state => state.brands,
    getWorkspace: state => state.workspace
  },
  actions: {
    async fetchBrandList ({commit, getters, dispatch}, {page = 1, isFetchAll = false}) {
      // fetching all brands no need to show loader
      if (!isFetchAll) {
        commit(brandTypes.SET_BRAND_RETRIEVE_LOADER, true)
      }

      const payload = getters.getBrand
      return await http.post(showBrandURL, {
        search: payload.search,
        page: page,
        sort: payload.sort,
        date: payload.date,
        archive: payload.archive,
        isFetchAll: isFetchAll,
        limit: payload.limit
      }).then(res => {
        if (res.data.status && isFetchAll) {
          return res.data.data.all
        }
        if (res.data.status) {
          commit(brandTypes.SET_BRAND_LIST, res.data.data.paginate)
          commit(brandTypes.SET_BRAND_RETRIEVE_LOADER, false)
        }
        return res.data.data.all
      }).catch(() => null)
    },
    fetchBrandAnalytics ({commit, getters}) {
      getters.getComponentBrandList.forEach((item) => {
        commit(brandTypes.SET_DEFAULT_BRAND_ANALYTICS, item._id)
        http.post(fetchAnalyticsByBrand, {
          brand_id: item._id
        }).then(res => {
          if (res.data.status) {
            const obj = {
              _id: item._id,
              data: res.data.data
            }
            commit(brandTypes.SET_BRAND_ANALYTICS, obj)
          }
        })
      })
    },
    async archiveBrand ({commit, getters, dispatch}) {
      const payload = getters.getModalData
      const profileArchiveStatus = getters.getArchiveStatus.status
      if (profileArchiveStatus) payload.hideArchiveMessage = true
      const res = await http.post(archiveBrandURL, payload).then(
        res => {
          if (res.data.status) {
            dispatch('toastNotification', {message: res.data.message})
            getters.getBrandList.splice(payload.index, 1)
            getters.getComponentBrandList.splice(payload.index, 1)
            commit(userTypes.SET_MODAL_STATUS, 'success')
            commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, [])
            commit(linkTypes.SET_CTA_FOR_LINK, [])

            if (profileArchiveStatus) commit(userTypes.SET_PROFILE_ARCHIVE_MESSAGE_STATUS)
          } else {
            commit(userTypes.SET_MODAL_STATUS, 'fail')
            dispatch('toastNotification', {message: res.data.message, type: 'error'})
          }
          // hide the loader

          return res
        }
      ).catch(
        () => {
          commit(userTypes.SET_MODAL_STATUS, 'fail')
          return false
        }
      )
      setTimeout(() => {
        commit(userTypes.SET_CLOSE_CONFIRMATION_MODAL, 'archiveBrand')
      }, 1000)
    },

    async removeBrand ({commit, getters, dispatch}, {brand, index, userId}) {
      return http.post(deleteBrandURL, {
              brand: brand,
              user_id: userId
            }).then(res => {
              if (res.data.status) {
                getters.getBrandList.splice(index, 1)
                getters.getComponentBrandList.splice(index, 1)
                commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, [])
                commit(linkTypes.SET_CTA_FOR_LINK, [])
                dispatch('toastNotification', {message: 'Brand deleted successfully.'})
              } else {
                dispatch('toastNotification', {message: res.data.message, type: 'error'})
              }
              return res
            })
    },
    async uploadImageForBrand ({commit, getters, dispatch}, event) {
      commit(brandTypes.SET_BRAND_IMAGE_UPLOAD_LOADER, true)
      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()
      formData.append('input_file', files[0])
      await http.post(uploadImageURL, formData).then(res => {
          if (res.data.status) {
            commit(brandTypes.SET_BRAND_AVATAR, res.data.url)
            commit(brandTypes.SET_BRAND_AVATAR_RESIZE_URL, res.data.resize_url)
          }
          return res
        }
      ).catch(error => {
        if (error) {
          dispatch('toastNotification', { message: 'The input file must be a file of type: jpeg, png, gif.', type: 'error' })
        } else {
          alert('An error occurred. Please try again.')
        }
      })
      event.target.value = ''
      commit(brandTypes.SET_BRAND_IMAGE_UPLOAD_LOADER, false)
    },

    async storeBrand ({commit, getters, dispatch}) {
      const payload = getters.getBrandAdd
      commit(brandTypes.SET_BRAND_STORE_LOADER, true)
      let result = false
      await http.post(storeBrandURL, payload).then(res => {
        if (res.data.status) {
          dispatch('toastNotification', {message: 'Brand saved successfully!'})
          if (!getters.getProfile.onboarding) getters.getProfile.onboarding_data.brand = true

          // update case

          if (payload._id && payload._id.length > 3) {
            const brand = getters.getBrand
            const brandListIndex = brand.list.findIndex(item => item._id === res.data.data._id)
            brand.list.splice(brandListIndex, 1, res.data.data)

            const brandAllListIndex = brand.brandComponentList.findIndex(item => item._id === res.data.data._id)
            brand.brandComponentList.splice(brandAllListIndex, 1, res.data.data)
          } else {
            // new brand added to the list

            const brand = res.data.data
            commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, brand._id)

            brand.conversions = 0
            brand.ctr = 0
            brand.clicks = 0
            brand.unique_visits = 0
            commit(brandTypes.ADD_BRAND_TO_LIST, brand)
          }
          result = true
          // $('#modal-create-brand').modal('hide')
        } else {
          const message = (res.data.message) ? res.data.message : 'Unable to save brand, please try again'
          dispatch('toastNotification', {message: message, type: 'error'})
          result = false
        }
      }).catch(() => {
        result = false
      })
      commit(brandTypes.SET_BRAND_STORE_LOADER, false)
      return result
    },
    async fetchWorkspaceById ({commit}, brand_id = '') {
      return http.post(getBrandByIdOrDefault, {
        brand_id: brand_id,
      }).then(res => {
         commit(brandTypes.SET_WORKSPACE, res.data.data ? res.data.data : {})
         localStorage.setItem('workspace', JSON.stringify(res.data.data))
        return res
      })
      return res
    },
    async setDefaultWorkspace ({commit, dispatch}, brand) {
      return http.post(setDefaultWorkspace, {
        brand_id: brand._id,
        is_default: !brand.is_default
      }).then(res => {
        if(res.data.status) {
          brand.is_default = !brand.is_default
          commit(brandTypes.SET_WORKSPACE, brand)
          localStorage.setItem('workspace', JSON.stringify(brand))

          dispatch('toastNotification', {message: res.data.message, type: 'success'})
          return res
        }
        dispatch('toastNotification', {message: res.data.message, type: 'error'})
        return res
      })
      return res
    },
    async setComponentBrandList ({commit}, value) {
      await commit(brandTypes.SET_COMPONENT_BRAND_LIST, value)
    },
    setBrands ({commit}, brands) {
      commit('setBrands', brands)
    },

    setBrandsFetchStatus ({commit}, status) {
      commit('setBrandsFetchStatus', status)
    },

    setCampaignDate ({commit}, status) {
      commit('setCampaignDate', status)
    },

    setBrandsLimit ({commit}, limit) {
      commit('setBrandsLimit', limit)
    },

    setBrandsPage ({commit}, page) {
      commit('setBrandsPage', page)
    },

    setBrandsTotalPages ({commit}, totalPages) {
      commit('setBrandsTotalPages', totalPages)
    },

    setBrandsTotalItems ({commit}, totalItems) {
      commit('setBrandsTotalItems', totalItems)
    },

    setAllBrands ({commit}, brands) {
      commit('setAllBrands', brands)
    },

    setAllBrandsFetchStatus ({commit}, status) {
      commit('setAllBrandsFetchStatus', status)
    },

    setBrandDate ({commit}, date) {
      commit('setBrandDate', date)
    },

    setBrandArchiveStatus ({commit}, status) {
      commit('setBrandArchiveStatus', status)
    }

  },
  mutations: {
    [brandTypes.SET_BRAND_LIST] (state, value) {
      state.brand.page = value.current_page
      state.brand.limit = value.per_page
      state.brand.total_items = value.total
      state.brand.total_pages = value.last_page

      // to maintain all records in both dropdown and pagination
      state.brand.list = [...state.brand.list, ...value.data].map(val => ({...val, fetchStats: true}))
    },
    [brandTypes.SET_BRAND_LISTS] (state, value) {
      state.brand.list = value
    },
    [brandTypes.SET_DEFAULT_BRAND_ANALYTICS] (state, brandId) {
      const brand = state.brand.brandComponentList.find(brand => brand._id === brandId)
      if (!brand) {
        return
      }
      brand.fetchStats = true
      brand.clicks = 0
      brand.unique_visits = 0
      brand.conversions = 0
      brand.ctr = 0
    },
    [brandTypes.SET_BRAND_ANALYTICS] (state, data) {
      state.brand.list.filter(brand => {
        if (brand._id == data._id) {
          brand.fetchStats = false
          brand.clicks = data.data.aggregations.total_visitors.value
          brand.unique_visits = data.data.aggregations.unique_clicks.value
          brand.conversions = data.data.aggregations.clicked_conversions.doc_count
          if (brand.clicks <= 0) {
            brand.ctr = 0
          } else {
            const digits = 2
            let value = (brand.conversions / brand.clicks) * 100
            value = value * Math.pow(10, digits)
            value = Math.round(value)
            brand.ctr = value / Math.pow(10, digits)
          }
        }
      })
    },
    [brandTypes.ADD_BRAND_TO_LIST] (state, value) {
      state.brand.list.unshift(value)
      state.brand.brandComponentList.unshift(value)
    },
    [brandTypes.REMOVE_BRAND_FROM_LIST] (state, value) {
      const index = state.brand.list.findIndex(item => {
        return item._id === value
      })
      state.brand.list.splice(index, 1)
    },
    [brandTypes.SET_BRAND_SORT_VALUE] (state, value) {
      Vue.set(state.brand, 'sort', value)
      // state.brand.sort = value
    },
    [brandTypes.SET_BRAND_ARCHIVE_STATUS] (state, value) {
      state.brand.archive = value
    },
    [brandTypes.SET_BRAND_DATE_VALUE] (state, value) {
      state.brand.date = value
    },
    [brandTypes.SET_BRAND_AVATAR] (state, value) {
      state.brand.add.avatar = value
    },
    [brandTypes.SET_BRAND_AVATAR_RESIZE_URL] (state, value) {
      state.brand.add.resize_url = value
    },
    [brandTypes.SET_BRAND_IMAGE_UPLOAD_LOADER] (state, value) {
      state.brand.loaders.image_upload = value
    },
    [brandTypes.RESET_BRAND_ADD] (state) {
      state.brand.add = defaultAddBrand()
    },
    [brandTypes.SET_BRAND_STORE_LOADER] (state, value) {
      state.brand.loaders.store = value
    },
    [brandTypes.SET_BRAND_RETRIEVE_LOADER] (state, value) {
      state.brand.loaders.retrieve = value
    },
    [brandTypes.SET_BRAND_ADD_VALUE] (state, value) {
      state.brand.add = JSON.parse(JSON.stringify(value))
    },
    [brandTypes.SET_BRAND_ADD_NAME_VALUE] (state, value) {
      state.brand.add.name = value
    },
    [brandTypes.ADD_CAMPAIGN_TO_BRANDS_LIST] (state, value) {
      const brand = state.brand.brandComponentList.find(item => item._id === value.brand_id)
      if (brand) brand.campaign.push(value)
    },
    [brandTypes.SET_IS_ALL_LIST_FETCHED] (state, value) {
      state.brand.isAllListFetched = value
    },
    [brandTypes.SET_COMPONENT_BRAND_LIST] (state, value) {
      state.brand.brandComponentList = [...value]
    },
    [brandTypes.SET_WORKSPACE] (state, value) {
      state.workspace = value
    },
    setBrands (state, brands) {
      state.brands.items = brands
    },

    setBrandsLimit (state, limit) {
      state.brands.limit = limit
    },

    setBrandsPage (state, page) {
      state.brands.page = page
    },

    setBrandsTotalPages (state, totalPages) {
      state.brands.totalPages = totalPages
    },

    setBrandsTotalItems (state, totalItems) {
      state.brands.totalItems = totalItems
    },

    setAllBrands (state, brands) {
      state.brands.allItems = brands
    },

    setAllBrandsFetchStatus (state, status) {
      state.brands.fetchAllStatus = status
    },

    setBrandDate (state, date) {
      if (date == null) {
        state.brands.date = {
          label: 'All Time',
          value: ''
        }
      } else {
        state.brands.date = date
      }
    },

    setBrandArchiveStatus (state, status) {
      state.brands.archiveStatus = status
    }
  }
}
