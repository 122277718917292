<template>
  <div class="flex flex-col p-6 h-[100vh] bg-[#F2F3F8]">
    <VueRecaptcha
      :sitekey="getClientSideRecaptchaKey()"
      ref="recaptcha"
      size="invisible"
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired"
      @error="onCaptchaError"
    >
    </VueRecaptcha>
    <div class="h-full grid lg:grid-cols-9 xl:grid-cols-12">
      <div
        :class="
          !getSiteDetails.isWhiteLabel
            ? 'lg:col-span-3  xl:col-span-3'
            : 'col-span-12'
        "
      >
        <div
          class="flex bg-white rounded-bl-lg rounded-br-lg h-full flex-column justify-between"
        >
          <div>
            <div class="py-8 bg-[#013D87] rounded-tl-lg rounded-tr-lg">
              <img :src="getSiteDetails.login_logo" class="mx-auto" />
            </div>
            <div class="pt-6 text-center px-10">
              <p class="font-poppins text-[#3C4549] text-xl font-semibold m-0">
                Create Account
              </p>
              <p
                class="hidden 2xl:block font-poppins pt-2 m-0 text-[#757A8A] font-normal"
              >
                Enter details to signup for replug app.
              </p>
            </div>
            <div class="pt-6 px-10">
              <form @keyup.enter="validateRegister">
                <FloatingLabelInput
                  class="mb-3"
                  id="first_name"
                  v-model="register.first_name"
                  type="text"
                  label="First Name"
                  :showIconLeft="true"
                  iconName="user.svg"
                  :authPageCalled="true"
                  :errorType="
                    validations.first_name || validations.first_name_valid
                      ? 'danger'
                      : ''
                  "
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.first_name"
                      :message="messages.first_name"
                    ></InputFieldMessage>
                    <InputFieldMessage
                      v-else-if="validations.first_name_valid"
                      :message="messages.first_name_valid"
                    ></InputFieldMessage>
                  </template>
                </FloatingLabelInput>

                <FloatingLabelInput
                  class="mb-3"
                  id="last_name"
                  v-model="register.last_name"
                  type="text"
                  label="Last Name"
                  :showIconLeft="true"
                  iconName="user.svg"
                  :authPageCalled="true"
                  :errorType="
                    validations.last_name || validations.last_name_valid
                      ? 'danger'
                      : ''
                  "
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.last_name"
                      :message="messages.last_name"
                    ></InputFieldMessage>
                    <InputFieldMessage
                      v-else-if="validations.last_name_valid"
                      :message="messages.last_name_valid"
                    ></InputFieldMessage>
                  </template>
                </FloatingLabelInput>

                <FloatingLabelInput
                  class="mb-3"
                  id="email"
                  v-model="register.email"
                  type="text"
                  label="Email"
                  :showIconLeft="true"
                  iconName="mail.svg"
                  :authPageCalled="true"
                  :errorType="validations.email ? 'danger' : ''"
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.email"
                      :message="messages.email"
                    ></InputFieldMessage>
                  </template>
                </FloatingLabelInput>

                <FloatingLabelInput
                  id="password"
                  v-model="register.password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  iconName="lock.svg"
                  :showIconLeft="true"
                  :showIconRight="true"
                  :authPageCalled="true"
                  :errorType="password_state"
                >
                  <template v-slot:error_message>
                    <InputFieldMessage
                      v-if="validations.password"
                      message="Please enter password"
                    ></InputFieldMessage>
                    <InputFieldMessage
                      v-else-if="password_strength_message"
                      :type="password_state"
                      :message="password_strength_message"
                    ></InputFieldMessage>
                  </template>
                  <template v-slot:right_icon>
                    <svg
                      v-if="showPassword"
                      @click="showPasswordFunc()"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      class="cursor-pointer"
                      viewBox="0 0 24 20"
                      fill="none"
                    >
                      <path
                        d="M1 10.0007C1 10.0007 5 3.33398 12 3.33398C19 3.33398 23 10.0007 23 10.0007C23 10.0007 19 16.6673 12 16.6673C5 16.6673 1 10.0007 1 10.0007Z"
                        stroke="#D2D5DF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 12.5C13.6569 12.5 15 11.3807 15 10C15 8.61929 13.6569 7.5 12 7.5C10.3431 7.5 9 8.61929 9 10C9 11.3807 10.3431 12.5 12 12.5Z"
                        stroke="#D2D5DF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      v-else
                      @click="showPasswordFunc()"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      class="cursor-pointer"
                      viewBox="0 0 22 18"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.639917 0.531506C0.898674 0.20806 1.37064 0.155619 1.69409 0.414376L21.6941 16.4144C22.0175 16.6731 22.07 17.1451 21.8112 17.4685C21.5525 17.792 21.0805 17.8444 20.757 17.5857L16.9315 14.5252C15.3954 15.3877 13.5029 16 11.2255 16C5.16782 16 1.83336 11.6675 0.68656 9.81945C0.371749 9.31213 0.372204 8.68706 0.687052 8.17976C1.26948 7.24132 2.41559 5.66301 4.19543 4.33639L0.757047 1.58568C0.433601 1.32692 0.38116 0.854953 0.639917 0.531506ZM5.41135 5.30912C4.83893 5.705 4.3321 6.13648 3.8874 6.57184C3.08761 7.35486 2.50105 8.14047 2.11641 8.72793C2.00813 8.8933 2.00813 9.1067 2.11641 9.27207C2.50105 9.85953 3.08761 10.6451 3.8874 11.4282C5.4818 12.9891 7.87503 14.5 11.2255 14.5C12.9466 14.5 14.4151 14.1013 15.6525 13.502L13.3628 11.6703C12.7397 12.0415 11.9924 12.2504 11.2255 12.2504C9.28832 12.2504 7.47554 10.9175 7.47554 9.00039C7.47554 8.38627 7.66155 7.83211 7.97808 7.3625L5.41135 5.30912ZM9.16492 8.31198L12.0602 10.6282C11.8092 10.7062 11.5296 10.7504 11.2255 10.7504C9.84906 10.7504 8.97554 9.84466 8.97554 9.00039C8.97554 8.7689 9.04121 8.53278 9.16492 8.31198ZM18.5637 11.4282C18.3624 11.6252 18.3714 11.9562 18.5913 12.1322L18.8561 12.344C19.1437 12.5741 19.5586 12.5598 19.8165 12.2967C20.7316 11.3633 21.3736 10.4491 21.7635 9.82117C22.0783 9.3139 22.0793 8.68787 21.7645 8.18055C20.6177 6.33247 17.2833 2 11.2255 2C10.1381 2 9.13837 2.13962 8.22332 2.37942C7.6364 2.53324 7.51958 3.27477 7.99337 3.6538C8.19498 3.81509 8.46214 3.86559 8.71262 3.80299C9.48225 3.61065 10.3188 3.5 11.2255 3.5C14.5761 3.5 16.9693 5.01089 18.5637 6.57184C19.3635 7.35486 19.95 8.14047 20.3347 8.72793C20.443 8.8933 20.443 9.1067 20.3347 9.27207C19.95 9.85953 19.3635 10.6451 18.5637 11.4282Z"
                        fill="#D2D5DF"
                      />
                    </svg>
                  </template>
                </FloatingLabelInput>

                <div class="text-center pt-8">
                  <Button
                    @click.prevent="validateRegister"
                    id="login-button"
                    type="button"
                    class="w-[14rem] text-white h-[3rem] bg-blue-600 hover:bg-blue-700"
                  >
                    <template v-if="!loaders.register" v-slot:label
                      >Sign up</template
                    >
                    <template v-if="loaders.register" v-slot:loader>
                      <Loader></Loader>
                    </template>
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div
            v-if="!getSiteDetails.isWhiteLabel"
            class="flex-grow px-10 flex-col items-center gap-4 inline-flex text-center font-poppins text-sm font-normal"
            :class="{
              'justify-center': socializationSignup || authorizeMessage,
              'justify-end': !socializationSignup && !authorizeMessage,
            }"
          >
            <div
              v-if="authorizeMessage"
              class="p-4 mb-4 text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              {{ authorizeMessage }}
            </div>
            <div
              v-if="socializationSignup"
              class="flex justify-center items-center flex-col gap-3"
            >
              <p class="text-[#3C4549] leading-tight">
                Or sign up using social media
              </p>
              <div class="justify-start items-center gap-3.5 inline-flex">
                <a :href="getLink('twitter')"
                  ><img
                    class="w-[40px] h-[40px]"
                    src="/assets/img/v2/twitter.svg"
                /></a>
                <a :href="getLink('facebook')"
                  ><img
                    class="w-[40px] h-[40px]"
                    src="/assets/img/v2/facebook.svg"
                /></a>
              </div>
            </div>
            <p class="text-[#3C4549] leading-tight">
              Already have account
              <router-link
                :to="{ path: '/login' }"
                class="text-blue-700 leading-[21px]"
              >
                Sign in.</router-link
              >
            </p>
          </div>
          <div class="px-10 pb-4 text-center font-poppins text-sm font-normal">
            <span class="text-gray-500 leading-tight"
              >By signing in, You agree to our
            </span>
            <a
              href="https://replug.io/terms"
              class="text-blue-700 leading-[21px]"
              >terms of service
          </a>
            <span class="text-gray-500 leading-tight">and </span>
            <a
            href="https://replug.io/privacy"
              class="text-blue-700 leading-[21px]"
            >
              privacy policy.</a
            >
          </div>
        </div>
      </div>
      <div
        v-if="!getSiteDetails.isWhiteLabel"
        class="hidden lg:block lg:col-span-6 xl:col-span-9"
      >
        <Carousel />
      </div>
    </div>
  </div>
</template>

<style lang="less">
.danger {
  color: #ea7070 !important;
}

.warning {
  color: #efa340 !important;
}

.green {
  color: #399017 !important;
}
</style>

<script>
import {baseURL, cookieDomain} from '../../constants'
import {registerFormURL} from '@/config/config'
import {authenticationTypes} from '@/state/modules/mutation-types'
import vueCookie from 'vue-cookie'
import {APIURL} from '@/config/config'
import {mapActions, mapGetters} from 'vuex'
import {trackSignUpEvent} from "../../common/methods";
import {VueRecaptcha} from 'vue-recaptcha'
import FloatingLabelInput from '@/ui/ui-kit/v2/FloatingLabelInput.vue'
import Button from '@/ui/ui-kit/v2/Button.vue'
import Carousel from "@/ui/ui-kit/Carousel"
import InputFieldMessage from '@/ui/ui-kit/v2/InputFieldMessage.vue'
export default ({
  'name': 'signup',
  components: {
    Carousel,
    FloatingLabelInput,
    Button,
    InputFieldMessage,
    VueRecaptcha,
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
 async created () {
    this.register.email = this.$route.query.email ? this.$route.query.email : this.register.email;
    if (this.$route.query.error_message) {
      this.authorizeMessage = this.$route.query.error_message
    }
    if (this.$route.query.error === 'login') {
      this.authorizeMessage = 'Unable to connect through social media, please use a below form'
    }
   await this.fetchWhitelabelAppSettings()
  },
  computed: {
    ...mapGetters(['getWhitelabel'])
  },
  data () {
    return {
      size: '16px',
      color: '#ffffff',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      socializationSignup:false,
      authorizeMessage: false,
      password_strength_message: '',
      password_state: '',
      register: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        recaptcha_token: ''
      },
      validations: {
        first_name: false,
        first_name_valid: false,
        last_name: false,
        last_name_valid: false,
        email: false,
        password: false
      },
      messages: {
        first_name: 'Please enter first name.',
        first_name_valid: 'Name must contain only alphabets.',
        last_name: 'Please enter last name.',
        last_name_valid: 'Name must contain only alphabets.',
        email: 'Please enter email address.',
        password: 'Please enter password.'
      },
      loaders: {
        register: false
      },
      showPassword: false
    }
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    /**
     * getting the google recaptcha client side key
     * @returns {string}
     */
    getClientSideRecaptchaKey () {
        return process.env.VUE_APP_GOOGLE_V2_RECAPTCHA_CLIENT_KEY
    },
    onCaptchaVerified: function (recaptchaToken) {
      if (recaptchaToken && recaptchaToken.length) {
        this.register.recaptcha_token = recaptchaToken
        this.loaders.register = true
        this.registerAccount()
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    onCaptchaError: function (error) {
      console.warn('onCaptchaError =>', error)
    },
    getLink (type) {
      if (this.$route.query.ref) {
        return APIURL + 'social/refer/' + type + '/' + this.$route.query.ref
      } else if (this.$route.query.oauth && this.$route.query.oauth == 'authorize' &&
        this.$route.query.client_id && this.$route.query.redirect_uri &&
        this.$route.query.response_type && this.$route.query.scope) {
        return APIURL + 'social/' + type +
          '/?client_id=' + this.$route.query.client_id +
          '&redirect_uri=' + this.$route.query.redirect_uri +
          '&response_type=' + this.$route.query.response_type +
          '&scope=' + this.$route.query.scope
      } else {
        return APIURL + 'social/' + type
      }
    },
    /**
     * validating the register form and executing the recaptcha
     */
    validateRegister () {
      this.validations.first_name = this.requiredCheck(this.register.first_name)
      this.validations.last_name = this.requiredCheck(this.register.last_name)
      this.validations.email = this.requiredCheck(this.register.email)
      this.validations.password = this.requiredCheck(this.register.password)

      console.log(this.validations)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.$refs.recaptcha.execute();
      }
    },
    registerAccount () {
      let ref = null
      try {
        this.register.ref = this.$route.query.ref
      } catch (m) {
        console.log(m)
      }
      this.register.subscription_name = (this.$route.query.subscription_name) ? this.$route.query.subscription_name : 'trial-personal'
      this.$http.post(registerFormURL, this.register).then(
        async res => {

          this.onCaptchaExpired()

          if (res.data.tokenError && res.data.tokenError === true) {
            this.alertMessage(res.data.message, 'error')
            return
          }

          if (res.data.status) {
            await trackSignUpEvent(res.data.user_id, this.register.email, this.register.first_name, this.register.last_name, this.register.subscription_name)

            this.alertMessage(res.data.message, 'success')
            window.localStorage.setItem('rplg_logged_jwt_token', res.data.token)
            vueCookie.set('rplg_logged_jwt_token', res.data.token, {expires: '1Y', domain: cookieDomain})
            this.$store.commit(authenticationTypes.SET_JWT_TOKEN, res.data.token)
            this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
            let stateObject = this
            setTimeout(function () {
              stateObject.$router.push({'name': 'dashboard'})
            }, 2000)
            gtag('event', 'conversion', {'send_to': 'AW-474110986/WEQ5CMHUjfMBEIq4ieIB'});
            // Event snippet for Sign-up conversion page
            gtag('event', 'conversion', {'send_to': 'AW-10788423991/Zl9ECMrI__oCELeSqZgo'});

            return
          }

          this.alertMessage(res.data.message, 'error')
          this.loaders.register = false

        }, err => {
          if (err.status === 422) {
            this.alertMessage(err.data.message, 'error')
          }
          this.loaders.register = false
        })
    },
    showPasswordFunc () {
      this.showPassword = !this.showPassword
    }

  },
  watch: {
    'register.first_name' (value) {
      if (value && value.trim().length > 0) {
        this.validations.first_name = false
      }

      const regex = new RegExp(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/)
      var result = regex.test(value)
      if (value && !result) {
        this.validations.first_name_valid = true
      } else {
        this.validations.first_name_valid = false
      }
    },
    'register.last_name' (value) {
      if (value && value.trim().length > 0) {
        this.validations.last_name = false
      }

      const regex = new RegExp(/^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/)
      var result = regex.test(value)
      if (value && !result) {
        this.validations.last_name_valid = true
      } else {
        this.validations.last_name_valid = false
      }
    },
    'register.email' (value) {
      if (value && value.trim().length > 0) {
        this.validations.email = false
      }
      else{
        this.validations.email = true;
        this.messages.email = "Email is required";
      }
      if (value){
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        this.validations.email = false;
      }else{
        this.validations.email = true;
        this.messages.email = "Invalid Email Format";
      }
    }
    },
    'register.password' (value) {
      if (value && value.trim().length > 0) {
        this.validations.password = false
      }

      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length <= 8) {
          this.password_strength_message = 'Password must be eight characters or more'
          this.password_state = 'danger'
          return
        }
        if (value.length >= 6 && value.length <= 12) {
          this.password_strength_message = 'Password could be more secure'
          this.password_state = 'warning'
          return
        }
        if (value.length >= 13 && value.length <= 17) {
          this.password_strength_message = 'Password is okay'
          this.password_state = 'green'
          return
        }
        if (value.length >= 17) {
          this.password_strength_message = 'Password is strong'
          this.password_state = 'green'
          return
        }
        this.password_state = ''
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    }
  }

})
</script>
