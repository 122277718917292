<template>
    <div :class="customClass" class="flex items-center gap-x-2.5 ">
     <input
        class="relative float-left h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] bg-[#ECEEF5] outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0  before:content-[''] checked:bg-[#0165E1] checked:before:opacity-[0.16] checked:after:absolute checked:after:mt-4 checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] focus:before:scale-100 focus:before:opacity-[0.12] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:after:mt-[0.2rem] checked:focus:after:ml-[0.40rem] checked:focus:after:h-[0.6rem] checked:focus:after:w-[0.3rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0   checked:focus:after:bg-transparent  checked:before:scale-100 checked:after:mt-[0.2rem] checked:after:ml-[0.40rem] checked:after:h-[0.6rem] checked:after:w-[0.3rem] checked:after:rotate-45 checked:after:rounded-none checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0   checked:after:bg-transparent"
        type="checkbox"
        v-model="model"
        @change="$emit('change')"
        @click="$emit('click')"
        :value="inputValue"
        :disabled="isDisabled"
        :id="id" />
      <div class="flex items-center space-x-1" v-if="image">
        <img :class="imageClass" :src="image" />
        <label :class="labelClass" class="inline-block text-[0.875rem] font-poppins mb-0 hover:cursor-pointer" :for="id">{{ label }}</label>
      </div>
      <label v-else :class="labelClass" class="inline-block text-[0.875rem] font-poppins mb-0 hover:cursor-pointer" :for="id">{{ label }}</label>
    </div>
</template>
<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Array, Boolean, Number, Object],
      required: false,
    },
    inputValue: {
      type: [String, Array, Boolean, Number, Object],
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
		isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    labelClass: {
      type: String,
      default: '',
      required: false,
    },
    customClass: {
      type: String,
      default: 'justify-center',
      required: false,
    },
    image: {
      type: String,
      default: '',
      required: false,
    },
    imageClass: {
      type: String,
      required: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  }
}
</script>
