<template>
  <button
    :id="id"
    :type="type"
    :class="buttonSize"
    :disabled="disabledProp"
    @click="$emit('click', $event)"
    class="m-0 rounded-[0.3rem] transition-all ease-in-out  justify-center text-center font-medium font-poppins items-center gap-2.5 inline-flex">
    <slot name="label"></slot>
    <slot name="loader"></slot>
  </button>
</template>
<script>
export default {
  name: 'Checkbox',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    bg: {
      type: String,
      default: 'bg-blue-600',
    },
    hover: {
      type: String,
      default: 'bg-blue-700',
    },
    width: {
      type: String,
      default: '4',
    },
    height: {
      type: String,
      default: '12',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    disabledProp:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    buttonSize() {
      if(this.customClass) {
        return `${this.customClass} text-sm leading-[0.875rem]`
      }

      if(this.buttonClass === 'btn-lg') {
        return `px-[2rem] py-[1rem] min-w-[7.063rem] h-[2.875rem] text-sm leading-[0.875rem]`
      }

      if(this.buttonClass === 'btn-md') {
        return `px-[1rem] py-[0.75rem] text-sm leading-[0.875rem] text-white`
      }

      if(this.buttonClass === 'btn-md-outline') {
        return `px-[0.75rem] py-[0.75rem] text-sm leading-[0.875rem] text-gray-500`
      }

      if(this.buttonClass === 'btn-sm') {
        return `px-[1rem] py-[0.5rem] text-xs leading-none text-white`
      }

    }
  }
}
</script>

<style scoped>
  button:disabled{
    opacity: 0.5;
  }
</style>
