<template>
  <button type="button" class="relative group items-center justify-center flex ">
    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 svg-hover" :class="computeTextColor" viewBox="0 0 22 22" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 6C11.5523 6 12 6.44772 12 7V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V7C10 6.44772 10.4477 6 11 6ZM11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16H11.01C11.5623 16 12.01 15.5523 12.01 15C12.01 14.4477 11.5623 14 11.01 14H11Z"
              fill="currentColor"/>
      </svg>

    <span class="info-text bottom-full mb-2 z-20 flex justify-center absolute">
      <span :class="computeBgColor" class="w-80 whitespace-pre-wrap rounded-lg font-poppins px-4 py-2  text-center text-white text-sm font-normal drop-shadow-md filter inset-0">
        <svg width="16" height="6" viewBox="0 0 16 6" :class="computeTextColor" class="absolute top-full left-1/2 -mt-px -ml-2">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0H1V1.00366V1.00366V1.00371H1.01672C2.72058 1.0147 4.24225 2.74704 5.42685 4.72928C6.42941 6.40691 9.57154 6.4069 10.5741 4.72926C11.7587 2.74703 13.2803 1.0147 14.9841 1.00371H15V0Z" fill="currentColor"></path>
        </svg>{{ message }}</span>
    </span>
  </button>
</template>
<script>
export default {
  name: 'InputFieldMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'danger',
    },
    id: {
      type: String,
      default: '',
      required: false,
    }
  },
  computed: {
    computeBgColor () {
      if(this.type === 'danger') {
        return 'bg-[#C13A52]'
      }
      if(this.type === 'warning') {
        return 'bg-[#F4B740]'
      }
      if(this.type === 'green') {
        return 'bg-[#3ABA6D]'
      }
    },
    computeTextColor () {
      if(this.type === 'danger') {
        return 'text-pink-700'
      }
      if(this.type === 'warning') {
        return 'text-[#F4B740]'
      }
      if(this.type === 'green') {
        return 'text-[#3ABA6D]'
      }
    }
  }
}
</script>
<style scoped lang="less">
.info-text {
  display: none;
  white-space: pre-line;

}

.svg-hover:hover + .info-text {
  display: block;
  width: 700px;
}
</style>
