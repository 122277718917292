<template>
    <div class="setting_form">
    <!--<form class="basic_form clear">-->
    <!-- Create Client Modal -->
    <b-modal
      modal-class="modal_basic modal-create-client"
      id="modal-create-client"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div class="w-full h-full">
        <div @click="close()" class="float-right w-6 h-6 flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3 h-3 cursor-pointer"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
              fill="black"
            />
          </svg>
        </div>
        <div
          class="w-full h-full flex flex-col items-center justify-between gap-6"
        >
          <div class="flex flex-col items-center justify-center">
            <svg
              width="32"
              height="38"
              viewBox="0 0 32 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6432 3.21402C10.6432 3.09582 10.7391 3 10.8573 3H21.1414C21.2596 3 21.3554 3.09582 21.3554 3.21402V6.64207C21.3554 6.76027 21.2596 6.85609 21.1414 6.85609H10.8573C10.7391 6.85609 10.6432 6.76027 10.6432 6.64207V4.97302C10.6437 4.95797 10.6439 4.94287 10.6439 4.92771C10.6439 4.91255 10.6437 4.89745 10.6432 4.8824V3.21402ZM7.64323 3.42771V3.21402C7.64323 1.43897 9.0822 0 10.8573 0H21.1414C22.9164 0 24.3554 1.43897 24.3554 3.21402V3.42771H26.2841C27.5911 3.42771 28.8446 3.94691 29.7688 4.8711C30.693 5.79529 31.2122 7.04876 31.2122 8.35575V32.3521C31.2122 33.6591 30.693 34.9125 29.7688 35.8367C28.8446 36.7609 27.5911 37.2801 26.2841 37.2801H5.71586C4.40886 37.2801 3.15539 36.7609 2.2312 35.8367C1.30701 34.9125 0.787811 33.6591 0.787811 32.3521V8.35575C0.787811 7.04876 1.30701 5.79529 2.2312 4.8711C3.15539 3.94691 4.40886 3.42771 5.71586 3.42771H7.64323ZM7.64323 6.42771H5.71586C5.20451 6.42771 4.7141 6.63084 4.35252 6.99242C3.99094 7.354 3.78781 7.84441 3.78781 8.35575V32.3521C3.78781 32.8634 3.99094 33.3538 4.35252 33.7154C4.7141 34.077 5.20451 34.2801 5.71586 34.2801H26.2841C26.7955 34.2801 27.2859 34.077 27.6475 33.7154C28.009 33.3538 28.2122 32.8634 28.2122 32.3521V8.35575C28.2122 7.84441 28.009 7.354 27.6475 6.99242C27.2859 6.63084 26.7955 6.42771 26.2841 6.42771H24.3554V6.64207C24.3554 8.41712 22.9164 9.85609 21.1414 9.85609H10.8573C9.0822 9.85609 7.64323 8.41712 7.64323 6.64207V6.42771Z"
                fill="#2560D7"
              />
            </svg>
            <p
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
            >
              Create Application
            </p>

            <p class="pt-1.5 text !text-[14px] text-center">
              Please enter details to create your application.
            </p>
          </div>

                  <!-- Form Errors -->

        <div class="flex flex-col items-center justify-center text-left alert-box danger" v-if="createForm.errors.length > 0">
          <div class="inner_box">
            <h4><i class="far fa-exclamation-triangle" aria-hidden="true"></i> Whoops!</h4>
            <ul class="list mt-3">
              <li v-for="error in createForm.errors">
                <template v-if="createForm.errors.redirect && createForm.errors.redirect.length >0">
                  {{ createForm.errors.redirect[0] }}
                </template>
                <template v-else-if="createForm.errors.message">
                  {{ createForm.errors.message }}
                </template>
                <template v-else>
                  {{ error }}
                </template>

              </li>
            </ul>

          </div>
        </div>


          <div class="flex flex-col justify-center item-center w-full ">
            <FloatingLabelInput
            id="create-client-name"
              @enter="store"
              :errorType="
                validations.name || validations.nameLength ? 'danger' : ''
              "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Name"
              placeholder="Your application name which your users will recognize and trust"
              v-model="createForm.name"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.name"
                  :message="messages.name"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.nameLength"
                  :message="messages.nameLength"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              id="redirect-url-name"
              @enter="store"
              :errorType="
                validations.redirect_url ||
                validations.redirect_url_valid ||
                validations.redirect_url_length
                  ? 'danger'
                  : ''
              "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Redirect URL"
              placeholder="Your application's authorization callback URL i.e https://app.contentstudio.io/oauth"
              v-model="createForm.redirect"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.redirect_url"
                  :message="messages.redirect_url"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.redirect_url_length"
                  :message="messages.redirect_url_length"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.redirect_url_valid"
                  :message="messages.redirect_url_valid"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>
            <URLInputFloatingV2
              class=""
              id="url-of-image"
              :readonly="true"
              :disabled="true"
              v-model="createForm.image"
              :validations="URLValidations"
              type="text"
              label="App Image"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="
                URLValidations.url ||
                URLValidations.urlLength ||
                URLValidations.urlValid
                  ? 'danger'
                  : ''
              "
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="URLValidations.url"
                  :message="URLMessages.url"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="URLValidations.urlLength"
                  :message="URLMessages.urlLength"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="URLValidations.urlValid"
                  :message="URLMessages.urlValid"
                ></InputFieldMessage>
              </template>
            </URLInputFloatingV2>
            <ImageUploadV2
              customClass="mt-[1rem] h-32"
              @click="createForm.image = $event"
              :image="createForm.image"
            ></ImageUploadV2>
          </div>
          <div class="mt-2 flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] !text-[14px] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="close()"
            >
              <template v-slot:label>Close</template>
            </Button>

            <Button
              id="store-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !text-[14px] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="store"
            >
              <template v-slot:label>Create</template>
            </Button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Edit Modal-->
    <b-modal
      modal-class="modal_basic modal-create-client sm-modal"
      id="modal-edit-client"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div class="w-full h-full">
        <div @click="closeEdit()" class="float-right w-6 h-6 flex justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3 h-3 cursor-pointer"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
              fill="black"
            />
          </svg>
        </div>
        <div
          class="w-full h-full flex flex-col items-center justify-between pt-2 gap-6"
        >
          <div class="flex flex-col items-center justify-center">
            <svg
              width="32"
              height="38"
              viewBox="0 0 32 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6432 3.21402C10.6432 3.09582 10.7391 3 10.8573 3H21.1414C21.2596 3 21.3554 3.09582 21.3554 3.21402V6.64207C21.3554 6.76027 21.2596 6.85609 21.1414 6.85609H10.8573C10.7391 6.85609 10.6432 6.76027 10.6432 6.64207V4.97302C10.6437 4.95797 10.6439 4.94287 10.6439 4.92771C10.6439 4.91255 10.6437 4.89745 10.6432 4.8824V3.21402ZM7.64323 3.42771V3.21402C7.64323 1.43897 9.0822 0 10.8573 0H21.1414C22.9164 0 24.3554 1.43897 24.3554 3.21402V3.42771H26.2841C27.5911 3.42771 28.8446 3.94691 29.7688 4.8711C30.693 5.79529 31.2122 7.04876 31.2122 8.35575V32.3521C31.2122 33.6591 30.693 34.9125 29.7688 35.8367C28.8446 36.7609 27.5911 37.2801 26.2841 37.2801H5.71586C4.40886 37.2801 3.15539 36.7609 2.2312 35.8367C1.30701 34.9125 0.787811 33.6591 0.787811 32.3521V8.35575C0.787811 7.04876 1.30701 5.79529 2.2312 4.8711C3.15539 3.94691 4.40886 3.42771 5.71586 3.42771H7.64323ZM7.64323 6.42771H5.71586C5.20451 6.42771 4.7141 6.63084 4.35252 6.99242C3.99094 7.354 3.78781 7.84441 3.78781 8.35575V32.3521C3.78781 32.8634 3.99094 33.3538 4.35252 33.7154C4.7141 34.077 5.20451 34.2801 5.71586 34.2801H26.2841C26.7955 34.2801 27.2859 34.077 27.6475 33.7154C28.009 33.3538 28.2122 32.8634 28.2122 32.3521V8.35575C28.2122 7.84441 28.009 7.354 27.6475 6.99242C27.2859 6.63084 26.7955 6.42771 26.2841 6.42771H24.3554V6.64207C24.3554 8.41712 22.9164 9.85609 21.1414 9.85609H10.8573C9.0822 9.85609 7.64323 8.41712 7.64323 6.64207V6.42771Z"
                fill="#2560D7"
              />
            </svg>
            <p
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
            >
              Edit Application
            </p>

            <p class="pt-1 text !text-[14px] text-center">
              Please enter details to edit your application.
            </p>
          </div>

                  <!-- Form Errors -->

        <div class="flex flex-col items-center justify-center text-left alert-box danger" v-if="editForm.errors.length > 0">
          <div class="inner_box">
            <h4><i class="far fa-exclamation-triangle" aria-hidden="true"></i> Whoops!</h4>
            <ul class="list mt-3">
              <li v-for="error in editForm.errors">
                <template v-if="error.redirect && error.redirect.length >0">
                  {{ error.redirect[0] }}
                </template>
                <template v-else-if="editForm.errors.message">
                  {{ editForm.errors.message }}
                </template>
                <template v-else>
                  {{ error }}
                </template>
              </li>
            </ul>

          </div>
        </div>


          <div class="flex flex-col justify-center item-center w-full">
            <FloatingLabelInput
            id="edit-client-name"
              @enter="update"
              :errorType="
                validations.name || validations.nameLength ? 'danger' : ''
              "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Name"
              placeholder="Your application name which your users will recognize and trust"
              v-model="editForm.name"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.name"
                  :message="messages.name"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.nameLength"
                  :message="messages.nameLength"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              id="redirect-url-name"
              @enter="update"
              :errorType="
                validations.redirect_url ||
                validations.redirect_url_valid ||
                validations.redirect_url_length
                  ? 'danger'
                  : ''
              "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Redirect URL"
              placeholder="Your application's authorization callback URL i.e https://app.contentstudio.io/oauth"
              v-model="editForm.redirect"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.redirect_url"
                  :message="messages.redirect_url"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.redirect_url_length"
                  :message="messages.redirect_url_length"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="validations.redirect_url_valid"
                  :message="messages.redirect_url_valid"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>
            <URLInputFloatingV2
              class=""
              id="url-of-image"
              :readonly="true"
              :disabled="true"
              v-model="editForm.image"
              :validations="URLValidations"
              type="text"
              label="App Image"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="
                URLValidations.url ||
                URLValidations.urlLength ||
                URLValidations.urlValid
                  ? 'danger'
                  : ''
              "
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="URLValidations.url"
                  :message="URLMessages.url"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="URLValidations.urlLength"
                  :message="URLMessages.urlLength"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="URLValidations.urlValid"
                  :message="URLMessages.urlValid"
                ></InputFieldMessage>
              </template>
            </URLInputFloatingV2>
            <ImageUploadV2
              customClass="mt-[1rem] h-32"
              @click="editForm.image = $event"
            ></ImageUploadV2>
          </div>
          <div class="mt-2 flex gap-x-3 justify-between items-center">
            <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !text-[14px] !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="closeEdit()"
            >
              <template v-slot:label>Close</template>
            </Button>

            <Button
              id="store-button"
              type="button"
              class="text-white bg-[#2560D7] !text-[14px] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="update"
            >
              <template v-slot:label>Update</template>
            </Button>
          </div>
        </div>
      </div>
    </b-modal>

    <div
      class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px] "
    >
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
          <div>
            <div class="flex border-b h-[4.188rem] px-3 py-3.5 justify-between items-center h-full">
              <div class="w-full flex gap-x-2">
                <Button
                  id="create-new-app-button"
                  type="button"
                  class="bg-[#2560D7] hover:bg-[#2560D7]"
                  buttonClass="btn-md"
                  @click.prevent="showCreateClientForm()"
                >
                  <template v-slot:label>Add Application</template>
                </Button>
              </div>
              <div class="w-full flex justify-center items-center">
                <p class="text font-bold text-center mr-[0.2rem] !text-[1.125rem] !text-[#3C4549]">Your Applications</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                    fill="#3C4549"
                  />
                </svg>
              </div>
              <div class="w-full flex items-center justify-end space-x-2"></div>
            </div>
          </div>
          <!-- Heading End -->
          <!-- <CustomDomainsTable
              ></CustomDomainsTable> -->
        </div>
        <!--Table -->
        <table class="developers_app_table text-left">
          <thead
            :class="'bg-[#F4F6FA]'"
            class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins"
          >
            <tr>
              <td class="py-3 pl-3 w-1/4">Name</td>
              <td class="py-3 text-left w-1/4">Client ID</td>
              <td class="py-3 text-left w-1/4">Client Secret</td>
              <td class="py-3 pr-3 text-right w-1/4">Action</td>
            </tr>
          </thead>

          <tbody v-if="clients.length == 0">
            <tr>
              <td colspan="4" class="">
                <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
                  <i class="fab fa-bandcamp" aria-hidden="true"></i>
                  You have not created any App Yet, please create one.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else-if="clients.length > 0"
            class="font-poppins text-[#3C4549] text-[0.875rem] "
          >
            <tr v-for="client in clients" class="border-b border-b-[#ECEEF5]">
              <td class="py-4 pl-3 text-left w-1/4">
                {{ client.name }}
              </td>
              <td class="py-4 text-left w-1/4">
                {{ client._id }}
              </td>
              <td class="py-4 text-left w-1/4">
                <code>{{ client.secret }}</code>
              </td>
              <td class="py-4 pr-3 text-right w-full flex gap-1 justify-end items-center h-full">
                <svg
                  :class="
                    ActiveModal === 'editDevelopersApp'
                      ? 'rounded-full !bg-[#F4F6FA)]'
                      : ''
                  "
                  class="cursor-pointer mr-2"
                  v-tooltip="'Edit Developers App'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click.prevent="
                    ActiveModal = 'editDevelopersApp';
                    edit(client);
                  "
                >
                  <path
                    d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                    stroke="#3C4549"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  :class="
                    ActiveModal === 'deleteDevelopersApp'
                      ? 'rounded-full !bg-[#F4F6FA)]'
                      : ''
                  "
                  class="cursor-pointer"
                  v-tooltip="'Delete Developer App'"
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click.prevent="
                    ActiveModal = 'deleteDevelopersApp';
                    destroy(client);
                  "
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                    fill="#97304A"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadAppPictureURL } from "@/config/config.js";
import { mapGetters } from "vuex";
import http from "@/mixins/http-lib";
import { API_URL_v3 } from "@/constants";
import { URLMessages, URLValidations } from "@/common/attributes";

export default {
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    ImageUploadV2: () => import("@/ui/ui-kit/v2/ImageUploadV2.vue"),
    URLInputFloatingV2: () => import("@/ui/ui-kit/v2/URLInputFloatingV2.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
  },
  /*
   * The component's data.
   */
  data() {
    return {
      URLValidations: URLValidations,
      URLMessages: URLMessages,
      clients: [],
      ActiveModal: "",
      createForm: {
        errors: [],
        name: "",
        redirect: "",
        image: "",
        fileName: "",
      },

      editForm: {
        errors: [],
        name: "",
        redirect: "",
        image: "",
        fileName: "",
      },
      validations: {
        name: false,
        nameLength: false,
        redirect_url: false,
        redirect_url_valid: false,
        image: false,
      },
      messages: {
        name: "Name cannot be empty",
        nameLength: "The name should not be greater than 35 characters",
        redirect_url: "Redirect URL cannot be empty",
        redirect_url_valid: "Please enter a valid URL",
        redirect_url_length:
          "The url should not be greater than 74 characters.",
        image: "Please upload the image for your application.",
      },
    };
  },

  /**
   * Prepare the component (Vue 1.x).
   */
  ready() {
    this.prepareComponent();
  },

  /**
   * Prepare the component (Vue 2.x).
   */
  mounted() {
    this.prepareComponent();
  },

  created() {
    this.getSiteDetails;
  },



  methods: {
    close() {
      this.createForm.image = "";
      this.$bvModal.hide("modal-create-client");
    },
    closeEdit(){
      this.editForm.image = "";
      this.$bvModal.hide("modal-edit-client");
    },
    /**
     * Prepare the component.
     */
    prepareComponent() {
      this.getClients();

      $("#modal-create-client").on("shown.bs.modal", () => {
        $("#create-client-name").focus();
      });

      $("#modal-edit-client").on("shown.bs.modal", () => {
        $("#edit-client-name").focus();
      });
    },

    /**
     * Get all of the OAuth clients for the user.
     */
    getClients() {
      http.post(API_URL_v3 + "oauth/clients", {brand_id: this.getWorkspace._id}).then((response) => {
        if (response.data.tokenError && response.data.tokenError === true) {
          this.alertMessage(response.data.message, "error");
        } else {
          this.clients = response.data;
        }
      });
    },

    /**
     * Show the form for creating new clients.
     */
    showCreateClientForm() {
      this.validations = _.mapValues(this.validations, () => false);
      // $('#modal-create-client').modal('show')
      this.$bvModal.show("modal-create-client");
    },

    /**
     * Create a new OAuth client for the user.
     */
    store() {
      if (this.performValidations(this.createForm)) {
        this.persistClient(
          "post",
          API_URL_v3 + "oauth/store",
          {...this.createForm, brand_id: this.getWorkspace._id},
          "modal-create-client"
        );
      } else {
        console.log("failed");
      }
    },

    performValidations(payload) {
      this.validations.name = this.requiredCheck(payload.name);
      this.validations.redirect_url = this.requiredCheck(payload.redirect);
      this.validations.redirect_url_valid = !this.urlCheck(payload.redirect);
      this.validations.image = this.requiredCheck(payload.image);
      return Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      );
    },
    /**
     * Create a OAuth client image the user.
     */
    uploadAppImage(e) {
      console.debug("app picture upload");
      e.preventDefault();
      let files = e.target.files || e.dataTransfer.files;
      files = files[0];
      let formData = new FormData();
      formData.append("app_picture", files);

      http.post(uploadAppPictureURL, formData).then(
        (response) => {
          if (response.data.tokenError && response.data.tokenError === true) {
            this.alertMessage(response.data.message, "error");
          } else {
            if (response.data.status) {
              this.createForm["fileName"] = files["name"];
              this.createForm["image"] = response.data.url;
              this.editForm["fileName"] = files["name"];
              this.editForm["image"] = response.data.url;
            } else {
              this.alertMessage(response.data.message, "error");
            }
          }
        },
        (response) => {}
      );
    },

    /**
     * Edit the given client.
     */
    edit(client) {
      this.editForm.id = client._id;
      this.editForm.name = client.name;
      this.editForm.redirect = client.redirect;
      this.editForm.image = client.image;
      this.editForm.fileName = client.filename;
      this.$bvModal.show("modal-edit-client");
    },

    /**
     * Update the client being edited.
     */
    update() {
      if (this.performValidations(this.editForm)) {
        this.persistClient(
          "put",
          API_URL_v3 + "oauth/clients/" + this.editForm.id,
          {...this.editForm, brand_id: this.getWorkspace._id},
          "modal-edit-client"
        );
      }
    },

    /**
     * Persist the client to storage using the given form.
     */
    persistClient(method, uri, form, modal) {
      form.errors = [];

      http[method](uri, form)
        .then((response) => {
          this.getClients();

          form.name = "";
          form.redirect = "";
          form.image = "";
          form.errors = [];
          this.$bvModal.hide(modal);
        })
        .catch((response) => {
          if (typeof response.data === "object") {
            form.errors = _.flatten(_.toArray(response.data));
          } else {
            this.$store.dispatch("toastNotification", {
              message: "Something went wrong. Please try again.",
              type: "error",
            });
          }
        });
    },

    /**
     * Destroy the given client.
     */
    destroy(client) {
      http
        .delete(API_URL_v3 + "oauth/clients/" + client._id)
        .then((response) => {
          this.getClients();
        });
    },
  },

  computed: {
    ...mapGetters(["getProfile", "getSubscription", 'getWorkspace']),
  },
  watch: {
    "getWorkspace._id" (value) {
      this.getClients()
    },
    "createForm.name"(value) {
      if (value && value.length > 0) {
        this.validations.name = false;
        this.validations.nameLength = !this.maxLength(value, 35);
      }
    },
    "editForm.name"(value) {
      if (value && value.length > 0) {
        this.validations.name = false;
        this.validations.nameLength = !this.maxLength(value, 35);
      }
    },
    "createForm.fileName"(value) {
      if (value && value.trim().length > 0) this.validations.image = false;
    },
    "editForm.fileName"(value) {
      if (value && value.trim().length > 0) this.validations.image = false;
    },
    "createForm.redirect"(value) {
      if (value && value.trim().length > 8) {
        this.validations.redirect_url = false;
        this.createForm.redirect = this.urlConcatenation(
          this.createForm.redirect
        );
        this.validations.redirect_url_length = !this.maxLength(
          this.createForm.redirect,
          74
        );
        this.validations.redirect_url_valid = !this.isValidURL(
          this.createForm.redirect
        );
      }
    },
    "editForm.redirect"(value) {
      if (value && value.trim().length > 8) {
        this.validations.redirect_url = false;
        this.editForm.redirect = this.urlConcatenation(this.editForm.redirect);
        this.validations.redirect_url_length = !this.maxLength(
          this.editForm.redirect,
          74
        );
        this.validations.redirect_url_valid = !this.isValidURL(
          this.editForm.redirect
        );
      }
    },
  },
};
</script>
<style lang="less">
.developers_app_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 14rem);
    table-layout: fixed;
  }

  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.modal-create-client {
  .modal-dialog {
    max-width: 36rem !important;
  }

  .modal-body {
    padding: 40px 40px 58px 40px !important;
  }
}
</style>
