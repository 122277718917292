import http from '@/mixins/http-lib'
import {
  addTagToLinkURL,
  deleteDomainURL,
  deleteTagURL, mergeTagURL,
  removeTagFromLinkURL,
  showTagURL,
  storeTagURL
} from '../../config/api'
import { linkTypes, tagTypes } from './mutation-types'

export default {
  state: {
    tag: {
      list: [],
      selection: [],
      selected_tag: {},
      edit: null,
      merge: null
    }

  },
  getters: {
    getTagList: state => {
      return state.tag.list
    },
    getTagSelection: state => {
      return state.tag.selection
    },
    getTag: state => {
      return state.tag
    }
  },
  mutations: {
    [tagTypes.SET_TAGS_LIST] (state, value) {
      state.tag.list = value
    },
    [tagTypes.ADD_TAG_TO_LIST] (state, value) {
      state.tag.list.push(value)
    },
    [tagTypes.SET_TAG_SELECTION] (state, value) {
      state.tag.selection = value
    },
    [tagTypes.SET_TAG_EDIT_VALUE] (state, value) {
      state.tag.edit = value
    },
    [tagTypes.SET_TAG_MERGE_VALUE] (state, value) {
      state.tag.merge = value
    }
  },
  actions: {
    storeTag ({ commit, getters, dispatch }, { id, name, type }) {

      let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
      let brand_id = getters.getWorkspace && getters.getWorkspace._id ? getters.getWorkspace._id : localStorageWorkspace._id

      return http.post(storeTagURL, {
        _id: id,
        name: name,
        brand_id: brand_id
      }).then(resp => {
        if (resp.data.status) {
          switch (type) {
            case 'edit':
              commit(tagTypes.SET_TAGS_LIST, resp.data.data)
              commit(tagTypes.SET_TAG_EDIT_VALUE, null)
              break
            case 'create':
              commit(linkTypes.SET_LINK_TAGS, getters.getSaveLink.tags.concat(resp.data.data._id))
              commit(tagTypes.ADD_TAG_TO_LIST, resp.data.data)
              commit(linkTypes.SET_LINK_TAG_VALUE, '')
              break
            default:
              break
          }
        } else {
          dispatch('toastNotification', { message: resp.data.message, type: 'error' })
        }
        return resp.data.status
      }).catch((err) => {
        console.warn(err)
      })
    },
    fetchTags ({ commit, getters }) {

      let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
      let brand_id = getters.getWorkspace && getters.getWorkspace._id ? getters.getWorkspace._id : localStorageWorkspace._id

      http.post(showTagURL, {brand_id: brand_id}).then(resp => {
        if (resp.data.status) {
          commit(tagTypes.SET_TAGS_LIST, resp.data.data.sort((a,b) => { return (a.tag.toLowerCase() > b.tag.toLowerCase()) ? 1 : -1 }))
        }
      }).catch(err => {
          console.warn(err)
        }
      )
    },

    mergeTags ({ commit, getters, dispatch }) {
      http.post(mergeTagURL, {
        tags: getters.getTagSelection,
        value: getters.getTag.merge
      }).then(res => {
        if (res.data.status) {
          commit(tagTypes.SET_TAGS_LIST, res.data.data)
          commit(tagTypes.SET_TAG_MERGE_VALUE, '')
          commit(linkTypes.SET_LINK_SUGGESTED_TAGS, [])
          dispatch('toastNotification', { message: 'Selected tags have been merged.' })
        }
        $('.marge_tooltip').removeClass('animate_it')
      }).catch(() => {
        $('.marge_tooltip').removeClass('animate_it')
      })
    },
    deleteTags ({ commit, getters, dispatch },selectedTags) {
      http.post(deleteTagURL, { tags: selectedTags }).then(resp => {
        if (resp.data.status) {
          commit(tagTypes.SET_TAGS_LIST, resp.data.data)
          commit(tagTypes.SET_TAG_SELECTION, [])
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    addTagToLink ({ commit, getters, dispatch }, { tag, linkId, index }) {
      let link = getters.getLinks.items[index]
      return http.post(addTagToLinkURL, {
        tag: tag,
        link_id: linkId
      }).then(res => {
        link.addTag = false
        if (res.data.status) {
          commit(tagTypes.SET_TAGS_LIST, res.data.data.all)
          link.tags = res.data.data.link_tags
          link.tagLoader = false
        }
        return res.data
      }).catch(() => {
        link.addTag = false
      })
    },
    addTagToQuickLink ({ commit, getters, dispatch }, { tag, linkId }) {
      return http.post(addTagToLinkURL, {
        tag: tag,
        link_id: linkId
      }).then(res => {
        commit(linkTypes.SET_QUICK_LINK_TAG_LOADER, false)
        if (res.data.status) {
          commit(tagTypes.SET_TAGS_LIST, res.data.data.all)
          commit(linkTypes.SET_QUICK_LINK_TAGS, res.data.data.link_tags)
          commit(linkTypes.SET_QUICK_LINK_TAG_VALUE, '')
          commit(linkTypes.SET_QUICK_LINK_TAG_FOCUS, false)
        }
      }).catch(() => {
        commit(linkTypes.SET_QUICK_LINK_TAG_LOADER, false)
      })
    },
    removeTagFromLink ({ commit, getters, dispatch }, { tag, linkId, index }) {
      return http.post(removeTagFromLinkURL, {
        tag: tag,
        link_id: linkId
      })
    }

  }
}
