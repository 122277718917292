<template>
  <b-modal
      modal-class="modal_basic add_ip_modal"
      id="addBlacklistIpModal"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
  >
    <div class="w-full h-full">
      <div
          class="w-full h-full flex flex-col items-center justify-between pt-2 gap-6"
      >
        <div class="flex flex-col items-center justify-center">
          <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M10.2226 8.05566V23.9443H8.05566V8.05566H10.2226Z"
                fill="#2560D7"
            />
            <path
                d="M20.193 17.7133H15.8253V16H20.193C21.0388 16 21.7237 15.869 22.2477 15.6071C22.7716 15.3452 23.1534 14.9815 23.3929 14.5159C23.6399 14.0503 23.7634 13.5192 23.7634 12.9227C23.7634 12.377 23.6399 11.8641 23.3929 11.384C23.1534 10.9038 22.7716 10.5183 22.2477 10.2273C21.7237 9.92898 21.0388 9.77985 20.193 9.77985H16.3306V23.9443H14.1636V8.05566H20.193C21.428 8.05566 22.4722 8.263 23.3256 8.67768C24.1789 9.09236 24.8263 9.66708 25.268 10.4019C25.7096 11.1294 25.9304 11.9624 25.9304 12.9008C25.9304 13.9193 25.7096 14.7887 25.268 15.5089C24.8263 16.2292 24.1789 16.7784 23.3256 17.1567C22.4722 17.5278 21.428 17.7133 20.193 17.7133Z"
                fill="#2560D7"
            />
            <circle
                cx="16"
                cy="16"
                r="14.7671"
                stroke="#2560D7"
                stroke-width="2.24311"
            />
            <path
                d="M6.06958 6.06934L27.9165 25.9302"
                stroke="#2560D7"
                stroke-width="2.24311"
            />
          </svg>
          <p
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
          >
            {{ getAddBlacklistIp ? 'Edit' :'Add'}} your IP Address
          </p>

          <p
              v-if="yourIp"
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[0.875rem] font-bold leading-5"
          >
            * Your IP Address is
            <span class="ml-1">({{ yourIp }})</span>
            <i
                class="fal fa-clipboard pointer ml-1"
                v-tooltip="'Copy IP to clipboard'"
                @click="copyText(yourIp)"
                aria-hidden="true"
            ></i>
          </p>

          <p class="pt-2 text !text-[14px] text-center">
            Please enter details to add IP Address.
          </p>
        </div>

        <div class="flex flex-col justify-center item-center w-full gap-3">
          <FloatingLabelInput
              id="Pixel-Campaign"
              @enter="validateAndStorePixel()"
              :errorType="
              validations.ip_address || validations.ip_address_valid
                ? 'danger'
                : ''
            "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="IP Address"
              placeholder="e.g 34.70.69.10"
              v-model="getAddBlacklistIp.ip_address"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                  v-if="validations.ip_address"
                  :message="messages.ip_address"
              ></InputFieldMessage>
              <InputFieldMessage
                  v-else-if="validations.ip_address_valid"
                  :message="messages.ip_address_valid"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
        </div>
        <div class="mt-2 flex gap-x-3 justify-between items-center">
          <Button
              id="close-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] !rounded-[0.571rem] !text-[1rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
              id="store-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !rounded-[0.571rem] !text-[1rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="validateAndStoreIp()"
              :disabledProp="addIpLoader"
          >
            <template v-slot:label>{{ getAddBlacklistIp._id ? 'Edit' : 'Add' }}</template>
            <template v-if="addIpLoader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
import { blacklistIp } from '../../../state/modules/mutation-types'


export default {
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
  },
  data () {
    return {
      validations: {
        ip_address: false,
        ip_address_valid: false
      },
      messages: {
        ip_address: 'IP Address cannot be empty',
        ip_address_valid: 'Invalid IP Address'
      },
      addIpLoader: false,
      yourIp: null,
    }
  },


  async created () {
    this.getSiteDetails
    this.yourIp = await this.getServerIp()
  },
  methods: {
    ...mapMutations([blacklistIp.SET_BLACKLIST_IP]),
    ...mapActions(['fetchBlacklistIp', 'storeBlacklistIp', 'getServerIp']),
    copyText (ip) {
      this.alertMessage('Your IP has been copied to clipboard', 'success')
      navigator.clipboard.writeText(ip)
    },
    close() {
      this.$bvModal.hide("addBlacklistIpModal");
      this.validations = {
        ip_address: false,
        ip_address_valid: false
      };
    },

    async validateAndStoreIp () {
      this.validations.ip_address = this.requiredCheck(this.getAddBlacklistIp.ip_address)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.addIpLoader = true
        await this.storeBlacklistIp()
        this.addIpLoader = false
        this.$bvModal.hide('addBlacklistIpModal')
        this.fetchBlacklistIp()
      }
    },
  },
  computed: {
    ...mapGetters([
      'getAddBlacklistIp'
    ])
  },

  watch: {
    'getAddBlacklistIp.ip_address' (value) {
      if (value && value.length > 0) {
        this.validations.ip_address = false
        this.validations.ip_address_valid = !this.validateIpAddress(value)
      }
    },
  }

};
</script>
<style lang="less">
.add_ip_modal {
  .modal-dialog {
    max-width: 28.71rem !important;
  }

  .modal-body {
    padding: 40px 40px 58px 40px !important;
  }
}
</style>

