<template>
  <div :class="{'flex': isShowButton}">
    <div :style="borderColor" :class="computeClass" class="border !border-transparent w-full justify-start items-center gap-2.5 flex px-[0.9rem]">
      <img v-if="showIconLeft" class="w-[1.347rem] h-[1.347rem]" :src="`/assets/img/v2/${iconName}`"/>
      <div :class="[labelFocused || value ? 'items-start mt-[0.5rem]' : 'items-center ']"  class="relative justify-start w-full gap-1.5 flex">
        <input
          :id="id"
          v-model="model"
          :type="type"
          :name="name"
          :maxlength="maxlength"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          @keydown.enter="$emit('enter')"
          @keydown="$emit('keydown', $event)"
          class="w-full font-poppins text !text-[#3C4549] bg-transparent p-0 transition-all ease-in-out duration-300"
          @focus="$emit('focus', $event); labelFocused = true"
          @blur="$emit('blur', $event); labelFocused = false"
          @keyup="$emit('keyup', $event)"
          style="border:none;"
          :placeholder="labelFocused ? placeholder : ''"
          :class="{'mt-[0.625rem]': labelFocused || value }"
        />
        <label
          :for="id"
          class="absolute m-0 left-0 font-poppins text-[0.75rem] text-[#757A8A] bg-transparent transition-all duration-300"
          :class="{ 'text-[0.75rem] ': labelFocused || value}"
        >{{ label }}</label>
      </div>
      <slot name="error_message"></slot>
      <slot name="right_icon"></slot>
    </div>
    <button type="button" v-if="isShowButton" class="w-24 px-4 bg-blue-700 rounded-tr-lg rounded-br-lg justify-center items-center gap-2 inline-flex">
      <div class="text-white font-poppins leading-none">Create</div>
      <img class="w-3 h-2.5" src="/assets/img/v2/icons/right.svg" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'FloatingLabelInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      required: false,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    showIconLeft: {
      type: Boolean,
      default: false,
    },
    showIconRight: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    readonly: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    customClass: {
      type: String,
      default: 'h-[56px]'
    },
    isShowButton: {
      type: Boolean,
      default: false
    },
    errorType: {
      type: String,
      default: ''
    },
    authPageCalled: {
      type: Boolean,
      default: false
    },
    placeholder:{
      type : String,
      required:false
    }
  },
  data() {
    return {
      labelFocused: false
    }
  },
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    borderColor() {

      if(this.errorType === 'danger') {
        return {'border': '1px solid', 'border-color': '#BE185D !important'}
      }
      if(this.errorType === 'warning') {
        return {'border': '1px solid', 'border-color': '#F4B740 !important'}
      }
      if(this.errorType === 'green') {
        return {'border': '1px solid', 'border-color': '#3ABA6D !important'}
      }

      if(this.authPageCalled) {
        return this.labelFocused ?  {'border': '1px solid', 'border-color': '#2766E4 !important'} : {'border': '1px solid', 'border-color': '#D2D5DF !important'}
      }

      return this.labelFocused ?  {'border': '1px solid' ,'border-color': '#2766E4 !important'} : ''

    },
    computeClass() {
      const errorTypeColors = {
        danger: 'bg-[#FFFCFC]',
        warning: 'bg-[#FFFEF9]',
        green: 'bg-[#FBFFFA]',
      };

      const defaultColor = this.labelFocused ? 'border-[#2766E4]' : 'border-[#D2D5DF]';
      const selectedColor = errorTypeColors[this.errorType] || defaultColor;

      return selectedColor + ' ' + (this.isShowButton ? 'rounded-tl-lg rounded-bl-lg' : 'rounded-[0.5rem]') + ' ' + this.customClass + (this.disabled ? ' select-none cursor-not-allowed' : '');
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style lang="less" scoped>
input {
  height: 1.875rem !important;
}
input:focus, input:not(:placeholder-shown) {
  height: 2.063rem !important;
}
</style>
