import http from '@/mixins/http-lib'
import { deleteBlacklistIpURL, fetchBlacklistIpURL, fetchIp, storeBlacklistIpURL } from '../../../config/api'
import { blacklistIp } from '../mutation-types'

const defaultAddBlacklistIpState = () => {
  return {
    _id: null,
    ip_address: null
  }
}

export default {
  state: {
    blacklist: {
      add: defaultAddBlacklistIpState(),
      list: [],
      loaders: {
        add: false,
        get: true
      }
    }
  },
  actions: {

    async storeBlacklistIp ({ commit, getters, dispatch, state }) {
      let payload = getters.getAddBlacklistIp

      let localStorageWorkspace = JSON.parse(localStorage.getItem('workspace'))
      let brand_id = getters.getWorkspace && getters.getWorkspace._id ? getters.getWorkspace._id : localStorageWorkspace._id

      let res = await http.post(storeBlacklistIpURL, {
        _id: payload._id,
        ip_address: payload.ip_address,
        brand_id: brand_id
      }).then(res => {
        if (res.data.status) {
          commit(blacklistIp.RESET_BLACKLIST_IP)
          dispatch('toastNotification', { message: res.data.message })
          return res.data.data
        } else {
          dispatch('toastNotification', { message: res.data.message, type: 'error' })
        }
        return res
      }).catch(() => {
        return null
      })

      return res
    },
    async getServerIp () {
      return await http.get('https://ipecho.net/plain').then(res => {
        if (res.data) {
          return res.data
        }
        return null
      }).catch(() => {
        return null
      })
    },
    fetchBlacklistIp ({ commit, getters }) {
      commit(blacklistIp.SET_BLACKLIST_IP_LOADER_GET, true)
      http.post(fetchBlacklistIpURL, {brand_id: getters.getWorkspace._id}).then(res => {
        if (res.data.status) {
          commit(blacklistIp.SET_BLACKLIST_IP_LIST, res.data.data)
        }
        commit(blacklistIp.SET_BLACKLIST_IP_LOADER_GET, false)
      }).catch(err => {
        commit(blacklistIp.SET_BLACKLIST_IP_LOADER_GET, false)
      })
    },
    deleteIp({ dispatch }, pixelId) {
      return http.post(deleteBlacklistIpURL, { _id: pixelId })
        .then(function (resp) {
          if (resp.data.status) {
            dispatch('fetchBlacklistIp');
          }
        })
        .catch(err => {
          // Handle the error if needed
        });
    }

  },
  getters: {
    getAddBlacklistIp: state => {
      return state.blacklist.add
    },
    getBlacklistIpList: state => {
      return state.blacklist.list
    },
    getBlacklistIpsLoaders: state => {
      return state.blacklist.loaders
    }
  },
  mutations: {
    [blacklistIp.SET_BLACKLIST_IP_LIST] (state, value) {
      state.blacklist.list = value
    },
    [blacklistIp.ADD_BLACKLIST_IP_TO_LIST] (state, value) {
      state.blacklist.list.push(value)
    },
    [blacklistIp.SET_BLACKLIST_IP] (state, value) {
      state.blacklist.add = value
    },
    [blacklistIp.RESET_BLACKLIST_IP] (state) {
      state.blacklist.add = defaultAddBlacklistIpState()
    },
    [blacklistIp.SET_BLACKLIST_IP_LOADER_GET] (state, value) {
      state.blacklist.loaders.get = value
    }
  }
}
