import { mapActions, mapGetters } from 'vuex'
import { analyticsTypes } from '@/state/modules/mutation-types'
import http from '@/mixins/http-lib'
import { fetchAnalyticsByLinks } from '@/config/api'

let AnalyticsMixin = {
  computed: {
    ...mapGetters([
      'getAnalytics', 'getAnalyticsDetail', 'getBrands'])
  },

  methods: {
    ...mapActions([]),
    sortingValue (caseValue) {
      if (caseValue.includes('unique_clicks')) {
        return ['unique_clicks.value', caseValue.split('clicks_')[1]]
      }
      if (caseValue.includes('clicks')) {
        return ['doc_count', caseValue.split('_')[1]]
      }
      if (caseValue.includes('conversions')) {
        return ['clicked_conversions.doc_count', caseValue.split('_')[1]]
      }
    },
    /**
     *
     * @param page {Integer}
     * @param campaignId {String}
     * @param campaignIndex {Integer}
     * @param brandIndex {Integer}
     * @param campaign {Object}
     */
    async fetchCampaignByLinks (page = 1, brandId, campaignId, campaignIndex, campaign) {
      this.$set(campaign, 'loader', true)
      let res = await this.$store.dispatch('listViewLinksStatistics', {
        brandId: brandId,
        campaignId: campaignId,
        page: page
      })
      delete res.data.links.data
      res.data.links.data = res.data.data
      res.data.links.data = res.data.links.data.map(val => ({ ...val, fetchStats: false }))
      this.$set(campaign, 'links', res.data.links)
      this.$set(campaign, 'loader', false)

      campaign.links.data.forEach(link => {
        http.post(fetchAnalyticsByLinks, {
          link_id: link._id
        }).then(res => {
          link.fetchStats = true
          link.total_visitors = res.data.data.aggregations.total_visitors.value
          link.unique_visitors = res.data.data.aggregations.unique_clicks.value
          link.total_clicks_and_conversions = res.data.data.aggregations.clicked_conversions.doc_count
          if (link.total_visitors <= 0) {
            brand.conversion_rate = 0
          } else {
            const digits = 2
            let value = (link.total_clicks_and_conversions / link.total_visitors) * 100
            value = value * Math.pow(10, digits)
            value = Math.round(value)
            link.conversion_rate = value / Math.pow(10, digits)
          }
        })
      })
      return
    },
    async fetchLinksAnalyticsMixins (campaign) {
      campaign.links.forEach(link => {
        let res = this.$store.dispatch('fetchLinkListAnalytics', { linkId: link._id })
        link.fetchStats = true
        link.total_visitors = res.aggregations.total_visitors.value
        link.unique_visitors = res.aggregations.unique_clicks.value
        link.total_clicks_and_conversions = res.aggregations.clicked_conversions.doc_count
        if (link.total_visitors <= 0) {
          brand.conversion_rate = 0
        } else {
          const digits = 2
          let value = (link.total_clicks_and_conversions / link.total_visitors) * 100
          value = value * Math.pow(10, digits)
          value = Math.round(value)
          link.conversion_rate = value / Math.pow(10, digits)
        }
      })

      // res.data.links.data = res.data.data
      // this.$set(campaign, 'links', res.data.links)
      // this.$set(campaign, 'loader', false)
      return
    },
    getDateFormat (dateTime, format) {
      return moment(dateTime).format(format)
    },
    brandDetailView (brand) {
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_CAMPAIGN_FILTER)
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_LINK_FILTER)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_BRAND_FILTER, brand)
      this.$router.push({ name: 'analytics_detail' })
    },
    campaignDetailView (brand, campaign) {
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_LINK_FILTER)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_CAMPAIGN_FILTER, campaign)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_BRAND_FILTER, brand)
      this.$router.push({ name: 'analytics_detail' })
    },
    linkDetailView (brand, campaign, link) {
      this.$store.commit(analyticsTypes.SET_ANALYTICS_CAMPAIGN_FILTER, campaign)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_BRAND_FILTER, brand)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_LINK_FILTER, link)
      this.$router.push({ name: 'analytics_detail' })
    },
    bioLinkDetailView (brand, bio_link) {
      this.$store.commit(analyticsTypes.SET_ANALYTICS_BRAND_FILTER, brand)
      this.$store.commit(analyticsTypes.SET_ANALYTICS_BIO_LINK_FILTER, bio_link)
      this.$router.push({ name: 'analytics_detail' })
    },
    parseVisitorDateTime (date) {
      const d = new Date(date)
      return (this.getMonthName(date)).slice(0, 3) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' +
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
    },
    /**
     * Calculate conversions performance and clicks performance from the stats
     *
     * @param performanceHistory {object}     = contains information about the statistics
     * @param activity {object}               = contains checks whether to show graphs or not
     * @param conversionsArr {object}         = contains array of data for conversions
     * @param clicksArr {object}              = contains array of data for clicks
     *
     * @return void
     */
    performanceChart (performanceHistory, activity, conversionsArr, clicksArr, addUnique = false) {
      const clicks = []
      const uniqueClicks = []
      const conversions = []
      let clicksCount = 0
      let conversionsCount = 0
      performanceHistory.forEach(item => {
        clicks.push([Date.parse(item.key_as_string), item.doc_count])
        uniqueClicks.push([Date.parse(item.key_as_string), item.unique_clicks.value])
        if(item.clicked_conversions){
          conversions.push([Date.parse(item.key_as_string), item.clicked_conversions.doc_count])
          conversionsCount += (item.clicked_conversions.doc_count) ? item.clicked_conversions.doc_count : 0
        }
        clicksCount += (item.doc_count) ? item.doc_count : 0
      })
      activity.clicks = !!clicksCount
      activity.conversions = !!conversionsCount

      conversionsArr.series[0].data = conversions
      clicksArr.series[0].data = clicks
      if (addUnique && clicksArr.series[1]) {
        clicksArr.series[1].data = uniqueClicks
      }
      clicksArr.series[2].data = conversions
    }
  }

}

export { AnalyticsMixin }
