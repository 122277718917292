<template>
  <div class="featureSlider flex flex-col h-full justify-center">
    <div class="featureSlider__header--no-padding pb-12 mx-auto">
      <img class="w-48" src="/assets/img/v2/replug-logo-black.svg"/>
    </div>
    <div class="VueCarousel featureSlider__carousel">
      <slick ref="slick" :options="slickOptions">
        <div class="auth-features-container flex flex-column items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h1 class="font-poppins text-[2rem] font-semibold">New Feature: Deep Links</h1>
              <p class="font-poppins text-[#757A8A] font-weight-500 my-2"> Create links to navigate users to the Google Play Store or Apple Store and download the intended app. Play videos and songs directly from an appropriate app to increase app installations. </p>
            </div>
            <div class="auth-features__thumbnail">
              <img src="/assets/img/login/deep_linking.png"/>
            </div>
          </div>
        </div>
        <div class="auth-features-container d-flex flex-column align-items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h1 class="font-poppins text-[2rem] font-semibold">New Feature: Traffic Routing Rules</h1>
              <p class="font-poppins text-[#757A8A] font-weight-500 my-2"> Traffic routing rules empower you to target specific audiences, audience segmentation, and the ability to have multiple URL destinations. Get total control of your links, all intending to boost your conversions.

              </p>
            </div>
            <div class="auth-features__thumbnail">
              <img
                src="/assets/img/login/traffic_routing.png"/>
            </div>
          </div>
        </div>
        <div class="auth-features-container d-flex flex-column align-items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h1 class="font-poppins text-[2rem] font-semibold">New Feature: A/B Testing</h1>
              <p class="font-poppins text-[#757A8A] font-weight-500 my-2"> Webpage A/B testing gives you insight on which page performs better based on conversion rates, session times, and bounce rate. Create high converting marketing campaigns by A/B testing your landing pages. </p>
            </div>
            <div class="auth-features__thumbnail">
              <img
                src="/assets/img/login/ab-testing.png"/>
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Carousel',
  data () {
    return {
      slickOptions: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }
    }
  }
}
</script>
