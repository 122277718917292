import { resendVerificationEmailURL } from '@/config/config'
import http from '@/mixins/http-lib'

let authenticationMixin = {
  created () {
  },
  methods: {
    async resendVerificationEmail () {
      await http.post(resendVerificationEmailURL).then(
        response => {
          if (response.data.status) {
            this.alertMessage('A verification email has been sent to your email address.', 'success')
          } else {
            this.alertMessage('An error occurred while sending a verification email, please try again.', 'error')
          }
        }
      )
      return
    }
  }
}
export { authenticationMixin }
