const paddle_ids = {
  development: {
    replug_basic: 53393,
    replug_professional: 53392,
    replug_agency: 53391,
    replug_basic_annual: 53390,
    replug_professional_annual: 53389,
    replug_agency_annual: 53388,
    replug_agency_lifetime: 53387,
    replug_enterprise: 53386,
    replug_professional_semi_annual: 53385,
    replug_lifetime_split_plan: 53384,
    replug_growth_annual: 53383,
    replug_addons_monthly: 53382,
    replug_addons_annually: 53381
  },
  staging: {
    replug_basic: 50609,
    replug_professional: 50610,
    replug_agency: 50611,
    replug_basic_annual: 50612,
    replug_professional_annual: 50613,
    replug_agency_annual: 50614,
    replug_agency_lifetime: 50615,
    replug_enterprise: 50616,
    replug_professional_semi_annual: 50617,
    replug_lifetime_split_plan: 50618,
    replug_growth_annual: 50619,
    replug_addons_monthly: 50620,
    replug_addons_annually: 50621
  },
  qa: {
    replug_basic: 50609,
    replug_professional: 50610,
    replug_agency: 50611,
    replug_basic_annual: 50612,
    replug_professional_annual: 50613,
    replug_agency_annual: 50614,
    replug_agency_lifetime: 50615,
    replug_enterprise: 50616,
    replug_professional_semi_annual: 50617,
    replug_lifetime_split_plan: 50618,
    replug_growth_annual: 50619,
    replug_addons_monthly: 50620,
    replug_addons_annually: 50621
  },
  production: {
    replug_basic: 563085,
    replug_professional: 563086,
    replug_agency: 563087,
    replug_basic_annual: 563088,
    replug_professional_annual: 563089,
    replug_agency_annual: 563090,
    replug_agency_lifetime: 567715,
    replug_enterprise: 572326,
    replug_professional_semi_annual: 575795,
    replug_lifetime_split_plan: 576010,
    replug_growth_annual: 637167,
    replug_addons_monthly: 638707,
    replug_addons_annually: 638708
  }
}


const pricingList = {
  plans: [
    {
      internal_id: 1006,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_basic,
      display: 'Basic',
      price: '$19',
      clicks: '10,000',
      brands: 1,
      campaigns: 5,
      pixels: 1,
      links: 'unlimited',
      custom_domains: 0,
      team_members: 0,
      branding: false,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 1,
      upgrade: true,
      type: 'monthly',
      color: '_orange',
      traffic_routing: false,
      ab_testing: false,
      whitelabel: false,
      deep_linking: false,
      bio_links: 5,
      loader: false
    },
    {
      internal_id: 1007,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_basic_annual,
      display: 'Basic Annual',
      price: '$180',
      discounted_price: '$15',
      clicks: '10,000',
      brands: 1,
      campaigns: 5,
      pixels: 1,
      links: 'unlimited',
      custom_domains: 0,
      team_members: 0,
      branding: false,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 2,
      upgrade: true,
      type: 'annually',
      color: '_orange',
      traffic_routing: false,
      ab_testing: false,
      whitelabel: false,
      deep_linking: false,
      bio_links: 5,
      loader: false
    },
    {
      internal_id: 1008,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_professional,
      display: 'Professional',
      clicks: '50,000',
      price: '$49',
      brands: 5,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 5,
      team_members: 3,
      branding: true,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 3,
      upgrade: true,
      type: 'monthly',
      color: '_green',
      traffic_routing: true,
      ab_testing: true,
      whitelabel: false,
      deep_linking: false,
      bio_links: 25,
      loader: false
    },
    {
      internal_id: 1009,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_professional_annual,
      display: 'Professional Annual',
      clicks: '50,000',
      price: '$468',
      discounted_price: '$39',
      brands: 5,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 5,
      team_members: 3,
      branding: true,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 4,
      upgrade: true,
      type: 'annually',
      color: '_green',
      traffic_routing: true,
      ab_testing: true,
      whitelabel: false,
      deep_linking: false,
      bio_links: 25,
      loader: false
    },
    {
      internal_id: 1011,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_agency,
      display: 'Agency',
      clicks: '200,000',
      price: '$79',
      brands: 10,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 10,
      team_members: 10,
      branding: true,
      dedicated_support: true,
      api_access: true,
      onboarding: true,
      level: 5,
      upgrade: true,
      type: 'monthly',
      color: '_purple',
      traffic_routing: true,
      ab_testing: true,
      whitelabel: true,
      deep_linking: true,
      bio_links: 50,
      loader: false
    },
    {
      internal_id: 1012,
      paddle_id: paddle_ids[process.env.VUE_APP_ENVIRONMENT].replug_agency_annual,
      display: 'Agency Annual',
      clicks: 'unlimited',
      price: '$786',
      discounted_price: '$63',
      brands: 10,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 10,
      team_members: 10,
      branding: true,
      dedicated_support: true,
      api_access: true,
      onboarding: true,
      level: 6,
      upgrade: true,
      type: 'annually',
      color: '_purple',
      traffic_routing: true,
      ab_testing: true,
      whitelabel: true,
      deep_linking: true,
      bio_links: 50,
      loader: false
    }
  ]
}

const trialPricingList = {
  plans: [
    {
      internal_id: 1001,
      paddle_id: 563085,
      display: 'Basic Trial',
      price: '$9',
      clicks: '5,000',
      brands: 1,
      campaigns: 5,
      pixels: 1,
      links: 'unlimited',
      custom_domains: 0,
      team_members: 0,
      branding: false,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 1,
      upgrade: true,
      type: 'monthly',
      loader: false
    },
    {
      internal_id: 1004,
      paddle_id: 563086,
      display: 'Professional',
      clicks: '50,000',
      price: '$49',
      brands: 5,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 5,
      team_members: 3,
      branding: true,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      level: 2,
      upgrade: true,
      type: 'monthly',
      loader: false
    },
    {
      internal_id: 1005,
      paddle_id: 563087,
      display: 'Agency',
      clicks: '200,000',
      price: '$79',
      brands: 10,
      campaigns: 'unlimited',
      pixels: 'unlimited',
      links: 'unlimited',
      custom_domains: 10,
      team_members: 10,
      branding: true,
      dedicated_support: true,
      api_access: true,
      onboarding: true,
      level: 3,
      upgrade: true,
      type: 'monthly',
      loader: false
    }
  ]
}

const blackFridayPricingList = {
  plan: [
    {
      internal_id: 1006,
      paddle_id: 563088,
      display: 'Replug Basic',
      price: '$69',
      clicks: '10,000',
      brands: 1,
      campaigns: 5,
      pixels: 1,
      discounted_price: '$228',
      links: 'Unlimited',
      custom_domains: 0,
      branding: false,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      bio_links: 0,
      upgrade: true,
      color: '_orange',
      qr_code: true,
      loader: false,
      coupon: 'SPECIAL70OFF'
    },
    {
      internal_id: 1006,
      paddle_id: 637167,
      display: 'Replug Growth',
      price: '$99',
      clicks: '20,000',
      brands: 3,
      campaigns: 20,
      pixels: 10,
      links: 'Unlimited',
      custom_domains: 3,
      discounted_price: '$468',
      branding: false,
      dedicated_support: false,
      api_access: false,
      onboarding: false,
      bio_links: 3,
      upgrade: true,
      color: '_orange',
      qr_code: true,
      loader: false,
      coupon: 'SPECIAL70OFF'
    }
  ]
}
export { pricingList, trialPricingList, blackFridayPricingList, paddle_ids }
