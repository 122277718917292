<template>
  <div class="setting_form h-full">
    <IPAddressModalV2></IPAddressModalV2>
    <DeleteIPModalV2
        :DelObj="IpAddressToDel"
    ></DeleteIPModalV2>
    <div class="w-full h-full bg-[#F7F7FC]">
      <div
          class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <!-- Header -->
            <div class="flex px-3 py-[1rem] border-b h-[4.188rem] justify-between items-center">
              <div class="w-full flex gap-x-2">
                <Button
                    id="create-new"
                    type="button"
                    class="bg-blue-600 hover:bg-blue-700"
                    buttonClass="btn-md"
                    @click.prevent="showIpAddModal"
                >
                  <template v-slot:label>Add IP Address</template>
                </Button>

              </div>
              <div class="w-full flex justify-center items-center">
                <p class="text font-bold text-center !text-[1.125rem] pr-[0.2rem] !text-[#3C4549]">
                  Blacklist IPs
                </p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                      fill="#3C4549"
                  />
                </svg>
              </div>
              <div class="w-full flex items-center justify-end space-x-2"></div>
            </div>
            <table class="links_table text-left w-full">
              <thead
                  class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins bg-[#F4F6FA]"
              >
              <tr>
                <td class="py-3 pl-3 w-1/2">IP Address</td>
                <td class="py-3 pr-3 text-right w-1/2">Actions</td>
              </tr>
              </thead>
              <tbody v-if="getBlacklistIpsLoaders.get">
              <tr>
                <td colspan="2">
                  <div class="p-5 text-center">
                    <clip-loader
                        :size="'26px'"
                        :color="'#1d4ed8'"
                    ></clip-loader>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else-if="!getBlacklistIpsLoaders.get && getBlacklistIpList.length == 0">
              <tr>
                <td colspan="2" class="">
                  <p
                      class="no-rows text-[#3C4549] text-[0.875rem] font-poppins"
                  >
                    <i class="fab fa-bandcamp" aria-hidden="true"></i>
                    You have not added any IP Address
                  </p>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="font-poppins text-[#3C4549] text-[0.875rem]">
              <tr
                  v-for="(blacklist,index) in getBlacklistIpList"
                  class="border-b border-[#ECEEF5] px-3"
              >
                <td class="py-4 pl-3 w-1/2"> {{ blacklist.ip_address }}</td>

                <td
                    class="py-4 pr-3 text-right w-full flex gap-1 justify-end items-center h-full"
                >
                  <svg
                      :class="
                        ActiveModal === 'editIp'
                          ? 'rounded-full !bg-[#F4F6FA)]'
                          : ''
                      "
                      class="cursor-pointer mr-2"
                      v-tooltip="'Edit Ip'"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click.prevent="
                        ActiveModal = 'editIp';
                        editIp(blacklist)
                      "
                  >
                    <path
                        d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                        stroke="#3C4549"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                      :class="
                        ActiveModal === 'delete-ip-modal'
                          ? 'rounded-full !bg-[#F4F6FA)]'
                          : ''
                      "
                      class="cursor-pointer mr-2"
                      v-tooltip="'Delete Ip'"
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click.prevent="
                        ActiveModal = 'deleteIp';
                        DeleteModal(blacklist._id)
                      "
                  >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                        fill="#97304A"
                    />
                  </svg>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {DARK_PURPLE_LOADER_COLOR} from '../../../common/constants'
import {blacklistIp} from '../../../state/modules/mutation-types'
import IPAddressModalV2 from "./IPAddressModalV2.vue"
import DeleteIPModalV2 from "./DeleteIPModalV2.vue"

export default ({
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    DeleteIPModalV2: DeleteIPModalV2,
    IPAddressModalV2: IPAddressModalV2
  },
  data() {
    return {
      size: '14px',
      ActiveModal: '',
      IpAddressToDel: null,
      color: DARK_PURPLE_LOADER_COLOR,
      validations: {
        ip_address: false,
        ip_address_valid: false
      },
      messages: {
        ip_address: 'IP Address cannot be empty',
        ip_address_valid: 'Invalid IP Address'
      },
      addIpLoader: false,
      yourIp: null,
      userIpLoader: false
    }
  },
  async created() {
    this.getSiteDetails
    this.yourIp = await this.getServerIp()
    await this.fetchBlacklistIp()
  },
  methods: {
    ...mapMutations([blacklistIp.SET_BLACKLIST_IP]),
    ...mapActions(['fetchBlacklistIp', 'storeBlacklistIp', 'deleteIp', 'getServerIp']),
    DeleteModal(id) {
      this.IpAddressToDel = id;
      setTimeout(() => {
        this.$bvModal.show("delete-ip-modal");
      }, 150);
    },
    editIp(ip) {
      this[blacklistIp.SET_BLACKLIST_IP](JSON.parse(JSON.stringify(ip)))
      this.$bvModal.show('addBlacklistIpModal')
    },
    showIpAddModal() {
      this.$store.commit(blacklistIp.RESET_BLACKLIST_IP)
      this.$bvModal.show('addBlacklistIpModal')
    },
    copyText(ip) {
      this.alertMessage('Your IP has been copied to clipboard', 'success')
      navigator.clipboard.writeText(ip)
    }
  },
  computed: {
    ...mapGetters([
      'getBlacklistIpsLoaders',
      'getBlacklistIpList',
      'getAddBlacklistIp',
      'getWorkspace'
    ])
  },

  watch: {
    async 'getWorkspace._id' (value) {
      this.yourIp = await this.getServerIp()
      await this.fetchBlacklistIp()
    },
    'getAddBlacklistIp.ip_address'(value) {
      if (value && value.length > 0) {
        this.validations.ip_address = false
        this.validations.ip_address_valid = !this.validateIpAddress(value)
      }
    },
  }

})
</script>
<style scoped lang="less">
.ip {
  color: #1f216e;

  span {
    color: #1f216e;
  }

  i {
    margin-top: 3px;
  }
}
</style>
