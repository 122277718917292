import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store'
import {integrationRoutes} from './routes/integrations'
import {authenticationRoutes} from './config/routes/authentication'

import CreateLink from './views/pages/links/CreateLink.vue'
import UpgradeExistingLifetimePlan from '@/views/pages/setting/billing/UpgradeExistingLifetimePlan'
// import Clients from '@/views/pages/setting/passport/Clients.vue'
import Clients from '@/views/pages/setting/passport/ClientsV2.vue'

import {authIntegrationRoutes} from '@/routes/auth-integrations'
// import BlacklistIP from './views/pages/setting/BlacklistIP'
import BlacklistIP from './views/pages/setting/BlacklistIPV2'

import http from '@/mixins/http-lib'
import {getUserSubscriptionURL} from '@/config/config'
import ChangeEmail from '@/views/users/auth/ChangeEmail.vue'
import JoinTeam from '@/views/users/auth/JoinTeam.vue'

// Verify Email
import VerifyEmail from '@/views/users/auth/VerifyEmail.vue'
// Social Login
import SocialLogin from '@/views/authentication/SocialLogin.vue'

import {userMaven} from './config/config'
import {JWT_CHROME_LOCAL_STORAGE} from "@/constants";

const Page404 = () => import('@/components/404page.vue')
const Logout = () => import('./views/Logout.vue')
const PlatformLayout = () => import('./components/PlatformLayout.vue')
const Dashboard = () => import('./views/users/Dashboard.vue')
const Brands = () => import('./views/pages/brands/Brands.vue')
const Campaigns = () => import('./views/pages/campaigns/CampaignsView.vue')
const Links = () => import('./views/pages/links/Links.vue')
const RssFeeds = () => import('./views/pages/rss-feeds/RSSListing.vue')
const RssFeedsV2 = () => import('./views/pages/rss-feeds/RSSListingV2.vue')
const CallToActionLayout = () => import('./views/pages/campaigns/Layout.vue')
const CallToActionBrand = () => import('./views/pages/campaigns/call-to-actions/steps/CampaignBrand.vue')
const CallToActionType = () => import('./views/pages/campaigns/call-to-actions/steps/call-to-action/CTAType.vue')
const CtaDetail = () => import('./views/pages/campaigns/call-to-actions/steps/CampaignType.vue')
const CallToActionMessagePreview = () => import('./views/pages/campaigns/call-to-actions/steps/call-to-action/MessagePreview.vue')
const CallToActionCustomizationPreview = () => import('./views/pages/campaigns/call-to-actions/steps/call-to-action/CustomizationPreview.vue')
const CreateWhiteLabel = () => import('./views/pages/setting/white-label/CreateWhiteLabel.vue')
// const WhitelabelSettings = () => import('./views/pages/setting/white-label/WhitelabelSettings.vue')
const WhitelabelSettings = () => import('./views/pages/setting/white-label/WhitelabelSettingsV2.vue')
// const WhitelabelGeneralSettings = () => import('./views/pages/setting/white-label/WhitelabelGeneralSettings.vue')
const WhitelabelGeneralSettings = () => import('./views/pages/setting/white-label/WhitelabelGeneralSettingsV2.vue')
// const WhitelabelDomainSettings = () => import('./views/pages/setting/white-label/WhitelabelDomainSettings.vue')
const WhitelabelDomainSettings = () => import('./views/pages/setting/white-label/WhitelabelDomainSettingsV2.vue')
const WhitelabelBrandedLinksSettings = () => import('./views/pages/setting/white-label/WhiteLabelBrandedLinksSettings.vue')
// const WhitelabelEmailSettings = () => import('./views/pages/setting/white-label/WhitelabelEmailSettings.vue')
const WhitelabelEmailSettings = () => import('./views/pages/setting/white-label/WhitelabelEmailSettingsV2.vue')


const CallToActionTriggers = () => import('./views/pages/campaigns/call-to-actions/steps/call-to-action/Triggers.vue')
const RetargetingStep = () => import('./views/pages/campaigns/call-to-actions/steps/retargeting/RetargetingStep.vue')
const ThirdPartyIntegration = () => import('./views/pages/campaigns/call-to-actions/steps/widgets/ThirdPartyIntegration.vue')

const AnalyticsMain = () => import('@/views/pages/analytics/AnalyticsMain.vue')
const Analytics = () => import('@/views/pages/analytics/list-view/AnalyticsV2.vue')
const AnalyticsDetail = () => import('@/views/pages/analytics/AnalyticsDetailV2.vue')
const Report = () => import('@/views/pages/reports/ReportV2.vue')

// bio links
const BioLinkList = () => import('@/views/pages/bio-link/BioLinkListV2.vue')
const BioLinkLayout = () => import('@/views/pages/bio-link/widgets/BioLinkLayout.vue')
const BioBlockSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioBlockSnippet')
const BioRSSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioRSSSnippet')
const BioContentSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioContentSnippet')
const BioBrandSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioBrandSnippet')
const BioLinksSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioLinksSnippet')
const BioSocialSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioSocialSnippet')
const BioSocialNetwork = () => import('@/views/pages/bio-link/widgets/snippets/BioSocialNetwork')
const BioAudioSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioAudioSnippet')
const BioVideoSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioVideoSnippet')
const BioCardSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioCardSnippet')
const BioTextSnippet = () => import('@/views/pages/bio-link/widgets/snippets/BioTextSnippet')
const BioThemeAndFinalize = () => import('@/views/pages/bio-link/widgets/snippets/BioThemeAndFinalize')

// settings
const Setting = () => import('@/views/pages/setting/Setting.vue')
// const Tags = () => import('@/views/pages/setting/Tags.vue')
const Tags = () => import('@/views/pages/setting/TagsV2.vue')
// const Team = () => import('@/views/pages/setting/team/Team.vue')
const Team = () => import('@/views/pages/setting/team/TeamV2.vue')

const WhitelabelClients = () => import('@/views/pages/setting/clients/Clients.vue')
// const UTM = () => import('@/views/pages/setting/utm/UTMListing.vue')
const UTM = () => import('@/views/pages/setting/utm/UTMListingV2.vue')

const Password = () => import('@/views/pages/setting/Password.vue')
const Billing = () => import('@/views/pages/setting/Billing.vue')
const BillingV2 = () => import('@/views/pages/setting/BillingV2.vue')
const BasicSetting = () => import("@/views/pages/setting/workspace/basicSetting.vue")   // For workspace setting
// const Profile = () => import('@/views/pages/setting/Profile.vue')
const Profile = () => import('@/views/pages/setting/ProfileNew.vue')

// const Pixels = () => import('@/views/pages/setting/pixels/Pixels.vue')
const Pixels = () => import('@/views/pages/setting/pixels/PixelsV2.vue')

// const Miscellaneous = () => import('@/views/pages/setting/Miscellaneous.vue')
const Miscellaneous = () => import('@/views/pages/setting/MiscellaneousV2.vue')

const NotificationsList = () => import('@/views/pages/setting/notifications/NotificationsList.vue')
// const Integrations = () => import('@/views/pages/integrations/Integrations.vue')
const Integrations = () => import('@/views/pages/integrations/IntegrationsV2.vue')

// const CustomDomain = () => import('@/views/pages/setting/domains/CustomDomain.vue')
const CustomDomain = () => import('@/views/pages/setting/domains/CustomDomainV2.vue')

// const CustomCode = () => import('@/views/pages/setting/custom-widget/CustomCode.vue')
const CustomCode = () => import('@/views/pages/setting/custom-widget/CustomCodeV2.vue')

const SpecialOffer = () => import('@/views/pages/Subscriptions/SpecialOffer.vue')
// deep links
// const DeepLinksSettings = () => import('@/views/pages/setting/DeepLinks/DeepLinksSettings')
const DeepLinksSettings = () => import('@/views/pages/setting/DeepLinks/DeepLinksSettingsV2.vue')

// const DeepLinksCommon = () => import('@/views/pages/setting/DeepLinks/DeepLinksCommon')
const DeepLinksCommon = () => import('@/views/pages/setting/DeepLinks/DeepLinksCommonV2.vue')


/**
 * Route Guard: If user is authorized, we follow along with the route otherwise
 * we redirect to /login
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
export const ifAuthenticated = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.

  // getting the token from query paramenter and setting in the localStorage for authentication if token exists
  let params = (new URL(document.location)).searchParams;
  let token = params.get("token");
  if (token) {
    localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, token)
  }

  if (!store.getters.isAuthenticating && !store.getters.getLoadedOnce) {
    await store.dispatch('authenticate')
  }
  // if user is being authenticated, wait with loader
  if (store.getters.isAuthenticating) {
    return
  }
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next(`/login/?redirect_url=${window.location.pathname.substring(1)}`)
}

/**
 * If authentication can be ignored like 404 page
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const ignoreAuthenticationProcess = async (to, from, next) => {
  next()
}

const ifSubscriptionPaused = async (to, from, next) => {
  // for the first time, send the request to verify that API call has been made to the server.
  // await this.getUserSubscription()

  if (!store.getters.getProfile.is_fetched) {
    await store.dispatch('fetchProfile')
  }

  if (!store.getters.getSubscription) {
    await store.dispatch('fetchSubscription')
  }

  if (store.getters.getSubscription.paused) {
    next('/settings/billing')
    return
  }

  //  || store.getters.getProfile.subscription_state === 'deleted' || store.getters.getProfile.subscription_state === 'cancelled'
  if ((store.getters.getProfile.subscription_state && store.getters.getProfile.subscription_state === 'past_due') || store.getters.getProfile.state === 'cancelled') {
    next('/settings/billing')
    return
  }

  // checking the is user allowed the current routes
  /*if (store.getters.getProfile.role === ROLES.guest && Object.keys(routeList).includes(to.name) && !store.getters.getProfile.policy[routeList[to.name]]) {
    next('/dashboard')
    return
  }*/

  next()
}

const isAgencyAndIfSubscriptionPaused = async (to, from, next) => {
  await http.post(getUserSubscriptionURL).then(
    async response => {
      if (response.data.status) {
        if (store.getters.getProfile.subscription_state && (store.getters.getProfile.subscription_state === 'past_due')) {
          next('/settings/billing')
          return
        }
        if (response.data.subscription && response.data.subscription.limits && response.data.subscription.limits.api_access) {
          next()
        } else {
          next({name: '404'})
        }
      }
    })
  next()
}

Vue.use(VueRouter)
// These tags are used with pages that shall not be indexed
const noIndexing = {
  name: 'robots',
  content: 'noindex'
}
const router = new VueRouter({
  mode: 'history',
  routes: [
    ...authenticationRoutes,
    {
      path: '/sso',
      name: 'sso',
      component: SocialLogin,
      meta: {
        title: 'Social Sign On'
      },
      beforeEnter: ignoreAuthenticationProcess
    },
    {
      path: '/verifyEmail/:token',
      name: 'verifyEmail',
      component: VerifyEmail,
      meta: {
        title: 'Email Verification'
      }
    },
    {
      path: '/',
      name: 'home',
      beforeEnter: ifAuthenticated,
      component: PlatformLayout,
      children: [
        {
          path: '/:workspaceId/dashboard',
          name: 'dashboard',
          beforeEnter: ifSubscriptionPaused,
          component: Dashboard,
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: 'brands',
          name: 'brands',
          beforeEnter: ifSubscriptionPaused,
          component: Brands,
          meta: {
            title: 'Brands',
            metaTags: [
              noIndexing
            ]
          }
        },
        {
          path: '/special-offer',
          name: 'special-offer',
          component: SpecialOffer,
          meta: {
            title: 'Special Offer'
          }
        },
        {
          path: '/:workspaceId/campaigns',
          name: 'campaigns',
          beforeEnter: ifSubscriptionPaused,
          component: Campaigns,
          meta: {
            title: 'Campaigns',
            metaTags: [
              noIndexing
            ]
          }
        },
        {
          path: '/:workspaceId/links',
          name: 'links',
          beforeEnter: ifSubscriptionPaused,
          component: Links,
          meta: {
            title: 'Links',
            metaTags: [
              noIndexing
            ]
          }
        },
        {
          path: '/:workspaceId/rss',
          name: 'rss',
          beforeEnter: ifSubscriptionPaused,
          component: RssFeedsV2,
          meta: {
            title: 'RSS Automation'
          }
        },
        {
          path: '/:workspaceId/analytics',
          name: 'analytics_main',
          beforeEnter: ifSubscriptionPaused,
          component: AnalyticsMain,
          redirect: '/analytics/analytics',
          meta: {
            metaTags: [
              noIndexing
            ]
          },
          children: [
            {
              path: '/:workspaceId/analytics/analytics/:type?/:id?',
              name: 'analytics',
              component: Analytics,
              meta: {
                title: 'Analytics'
              }
            },
            {
              path: '/:workspaceId/analytics/detail/:type?/:id?',
              name: 'analytics_detail',
              component: AnalyticsDetail,
              meta: {
                title: 'Analytics Detail'
              }
            }
          ]

        },
        {
          path: '/:workspaceId/reports',
          name: 'reports',
          beforeEnter: ifSubscriptionPaused,
          component: Report,
          meta: {
            title: 'Reports'
          }
        },
        {
          path: '/:workspaceId/bio-links',
          name: 'bio_links',
          beforeEnter: ifSubscriptionPaused,
          component: BioLinkList,
          meta: {
            title: 'Bio Links',
            metaTags: [
              noIndexing
            ]
          }
        },
        {
          path: '/:workspaceId/settings',
          name: 'settings',
          component: Setting,
          redirect: '/:workspaceId/settings/profile',
          meta: {
            title: 'Settings'
          },
          children: [
            {
              path: 'profile',
              name: 'profile',
              beforeEnter: ifSubscriptionPaused,
              component: Profile,
              meta: {
                title: 'Profile Settings'
              }
            },
            {
              path: 'workspace',
              name: 'workspace',
              beforeEnter: ifSubscriptionPaused,
              component: BasicSetting,
              meta: {
                title: 'Workspace Settings'
              }
            },
            {
              path: '/:workspaceId/settings/events',
              name: 'events',
              beforeEnter: ifSubscriptionPaused,
              component: NotificationsList,
              meta: {
                title: 'Events'
              }
            },
            {
              path: '/:workspaceId/settings/password',
              name: 'password',
              beforeEnter: ifSubscriptionPaused,
              component: Password,
              meta: {
                title: 'Change Password'
              }
            },
            {
              path: '/:workspaceId/settings/billing',
              name: 'billing',
              // beforeEnter: ifSubscriptionPaused,
              component: BillingV2,
              meta: {
                title: 'Billing',
                metaTags: [
                  noIndexing
                ]
              }
            },
            {
              path: '/:workspaceId/settings/integrations',
              name: 'integrations',
              beforeEnter: ifSubscriptionPaused,
              component: Integrations,
              meta: {
                title: 'Integrations'
              }
            },
            {
              path: '/:workspaceId/settings/custom_code',
              name: 'custom_code',
              beforeEnter: ifSubscriptionPaused,
              component: CustomCode,
              meta: {
                title: 'Custom Widgets',
                metaTags: [
                  noIndexing
                ]
              }
            },

            {
              path: '/:workspaceId/settings/custom_domain',
              name: 'custom_domains',
              beforeEnter: ifSubscriptionPaused,
              component: CustomDomain,
              meta: {
                title: 'Custom Domains'
              }
            },
            {
              path: '/:workspaceId/settings/pixels',
              name: 'pixels',
              beforeEnter: ifSubscriptionPaused,
              component: Pixels,
              meta: {
                title: 'Retargeting Pixels',
                metaTags: [
                  noIndexing
                ]
              }
            },
            {
              path: '/:workspaceId/settings/blacklist-ip',
              name: 'blacklist_ip',
              beforeEnter: ifSubscriptionPaused,
              component: BlacklistIP,
              meta: {
                title: 'Blacklist Ips'
              }
            },

            {
              path: '/:workspaceId/settings/apps',
              name: 'apps',
              beforeEnter: isAgencyAndIfSubscriptionPaused,
              component: Clients,
              meta: {
                title: 'Developer Apps'
              }
            },
            {
              path: '/:workspaceId/settings/tags',
              name: 'tags',
              beforeEnter: ifSubscriptionPaused,
              component: Tags,
              meta: {
                title: 'Tags'
              }
            },
            {
              path: '/:workspaceId/settings/team',
              name: 'team',
              beforeEnter: ifSubscriptionPaused,
              component: Team,
              meta: {
                title: 'Team',
                metaTags: [
                  noIndexing
                ]
              }
            },
            {
              path: '/:workspaceId/settings/clients',
              name: 'clients',
              beforeEnter: ifSubscriptionPaused,
              component: WhitelabelClients,
              meta: {
                title: 'clients'
              }
            },
            {
              path: '/:workspaceId/settings/utm',
              name: 'utm',
              beforeEnter: ifSubscriptionPaused,
              component: UTM,
              meta: {
                title: 'UTM'
              }
            },

            {
              path: '/:workspaceId/settings/miscellaneous',
              name: 'miscellaneous',
              beforeEnter: ifSubscriptionPaused,
              component: Miscellaneous,
              meta: {
                title: 'Miscellaneous',
                metaTags: [
                  noIndexing
                ]
              }
            },
            {
              path: '/:workspaceId/settings/white-label',
              redirect: 'white-label/general-settings',
              name: 'white-label',
              beforeEnter: ifSubscriptionPaused,
              component: WhitelabelSettings,
              meta: {
                title: 'White Label'
              },
              children: [
                {
                  path: '/:workspaceId/settings/white-label/general-settings',
                  name: 'general-settings',
                  component: WhitelabelGeneralSettings,
                  meta: {
                    title: 'General Setting'
                  }
                },
                {
                  path: '/:workspaceId/settings/white-label/domain-settings',
                  name: 'domain-settings',
                  component: WhitelabelDomainSettings,
                  meta: {
                    title: 'Domain Setting'
                  }
                },
                {
                  path: '/:workspaceId/settings/white-label/branded-links-settings',
                  name: 'branded-links-settings',
                  component: WhitelabelBrandedLinksSettings,
                  meta: {
                    title: 'Branded Links Settings'
                  }
                },
                {
                  path: '/:workspaceId/settings/white-label/email-settings',
                  name: 'email-settings',
                  component: WhitelabelEmailSettings,
                  meta: {
                    title: 'Email Settings'
                  }
                }
              ]
            },
            {
              path: '/:workspaceId/settings/deep-links',
              redirect: 'deep-links/social-media',
              name: 'deep-links',
              beforeEnter: ifSubscriptionPaused,
              component: DeepLinksSettings,
              meta: {
                title: 'Deep link Settings'
              },
              children: [
                {
                  path: '/:workspaceId/settings/deep-links/social-media',
                  name: 'social-media',
                  beforeEnter: ifSubscriptionPaused,
                  component: DeepLinksCommon,
                  meta: {
                    title: 'Social Media'
                  }
                },
                {
                  path: '/:workspaceId/settings/deep-links/e-commerce',
                  name: 'e-commerce',
                  beforeEnter: ifSubscriptionPaused,
                  component: DeepLinksCommon,
                  meta: {
                    title: 'E-Commerce'
                  }
                },
                {
                  path: '/:workspaceId/settings/deep-links/music',
                  name: 'music',
                  beforeEnter: ifSubscriptionPaused,
                  component: DeepLinksCommon,
                  meta: {
                    title: 'Music'
                  }
                },
                {
                  path: '/:workspaceId/settings/deep-links/video',
                  name: 'video',
                  beforeEnter: ifSubscriptionPaused,
                  component: DeepLinksCommon,
                  meta: {
                    title: 'Video'
                  }
                },
                {
                  path: '/:workspaceId/settings/deep-links/productivity',
                  name: 'productivity',
                  beforeEnter: ifSubscriptionPaused,
                  component: DeepLinksCommon,
                  meta: {
                    title: 'Productivity'
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        title: 'Logout'
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: '/:workspaceId/preview',
      name: 'preview',
      redirect: {name: 'create'},
      component: CallToActionLayout,
      meta: {
        title: 'Preview'
      },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '/:workspaceId/create',
          name: 'create',
          component: CallToActionBrand,
          meta: {
            title: 'Select Your Brand',
            metaTags: [
              noIndexing
            ]
          }
        },
        {
          path: '/:workspaceId/call_to_action',
          name: 'call_to_action_type',
          component: CallToActionType,
          meta: {
            title: 'Select CTA Type'
          }
        },
        {
          path: '/:workspaceId/campaignType',
          name: 'campaign_type',
          component: CtaDetail,
          meta: {
            title: 'Select Campaign Type'
          }
        },

        {
          path: '/:workspaceId/message',
          name: 'message',
          component: CallToActionMessagePreview,
          meta: {
            title: 'Call-to-Action Message'
          }
        },

        {
          path: '/:workspaceId/customize',
          name: 'customize',
          component: CallToActionCustomizationPreview,
          meta: {
            title: 'Call-to-Action Customization'
          }
        },
        {
          path: '/:workspaceId/link',
          name: 'link',
          component: CreateLink,
          meta: {
            title: 'Brand Link'
          }
        },
        {
          path: '/:workspaceId/triggers',
          name: 'triggers',
          component: CallToActionTriggers,
          meta: {
            title: 'Display Rules'
          }
        }, {
          path: '/:workspaceId/retargeting',
          name: 'retargeting',
          component: RetargetingStep,
          meta: {
            title: 'Retargeting'
          }
        }, {
          path: '/:workspaceId/third_party_integration',
          name: 'third_party_integration',
          component: ThirdPartyIntegration,
          meta: {
            title: '3rd Party Integration'
          }
        }
      ]
    },
    {
      path: '/:workspaceId/preview_bio_link',
      name: 'preview_bio_link',
      redirect: {name: 'select_brand'},
      component: BioLinkLayout,
      meta: {
        title: 'Bio Link Select'
      },
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'brand',
          name: 'select_brand',
          beforeEnter: ifSubscriptionPaused,
          component: BioBrandSnippet,
          meta: {
            title: 'Select Brand'
          }
        },
        {
          path: '/:workspaceId/content',
          name: 'select_content',
          beforeEnter: ifSubscriptionPaused,
          component: BioContentSnippet,
          meta: {
            title: 'Add Content Types'
          }
        },
        {
          path: '/:workspaceId/block',
          name: 'select_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioBlockSnippet,
          meta: {
            title: 'Select Block'
          }
        },
        {
          path: '/:workspaceId/links_block',
          name: 'content_links_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioLinksSnippet,
          meta: {
            title: 'Add Links'
          }
        },
        {
          path: '/:workspaceId/social_profile',
          name: 'content_social_channels',
          beforeEnter: ifSubscriptionPaused,
          component: BioSocialNetwork,
          meta: {
            title: 'Add Social'
          }
        },
        {
          path: '/:workspaceId/social_block',
          name: 'content_social_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioSocialSnippet,
          meta: {
            title: 'Add Social'
          }
        },
        {
          path: '/:workspaceId/rss_block',
          name: 'content_rss_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioRSSnippet,
          meta: {
            title: 'Add RSS Feed'
          }
        },
        {
          path: '/:workspaceId/audio_block',
          name: 'content_audio_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioAudioSnippet,
          meta: {
            title: 'Add Audio'
          }
        },
        {
          path: '/:workspaceId/video_block',
          name: 'content_video_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioVideoSnippet,
          meta: {
            title: 'Add Video'
          }
        },
        {
          path: '/:workspaceId/text_block',
          name: 'content_text_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioTextSnippet,
          meta: {
            title: 'Add Text'
          }
        },
        {
          path: '/:workspaceId/card_block',
          name: 'content_card_block',
          beforeEnter: ifSubscriptionPaused,
          component: BioCardSnippet,
          meta: {
            title: 'Add Card'
          }
        },
        {
          path: '/:workspaceId/finalize',
          name: 'bio_finalize',
          beforeEnter: ifSubscriptionPaused,
          component: BioThemeAndFinalize,
          meta: {
            title: 'Finalize'
          }
        }
      ]

    },
    {
      path: '/upgrade_lifetime_addon',
      name: 'upgrade_lifetime_addon',
      component: UpgradeExistingLifetimePlan,
      meta: {
        title: 'Upgrade Lifetime Plan'
      }
    },
    {
      path: '*',
      name: '404',
      component: Page404,
      meta: {
        title: '404 - Page not found'
      },
      beforeEnter: ignoreAuthenticationProcess
    },
    /**
     * This route is used when the user changes their email address by going to the Settings.
     * We send them an email to click on it, so most likely we are going to force user to be logged in.
     */
    {
      path: '/verifyEmailChange/:token',
      name: 'verifyEmailChange',
      component: ChangeEmail,
      meta: {
        title: 'Change Email Verification'
      },
      beforeEnter: ifAuthenticated
    },
    /**
     * If the user is not logged in, we will render the join team form.
     * If they are already logged in, we will verify the token and send DB call.
     * During this process we can show a loader and show a message joining team
     */
    {
      path: '/joinTeam/:token',
      name: 'joinTeam',
      component: JoinTeam,
      meta: {
        title: 'Join'
      },
      beforeEnter: ignoreAuthenticationProcess
    },
    ...integrationRoutes,
    ...authIntegrationRoutes
  ],
  linkActiveClass: 'active_tab'
})

/**
 * This callback runs before every route change, including on page load.
 * @link https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
 */
router.beforeEach(async (to, from, next) => {
  /**
   * It has been commented out intentionally - We will move this logic to be called after user logs in.
   * Setting it in localStorage to consume this for use in white label title from storage instead of extra
   * api call every time.
   * @see https://app.clubhouse.io/replug-team/story/25408/white-label-title-to-be-shown-in-case-of-white-label-login
   */
  /* const domain = window.location.hostname
  const defaultDomains = ['app.replug.io', 'staging-frontend.replug.io', 'stage.replug.io', 'localhost']
  let customDomain = false;
  if (!defaultDomains.includes(domain)) {
    console.log("Sending call")
    customDomain = await http.post(getWhitelabelDetailsFromDomain, {domain: domain})
    customDomain = customDomain.data.status ? customDomain.data.data : false;
    console.log(customDomain)
  }
  const site = customDomain ? customDomain.name : 'Replug' */
  const site = 'Replug'

  // if (!store.getters.getProfile.is_fetched) {
  //   await store.dispatch('fetchProfile')
  // }
  //
  // if (!store.getters.getSubscription) {
  //   await store.dispatch('fetchSubscription')
  // }

  if (store.getters.getSubscription.paused) {
    next()
    return
  }

  if ((store.getters.getProfile.subscription_state && store.getters.getProfile.subscription_state === 'past_due') || store.getters.getProfile.state === 'cancelled') {
    next()
    return
  }


  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  // We haven't added meta tags for description etc but we can
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} | ${site}`
  } else if (previousNearestWithMeta) {
    document.title = `${previousNearestWithMeta.meta.title} | ${site}`
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .filter(tagDef => {
      // if condition exists for meta tags, make sure it is satisfied
      tagDef.conditions = tagDef.conditions || []
      if (!tagDef.conditions.length) {
        return true
      }
      // verifying query params in route match the key values provided in the condition of route's meta tags
      return tagDef.conditions.some(condition => to.query[condition.key] && to.query[condition.key] === condition.value)
    })
    .map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

router.afterEach(async (to, from) => {
  if (store.getters.getProfile && store.getters.getProfile._id) {
    await userMaven.track('pageview')
    // await analyticsConfig.page()
  }
})

export default router
